import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  tableContainer: {
    maxHeight: '60vh',
    overflow: 'auto',
  },
  table: {
    tableLayout: 'fixed',
    width: 650,
  },
  subHeader: {
    top: 29,
  },
});
