import { API } from 'aws-amplify';

import type { ClientProfileAPIType, ClientProfileRoleAPIType, ClientProfileRoleType, ClientProfileType } from '@/api/ClientService/client.interface';


const CLIENT_SERVICE_URL =  process.env.REACT_APP_CLIENT_SERVICE_URL;
const CLIENT_PATH = '/clientProfile';

class ClientService {
  public apiServiceName = 'ClientService';
  public endpointUrl = CLIENT_SERVICE_URL;
  mapToClientProfileRolesType(data:ClientProfileRoleAPIType): ClientProfileRoleType{
    return{
      clientRate: data.client_rate,
      clientRole: data.client_role,
      fixedRate: data.fixed_rate,
      slalomProficiency: data.slalom_proficiency,
      slalomRole: data.slalom_role
    };
  }
  mapToClientProfileType(data: ClientProfileAPIType):ClientProfileType{
    return {
      clientId: data.client_id,
      market: data.market,
      clientName: data.client_name,
      roles: data.roles.map((item:ClientProfileRoleAPIType) => {
        return this.mapToClientProfileRolesType(item);
      })
    };
  }
  async getProfilesData(){
    const retValue: Array<ClientProfileType> = [];
    const res = await API.get(this.apiServiceName, CLIENT_PATH, {});

    if (res.result) {
      res.result.forEach((client: ClientProfileAPIType)=>{
        retValue.push(this.mapToClientProfileType(client));
      });
    }
    return retValue;
  }
  async getProfileById(id: string): Promise<ClientProfileType> {
    const url = `${CLIENT_PATH}/${id}`;
    const res = await API.get(this.apiServiceName, url, {});
    return this.mapToClientProfileType(res);
  }
  async createClientProfile(data:ClientProfileAPIType){
    let retValue:ClientProfileType ={clientId:'', market:'', clientName:'', roles:[]};
    const myInit = {
      body: data
    };
    const res = await API.post(this.apiServiceName, CLIENT_PATH, myInit);

    if (res.result) {
      retValue = this.mapToClientProfileType(res.result);
    }
    return retValue;
  }
  async updateClientProfile(data:ClientProfileAPIType){
    const url = `${CLIENT_PATH}/${data.client_id}`;
    let retValue:ClientProfileType ={clientId:'', market:'', clientName:'', roles:[]};
    const myInit = {
      body: data
    };
    const res = await API.patch(this.apiServiceName, url, myInit);
    if (res.result) {
      retValue = this.mapToClientProfileType(res.result);
    }
    return retValue;
  }
}

export const clientService = new ClientService();
