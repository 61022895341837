import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  bleedBackground: {
    backgroundColor: theme.palette.grey[50],
    boxShadow: `0 0 0 100vmax ${theme.palette.grey[50]}`,
    clipPath: 'inset(0 -100vmax)',
  },
  buttonGroup: {
    paddingTop: 62,
  },
  final: {
    paddingTop: 12,
    fontSize: 24,
  },
  finalCost: {
    '&:before': {
      content: '"$"',
    }
  },
  section: {
    paddingTop: 12,
    paddingBottom: 16,
  },
  subtitle: {
    paddingBottom: 16,
  },
  marginStyle: {
    fontWeight: 700,
    fontSize: 11,
  },
}));