import type { EstimateAPIType } from '@/api/EstimateService/estimate.interface';
import type { EstimateSummaryInfo } from '@/components/EstimatesSummaryTable';
import { type ActionListDispatch,newAction} from '@/store/utils';


export interface EstimatesState {
  summaries: Array<EstimateSummaryInfo> | undefined;
  details: Record<string, EstimateAPIType>;
}

export const estimatesActions = {
  receivedSummaries: newAction<EstimateSummaryInfo[]>('receivedSummaries'),
  receivedOneSummary: newAction<EstimateSummaryInfo>('receivedOneSummary'),
  deleteEstimateSuccess: newAction<string>('deleteEstimateSuccess'),
  receivedDetails: newAction<EstimateAPIType>('receivedDetails'),
};

export type EstimatesActions = typeof estimatesActions;
export type EstimatesDispatches = ActionListDispatch<EstimatesActions>;