import type { PropsWithChildren} from 'react';
import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { clientService } from '@/api/ClientService/client.service';
import type { Notification } from '@/store/notification';
import { useNotificationContext } from '@/store/notification';

import type { ActionsType,ContextType, PayloadType, StateType } from './client.interface';
import { ClientActionType } from './client.interface';

const ClientContext = createContext<ContextType>({state: { clientProfiles: [] }, actions: undefined});
ClientContext.displayName = 'ClientContext';

const initialState: StateType = {
  clientProfiles: undefined,
};

const reducer = (state: StateType, action: PayloadType): StateType => {
  switch (action.type) {
  case ClientActionType.GET_PROFILES:
    return { ...state, clientProfiles: action.payload.clientProfiles };
  }
};

function ClientContextProvider(props: PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { dispatch: notification } = useNotificationContext();

  async function getProfilesData() {
    await clientService.getProfilesData().then(clients => {
      clients.sort((a,b) => a.clientName.localeCompare(b.clientName));
      dispatch({ type: ClientActionType.GET_PROFILES, payload: {clientProfiles: clients}});
    }).catch(err => {
      let error;
      if (typeof err === 'string') {
        error = err;
      } else if (err instanceof Error) {
        error = err.message;
      } else {
        error = 'An unknown error has occured';
      }

      const message = <>Ran into an issue while attempting to retrieve client profiles.<br/>Error: {error}</>;

      const notify: Notification = {
        message,
        title: 'Client Profile',
        severity: 'error',
        id: `${Date.now()}`
      };

      notification?.addNotification(notify);
    });
  }

  const actions: ActionsType = {
    getProfiles: () => {
      if(state.clientProfiles) return;
      getProfilesData();
    },
    refresh: () => {
      getProfilesData();
    },
  };

  useEffect(() => {
    actions.getProfiles();
  }, []);

  return (
    <ClientContext.Provider value={{ state, actions }}>
      {props.children}
    </ClientContext.Provider>
  );
}

export const useClientContext = () => useContext(ClientContext);
export { ClientContext, ClientContextProvider };