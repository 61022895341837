import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  table: {
    minWidth: 700,
    maxWidth: 2600,
    border: '2px solid',
    borderColor: theme.palette.divider,
  },
  moveRight: {
    paddingLeft: 10,
  },
  projectLink: {
    color: 'blue',
  },
  salesforceStyle: {
    width: '40px',
  },
  teamsStyle: {
    width: '30px',
    marginLeft: '20px',
  },
  noBottomBorder: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
}));
