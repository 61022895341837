import React from 'react';
import type { NavigateFunction } from 'react-router';
import { API } from 'aws-amplify';
import { format,subDays } from 'date-fns';

import type { ServiceStatus } from '@/api/utils';
import type { EstimateSummaryInfo } from '@/components/EstimatesSummaryTable';
import { paths } from '@/services/Router/paths';
import type { EstimatesDispatches } from '@/store/estimates/estimates.interface';
import type { LoadingActions } from '@/store/loading';
import type { Notification, NotificationActions} from '@/store/notification';
import type { ActionListDispatch } from '@/store/utils';

import type { EstimateAPIResponse, EstimateAPIType, EstimateQueryParameters, EstimatesAPIResponse } from './estimate.interface';

const ESTIMATES_SERVICE_URL = process.env.REACT_APP_ESTIMATES_SERVICE_URL;
const ESTIMATES_PATH = '/estimates';

const ESTIMATE_DATE_FORMAT = 'yyyy-MM-dd';
const DAYS_UNTIL_HISTORICAL = 365;
export class EstimateService {
  public static apiServiceName = 'EstimateService';
  public static endpointUrl = ESTIMATES_SERVICE_URL;
  constructor(
    private estimatesDispatches: EstimatesDispatches,
    private notification: ActionListDispatch<NotificationActions>['addNotification'],
    private loader: ActionListDispatch<LoadingActions>['setLoading'],
  ) {}

  public static mapEstimatesAPITypeToEstimateSummaryInfo(estimate: EstimateAPIType): EstimateSummaryInfo {
    return {
      id: estimate.estimate_id,
      clientName: estimate.client_name,
      projectName: estimate.project_name,
      modifiedBy: {
        name: estimate.modified_by || estimate.created_by,
        date:  estimate.modified_datetime ? new Date(estimate.modified_datetime) : new Date (estimate.created_datetime),
      },
      market: estimate.market,
      salesforceLink: estimate.salesforce_link,
      teamsLink: estimate.teams_link
    };
  }

  getEstimatesSummary({showOthersEstimates = false, showHistoricalEstimates = false}: {showOthersEstimates?: boolean, showHistoricalEstimates?: boolean} = {showOthersEstimates: false, showHistoricalEstimates: false}): Promise<ServiceStatus> {
    return this.loading()
      .then(() => {
        const params: EstimateQueryParameters = {};

        if (!showHistoricalEstimates) {
          const startDate = format(subDays(new Date(), DAYS_UNTIL_HISTORICAL), ESTIMATE_DATE_FORMAT);
          params.startDate = startDate;
        }

        if (showOthersEstimates) {
          params.showOthers = true;
        }
        return params;
      })
      .then((params) => API.get(EstimateService.apiServiceName, ESTIMATES_PATH, { queryStringParameters: params }))
      .then((res: EstimatesAPIResponse) => res.result.map(estimate => EstimateService.mapEstimatesAPITypeToEstimateSummaryInfo(estimate)))
      .then((estimates) => this.estimatesDispatches.receivedSummaries(estimates))
      .then(() => 'ok' as const)
      .catch((e) => this.handleError('Issue fetching estimate summaries', e))
      .finally(() => this.removeLoading());
  }

  getById(id: string): Promise<ServiceStatus> {
    return this.loading()
      .then(() => this.fetchEstimate(id))
      .then((res: EstimateAPIResponse) => this.estimatesDispatches.receivedDetails(res.result))
      .then(() => 'ok' as const)
      .catch((e) => {
        this.notification({
          id,
          severity: 'error',
          message: 'Error occurred fetching estimate: ' + e.message,
        });
        return 'error' as const;
      })
      .finally(() => this.removeLoading());
  }
  createEstimate(reqBody: EstimateAPIType, navigate: NavigateFunction,): Promise<ServiceStatus> {
    return this.loading()
      .then(() => API.post(EstimateService.apiServiceName, ESTIMATES_PATH, {body: reqBody}))
      .then((res) => res.result as EstimateAPIType)
      .then((estimate) => {
        this.estimatesDispatches.receivedDetails(estimate);
        return estimate;
      })
      .then((estimate) => navigate(`${paths.estimation}/${estimate.estimate_id}`))
      .then(() => 'ok' as const)
      .catch((e) => this.handleError('Issue Creating Estimate', e))
      .finally(() => this.removeLoading());
  }

  async updateEstimate(reqBody: EstimateAPIType): Promise<ServiceStatus> {
    const updateCollision = await this.loading()
      .then(() => this.fetchEstimate(reqBody.estimate_id))
      .then((res) => this.checkForUpdateCollision(reqBody, res.result))
      .catch((e) => this.formattedErrorMessage(e, 'Estimate ID not found'))
      .finally(() => this.removeLoading());

    return updateCollision !== 'ok' ? updateCollision :
      this.loading()
        .then(() => `${ESTIMATES_PATH}/${reqBody.estimate_id}`)
        .then((url) => API.patch(EstimateService.apiServiceName, url, {body: reqBody}))
        .then((res) => res.result)
        .then((estimate) => {
          this.estimatesDispatches.receivedDetails(estimate);
          this.notification({
            id: estimate.estimate_id + 'update success',
            severity: 'success',
            message: 'Changes have been successfully saved.',
          });
        })
        .then(() => 'ok' as const)
        .catch((e) => this.formattedErrorMessage(e, `${reqBody.estimate_id} update failure`))
        .finally(() => this.removeLoading());
  }

  copyEstimate (id: string): Promise<ServiceStatus> {
    return this.loading()
      .then(() => API.post(EstimateService.apiServiceName, `${ESTIMATES_PATH}/${id}/copy`, {}))
      .then((res) => this.estimatesDispatches.receivedDetails(res.result))
      .then(() => 'ok' as const)
      .catch((e) => this.handleError(`Issue Copying ${id}`, e))
      .finally(() => this.removeLoading());
  }

  deleteEstimate (id: string): Promise<ServiceStatus> {
    return this.loading()
      .then(() => API.del(EstimateService.apiServiceName, `${ESTIMATES_PATH}/${id}`, {}))
      .then(() => this.estimatesDispatches.deleteEstimateSuccess(id))
      .then(() => 'ok' as const)
      .catch((e) => this.handleError(`Issue deleting ${id}`, e))
      .finally(() => this.removeLoading());
  }

  private async loading() {
    this.loader({ enable: true });
  }
  private removeLoading() {
    this.loader({ enable: false });
  }
  private handleError(msg: string, err: unknown): ServiceStatus {
    let error;
    if (typeof err === 'string') {
      error = err;
    } else if (err instanceof Error) {
      error = err.message;
    } else {
      error = 'An unknown error has occurred';
    }

    const message = <>{msg}<br/>Error: {error}</>;

    const notify: Notification = {
      message,
      title: 'Estimates',
      severity: 'error',
      id: `${Date.now()}`
    };

    this.notification(notify);
    return 'error';
  }
  private formattedErrorMessage (e: { response: { data: { message: string; data: { issue: string; param: string; }[]; }; }; }, id: string): ServiceStatus{
    let message:string;
    if (e?.response?.data?.message.includes('Validation errors')) {
      message= 'Validation error with the following field(s): ';
      e?.response?.data?.data.forEach((err: { issue:string, param: string; },index:number) =>{
        if(index !== e?.response?.data?.data?.length -1){
          message += err.param + ', ';
        }else{
          message += err.param;
        }
      });
    }else{
      message = e?.response?.data?.message;
    }
    this.notification({
      id,
      severity: 'error',
      message,
    });

    return 'error';
  }
  private fetchEstimate(id: string): Promise<EstimateAPIResponse> {
    const params: EstimateQueryParameters = {};
    return API.get(EstimateService.apiServiceName, ESTIMATES_PATH + '/' + id,  { queryStringParameters: params });
  }

  private checkForUpdateCollision = (newEstimate: EstimateAPIType, oldEstimate: EstimateAPIType): ServiceStatus => {
    if (newEstimate.modified_datetime !== oldEstimate.modified_datetime) {
      if(newEstimate.modified_by === oldEstimate.modified_by){
        return 'ok';
      }
      return 'collision';
    } else {
      return 'ok';
    }
  };
}