
import type { FC } from 'react';
import React from 'react';
import { Controller,useFormContext } from 'react-hook-form';
import InfoIcon from '@mui/icons-material/Info';
import { FormControl, Grid, IconButton,InputAdornment, InputLabel, MenuItem, Select, Stack, styled,TextField, Tooltip, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { debounce } from 'ts-debounce';

import ControlledAutocomplete from '@/components/Autocomplete';
import type { EstimationFormFieldsType } from '@/pages/Estimation/Estimation.interface';
import { useClientContext } from '@/store/client';

import type { ProjectDetailsFormProps } from './ProjectDetailsForm.interface';
import { useStyles } from './ProjectDetailsForm.styles';

const DURATION_OPTIONS : string[] = Array.from({length: 52}, (_v, i) => (i + 1) === 1 ? `${i + 1} wk` : `${i + 1} wks`);
const StyledTextField = styled(TextField)(({ theme, disabled }) => ({
  '& .MuiInput-root': {
    width: '100%',
    marginTop: 3,
  },
  '& .MuiInput-input': {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    marginLeft: 0,
  },
  '& .MuiInputAdornment-root': {
    marginRight: 0,
    marginLeft: 10,
    color: disabled ? theme.palette.text.secondary : theme.palette.text.disabled
  }
}));

export const ProjectDetailsForm: FC<ProjectDetailsFormProps> = ({disabled}) => {
  const { classes } = useStyles();
  const { control, register, watch, setValue, formState: { errors } } = useFormContext<EstimationFormFieldsType>();
  const { state: clients } = useClientContext();

  let num=0;
  const clientOptions = clients.clientProfiles?.map((item) => ({id: num++, label: item.clientName})) as {label:string; id: number;}[];

  const watchDuration = watch('duration');
  const watchSow = watch('sow');

  const validateLink = (link: string) => {
    let validLink = link;
    const httpsLink = 'https://';
    const httpLink = 'http://';
    if (!link.startsWith(httpsLink) && !link.startsWith(httpLink) && !httpsLink.startsWith(link) && !httpLink.startsWith(link)){
      validLink = httpsLink + validLink;
    }

    return validLink;
  };


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container direction="row" columnGap={2} rowGap={1} data-testid="ProjectDetailsForm">
        <Grid item xs={12}>
          <Typography variant='h3' color='text.secondary'>Project Details</Typography>
        </Grid>
        <Grid item xs={3}>
          <InputLabel variant="standard" shrink={true}>
            <Typography fontSize='11'>Client name* <Tooltip sx={{padding: 0}} title={'Select an existing client profile from the list or add new to work without a client rate card'}><IconButton><InfoIcon color='primary' fontSize={'small'}/> </IconButton ></Tooltip></Typography>
          </InputLabel>
          <Controller
            name='client'
            control={control}
            render={({ field }) => (
              <ControlledAutocomplete
                options={clientOptions}
                renderProps={field}
                disabled = {disabled}
                error={!!errors['client']}
              />
            )}/>
        </Grid>
        <Grid item xs={5}>
          <InputLabel variant="standard" shrink={true}>
            <Typography fontSize='11'>Project name*</Typography>
          </InputLabel>
          <TextField
            placeholder="Enter name"
            {...register('project')}
            fullWidth
            inputProps={{'data-testid': 'ProjectName' }}
            disabled = {disabled}
            error={!!errors['project']}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel variant="standard" shrink={true}>
            <Typography fontSize='11'>Team folder link</Typography>
          </InputLabel>
          <TextField
            placeholder="Paste link to team folder"
            {...register('teamsLink')}
            onChange={(e)=> {
              setValue('teamsLink',validateLink(e.target.value),{shouldDirty: true});
            }}
            fullWidth
            disabled = {disabled}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel variant="standard" shrink={true}>
            <Typography fontSize='11'>Salesforce folder link*</Typography>
          </InputLabel>
          <TextField
            placeholder="Paste link to Salesforce"
            {...register('salesforceLink')}
            onChange={(e)=> {
              setValue('salesforceLink',validateLink(e.target.value),{shouldDirty: true, shouldValidate: true});
            }}
            fullWidth
            disabled = {disabled}
            error={!!errors['salesforceLink']}
          />
        </Grid>
        <Grid item xs={5}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 2 }}
            justifyContent='space-between'
          >
            <Stack>
              <InputLabel shrink={true}>
                <Typography>Project contingency</Typography>
              </InputLabel>
              <StyledTextField
                placeholder="0"
                type='number'
                {...register('contingency', { min: -100,max: 100})}
                onChange= {debounce(async (e) => {
                  const min = -100;
                  const max = 100;
                  let value = Math.min(Math.max(parseInt(e.target.value), min), max);
                  if(isNaN(value)) value = 0;
                  await setValue('contingency', value, { shouldDirty: true });
                }, 2000)}
                className={classes.contingencyField}
                InputProps={{disabled: disabled, endAdornment:<InputAdornment disableTypography={disabled} position='end'>%</InputAdornment>, inputProps: { min: -100, max: 100 }}}

              />
            </Stack>
            <Stack>
              <InputLabel variant="standard" shrink={true}>
                <Typography fontSize='11'>SOW type*</Typography>
              </InputLabel>
              <FormControl variant="standard" fullWidth>
                <Select
                  className={classes.dropDown}
                  {...register('sow')}
                  value={watchSow}
                  displayEmpty
                  disabled = {disabled}
                  error={!!errors['sow']}
                >
                  <MenuItem value={'T&M'}>T&M</MenuItem>
                  <MenuItem value={'Fixed Fee'}>Fixed Fee</MenuItem>
                  <MenuItem value={'Fixed Fee Capacity'}>Fixed Fee Capacity</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack>
              <InputLabel variant="standard" shrink={true}>
                <Typography fontSize='11'>Start date</Typography>
              </InputLabel>
              <Controller
                name='startDate'
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <DatePicker
                    label=''
                    inputRef={ref}
                    {...rest}
                    renderInput={(inputProps) => (
                      <TextField
                        {...inputProps}
                        className={classes.datePicker}
                        label={''}
                      />
                    )}
                    disabled = {disabled}
                  />
                )}
              />
            </Stack>
            <Stack>
              <InputLabel variant="standard" shrink={true}>
                <Typography fontSize='11'>Duration</Typography>
              </InputLabel>
              <FormControl variant="standard" fullWidth>
                <Select
                  className={classes.dropDown}
                  {...register('duration')}
                  value={watchDuration}
                  displayEmpty
                  disabled = {disabled}
                >
                  {DURATION_OPTIONS?.map((item, index) => (<MenuItem key={index} value={index + 1}>{item}</MenuItem>))}
                </Select>
              </FormControl>
            </Stack>
            <Stack>
              <InputLabel variant="standard" shrink={true}>
                <Typography fontSize='11'>End date</Typography>
              </InputLabel>
              <Controller
                name='endDate'
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <DatePicker
                    label=''
                    inputRef={ref}
                    {...rest}
                    renderInput={(inputProps) => (
                      <TextField
                        {...inputProps}
                        className={classes.datePicker}
                        label={''}
                      />
                    )}
                    disabled = {disabled}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};