declare global {
    interface Window {
        BCAAConfig: {
            region: string
            userPoolId: string
            userPoolWebClientId: string
            identityPoolId: string
            domain: string
            identityProvider: string
        }
    }
}
export const config = window.BCAAConfig;

export enum AuthStatus {
    Pending,
    Failed,
    Authenticated,
    NoUser,
}

//TODO Populate With Slalom Equivalent Roles
export enum Role {
    Admin, // Managing Director & GM
    BasicAdmin, // Senior Director & Above
    BasicUser,
}

export enum AdminJobTitles {
    SeniorDirector = 'Senior Director',
    SeniorDeliveryDirector = 'Senior Delivery Director',
    ManagingDirector = 'Managing Director',
    GeneralManager = 'General Manager'
}

export interface User {
    employeeId: string;
    market: string;
    email: string;
    firstName: string;
    lastName: string;
    capability: string;
    group: string;
    jobTitle: string;
    role: Role;
}

export type AuthState =
    | { status: AuthStatus.Pending; user: undefined}
    | { status: AuthStatus.NoUser; user: undefined}
    | { status: AuthStatus.Failed; error: string; user: undefined}
    | { status: AuthStatus.Authenticated; user: User; }

export enum AuthActionType {
    Logout,
    NoUserFound,
    Authenticated,
    Failed,
    Pending,
}

export type AuthAction =
    | { type: AuthActionType.Logout }
    | { type: AuthActionType.NoUserFound }
    | { type: AuthActionType.Pending }
    | { type: AuthActionType.Authenticated; user: User }
    | { type: AuthActionType.Failed; error: string }

export interface AuthActionsType {
    initAuth: () => void
}

export interface AuthContextType {
    state: AuthState
    actions: AuthActionsType | undefined
}