import type { FC} from 'react';
import React from 'react';
import { Box,Tab, Tabs } from '@mui/material';

import { useStyles } from './TabGroup.styles';

type TabChangeHandler = (newTab: string) => void;

export const TabGroup: FC<{tabItems: Array<{name: string, value: string, hidden: boolean}>, item: {name: string, value: string, hidden: boolean}, callback: TabChangeHandler}> = ({tabItems, item, callback}) => {
  const { classes } = useStyles();

  const handleTabSelection = (_event: React.SyntheticEvent, newTab: string) => {
    callback(newTab);
  };

  const TabGroup = (tabs: Array<{name: string, value: string, hidden: boolean}>) => (
    <Tabs value={item.value} onChange={handleTabSelection}>
      {
        tabs.map((tab) => { return !tab.hidden && <Tab key={tab.value} data-testid={tab.name} label={tab.name} value={tab.value} />; })
      }
    </Tabs>
  );

  return (
    <Box className={classes.subnav} data-testid="TabGroup">
      {TabGroup(tabItems)}
    </Box>
  );
};