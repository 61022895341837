import type { ActionListDispatch} from '@/store/utils';
import { buildEmptyDispatchers, newAction } from '@/store/utils';

export interface LoadingState {
  loadingState: {
    loading: boolean,
    message?: string,
  }

}
export interface LoadingType {
  enable: boolean,
  message?: string,
}

export const loadingActions = {
  setLoading: newAction<LoadingType>('setLoading'),
};

export type LoadingActions = typeof loadingActions;
export type LoadingDispatches = ActionListDispatch<LoadingActions>;

export interface LoadingContext {
  state: LoadingState;
  dispatch: LoadingDispatches;
}

export const deafultLoadingContext: LoadingContext = {
  state: {
    loadingState: {
      loading: true,
    }
  },
  dispatch: buildEmptyDispatchers(loadingActions),
};