import React, { useEffect } from 'react';
import { createBrowserRouter,Navigate, RouterProvider } from 'react-router-dom';

import Layout from './components/Layout';
import Admin from './pages/Admin';
import ClientProfile from './pages/ClientProfile';
import Estimation from './pages/Estimation';
import Home from './pages/Home';
import Loading from './pages/Loading';
import Onboarding from './pages/Onboarding';
import { paths } from './services/Router/paths';
import { useAuthContext } from './store/auth';
import { isBasicAdmin, isRachelOrHamilton } from './store/auth/auth.func';
import { AuthStatus } from './store/auth/auth.interface';

function App() {
  const {state: authState, actions: authActions} = useAuthContext();

  useEffect(() => {
    authActions?.initAuth();
  }, []);

  const createPage = (Page: React.FC) => {
    return <Layout><Page /></Layout>;
  };

  const createBasicAdminRestrictedPage = (Page: React.FC): JSX.Element => {
    return isBasicAdmin() ? (
      <Layout>
        <Page />
      </Layout>
    ) : (
      <Navigate to="/" replace />
    );
  };

  const createRachelAndHamiltonRestrictedPage = (Page: React.FC): JSX.Element => {
    return isRachelOrHamilton() ? (
      <Layout>
        <Page />
      </Layout>
    ) : (
      <Navigate to="/" replace />
    );
  };

  const router = createBrowserRouter([
    {
      path: paths.admin + '/*',
      element: createBasicAdminRestrictedPage(Admin),
    },
    {
      path: paths.onboarding,
      element: createRachelAndHamiltonRestrictedPage(Onboarding),
    },
    {
      path: paths.home,
      element: createPage(Home),
    },
    {
      path: paths.clientProfile,
      element: createPage(ClientProfile),
    },
    {
      path: paths.estimation + paths.idParam,
      element: createPage(Estimation),
    },
  ]);

  switch (authState.status) {
  case AuthStatus.Pending:
  case AuthStatus.NoUser:
    return <Loading></Loading>;
  case AuthStatus.Authenticated:
    return (
      <RouterProvider router={router} />
    );
  case AuthStatus.Failed:
    return (
      <div className="bg-[#070717] w-screen h-screen flex justify-center items-center">
        <div className="text-white text-3xl">{authState.error}</div>
      </div>
    );
  default:
    return (
      <div className="bg-[#070717] w-screen h-screen flex justify-center items-center">
        <div className="text-white text-3xl">
                        Failed Authentication
        </div>
      </div>
    );
  }
}

export default App;
