import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  appTitle: {
    paddingLeft: 8,
    paddingTop: 8,
  },
  iconButton:{
    padding: 0,
  },
});