import type {FC} from 'react';
import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import type { KabobMenuItemProps,KabobMenuProps } from './KabobMenu.interface';

interface PrivateKabobMenuItemProps {
  selected: string | undefined;
  setAnchorEl: (e: null | HTMLElement) => void;
}

export const KabobMenuItem: FC<KabobMenuItemProps & PrivateKabobMenuItemProps> = ({option, value, onClick, selected, setAnchorEl}) => {
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    onClick(event);
  };

  return (
    <MenuItem key={option} selected={option === selected} onClick={handleClose} data-testid={`${value}-testid`}>
      {value}
    </MenuItem>
  );
};

export const KabobMenu: FC<KabobMenuProps> = ({color, items, selected, PaperStyles, id='KabobButton', disabled}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div data-testid="KabobMenu">
      <IconButton
        aria-label="more"
        id={id}
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        data-testid={id}
        disabled = {disabled}
      >
        <MoreVertIcon color={color}/>
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={PaperStyles}
      >
        {items?.map(({option, value, onClick}) => (
          <KabobMenuItem key={option} option={option} value={value} onClick={onClick} selected={selected} setAnchorEl={setAnchorEl}/>
        ))}
      </Menu>
    </div>
  );
};
