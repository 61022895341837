import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  textStyle: {
    fontWeight: 700,
    fontSize: 11,
  },
  discountStyle: {
    fontWeight: 500,
    fontSize: 11,
    color: theme.palette.grey[600]
  },
  discountPercent: {
    fontWeight: 400,
    fontSize: 13,
    color: theme.palette.grey[600]
  },
  cost: {
    '&:before': {
      content: '"$"',
    },
    fontSize: 13,
  },
  divider: {
    borderBottom: '1px solid',
    borderColor: theme.palette.grey[500],
  }

}));