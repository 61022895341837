import type { FC} from 'react';
import React, { useEffect } from 'react';
import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import type { AutocompleteProps } from './Autocomplete.interface';
import { useStyles } from './Autocomplete.styles';

export const ControlledAutocomplete: FC<AutocompleteProps> = (props: AutocompleteProps) => {
  const { onChange, ref, value, ...rest} = props.renderProps;
  const {id, options, disabled, error} = props;
  const { classes } = useStyles();
  const filter = createFilterOptions<{inputValue? : string; label: string; id: number;} | string>();
  const [val, setVal] = React.useState<string | null>(value || null);
  useEffect(() => {
    // For when the value is updated outside of this nested form
    setVal(value);
  }, [value]);

  return (
    <Autocomplete
      data-testid="ControlledAutocomplete"
      autoSelect
      autoHighlight
      classes={{paper: classes.paper}}
      {...rest}
      value={val || null}
      options={options || []}
      disabled = {disabled}
      onChange={(_event, newValue) => {
        if (typeof newValue === 'string') {
          setVal(newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setVal(newValue.inputValue);
          onChange(newValue.inputValue);
        } else if (newValue && newValue.label) {
          setVal(newValue.label);
          onChange(newValue.label);
        } else {
          setVal('');
          onChange('');
        }
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(props, option) => <li data-testid={`option-${typeof option === 'string'? option : option.label}`} {...props}>{typeof option === 'string'? option : option.label}</li>}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => typeof option === 'string' ? inputValue === option : inputValue === option.label);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            label: `Add "${inputValue}"`,
            id: filtered.length,
          });
        }
        return filtered;
      }}
      renderInput={(params) =>
        (<TextField
          data-testid={id || null}
          inputRef={ref}
          error= {error}
          {...params}
        />)}
      isOptionEqualToValue={(option, value) => {
        if(typeof option === 'string') {
          return option === value;
        }
        else if(typeof value === 'string') {
          return option.label === value;
        }
        else {
          return option.id === value.id;
        }
      }}
    />
  );
};