import { API } from 'aws-amplify';

import type { CostAPIType, CostsRolesAPIType, CostsRolesType, CostType, MarginDataItemRequest, MarginDataItemResponse, UpdateCostRequestType } from '@/api/CostsService/costs.interface';
import { mapFormToRequest,mapToCostsRolesType, mapToCostType } from '@/api/CostsService/costs.mapper';

const COST_SERVICE_URL = process.env.REACT_APP_RATE_SERVICE_URL;
const COSTS_PATH = '/costs';

class CostService {
  public apiServiceName = 'CostService';
  public endpointUrl: string = COST_SERVICE_URL || '';

  async getCosts(): Promise<Array<CostType>> {
    const res = await API.get(this.apiServiceName, COSTS_PATH, {});
    const retValue: Array<CostType> = [];
    if (res.result) {
      res.result.forEach((cost: CostAPIType) => {
        retValue.push(mapToCostType(cost));
      });
    }
    return retValue;
  }

  async getMarketCostData(market: string, revisedDate?: string): Promise<CostType> {
    market = market + '_cost_data';
    const url = revisedDate ? `${COSTS_PATH}/${market}?revised_date=${revisedDate}` :
      `${COSTS_PATH}/${market}`;
    const res = await API.get(this.apiServiceName, url, {});
    return mapToCostType(res.result);
  }

  async getMarketRoles(market: string, revisedDate?: string): Promise<Array<CostsRolesType>> {
    const url = revisedDate ? `${COSTS_PATH}/roles/${market}?revised_date=${revisedDate}` :
      `${COSTS_PATH}/roles/${market}`;
    const res = await API.get(this.apiServiceName, url, {});
    const retValue: Array<CostsRolesType> = [];
    res.result.costs.forEach((item: CostsRolesAPIType) => {
      retValue.push(mapToCostsRolesType(item));
    });
    return retValue;
  }

  async saveMarketCosts(formData: UpdateCostRequestType): Promise<CostType> {
    const url = `${COSTS_PATH}`;
    const reqBody = mapFormToRequest(formData);
    const res = await API.patch(this.apiServiceName, url, {body: reqBody});
    return res.result;
  }

  async getMargin(formData: MarginDataItemRequest): Promise<MarginDataItemResponse> {
    const url = `${COSTS_PATH}/margin`;
    const res = await API.post(this.apiServiceName, url, {body: formData});
    return res.result;
  }

}

export const costService = new CostService();