import type { WeeklyHoursType } from '@/pages/Estimation/Estimation.interface';

// Data types from the API
export interface CostsRolesAPIType {
  expert: number,
  last_updated: string, //yyyy-mm-dd
  role: string,
  advanced: number,
  foundational: number,
  proficient: number
}
export interface CapabilitiesType {
  capability: string,
  practice: string[],
}
export interface CostAPIType {
  market: string,
  revised_date: string, //yyyy-mm-dd
  costs: CostsRolesAPIType[],
  capabilities: CapabilitiesType[],
}

// Data types for the UI
export interface CostsRolesType {
  role: string,
  foundationalCost: number,
  proficientCost: number,
  advancedCost: number,
  expertCost: number,
  lastUpdated: Date,
}

export interface UpdateCostsRolesType extends CostsRolesType {
  modified: boolean
}

export interface CostType {
  market: string,
  revisedDate: Date,
  costs: CostsRolesType[],
  capabilities: CapabilitiesType[],
}

export interface UpdateCostRequestType extends CostType {
  costs: UpdateCostsRolesType[]
}

export interface MarginData {
  slalomRole: string,
  slalomCost: number,
  clientCost: number,
  proficiency: string,
  weeklyBreakdown: WeeklyHoursType[]
}

export interface MarginDataItemRequest {
  teamMembers: MarginData[],
  partner_other_funding: number,
  slalom_funding: number,
  market: string
}

export interface MarginDataItemResponse {
  marketCostMargin: number,
  estimatedProjectMargin: number
}

export function getCostsDefaultValues(selectedMarket: string): CostType {
  return {
    market: selectedMarket,
    revisedDate: new Date(),
    costs: [
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Application Integration Engineer',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Cloud Architect',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Cloud Engineer',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Data Analyst',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Data Architect',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Data Engineer',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Data Guardian',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Data Scientist',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Data Strategist',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Data Visualization Specialist',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'DevOps Engineer',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Engineering Lead',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Enterprise Architect',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Experience Designer',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Experience Strategist',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Industry Practitioner',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Platform Solution Architect',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Platform Specialist',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Platform Technical Architect',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Quality Engineer',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Security Engineer',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Software Engineer',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Solution Architect',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Technology Consultant',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Technology Strategist',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Visual Designer',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Accountable Executive',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Business Analyst',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Business Architect',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Business Strategist',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Change Manager',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Customer Strategist',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Digital Strategist',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Engagement Lead',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Learning & Development Specialist',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Organizational Strategist',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Process Optimization Specialist',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Product Manager',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Product Owner',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Product Strategist',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Program Manager',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Project Manager',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Scrum Master',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Solution Owner',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      },
      {
        expertCost: 0,
        lastUpdated: new Date(),
        role: 'Talent Strategist',
        advancedCost: 0,
        foundationalCost: 0,
        proficientCost: 0
      }
    ],
    capabilities: [
      {
        capability: 'Business Advisory Services',
        practice: [
          'Agile Enablement',
          'Associates',
          'Business Transformation',
          'Client / Practice Leadership',
          'Connected Planning',
          'Customer Strategy',
          'Experience Led Strategy Alignment (ESA)',
          'Organizational Effectiveness',
          'Process Optimization',
          'Product Management',
          'Program Portfolio Management',
          'Strategy & Operations'
        ]
      },
      {
        capability: 'Client Leadership',
        practice: [
          'Client / Practice Leadership'
        ]
      },
      {
        capability: 'Data & Analytics',
        practice: [
          'Data & Analytics'
        ]
      },
      {
        capability: 'Experience Design',
        practice: [
          'Experience Design'
        ]
      },
      {
        capability: 'Salesforce',
        practice: [
          'Salesforce'
        ]
      },
      {
        capability: 'Technology Enablement',
        practice: [
          'Client / Practice Leadership',
          'Cloud Enablement',
          'Experience Design',
          'Software Engineering',
          'Technology Enablement',
          'Technology Strategy'
        ]
      },
      {
        capability: 'Versa',
        practice: [
          'Associates'
        ]
      }
    ]
  };
}