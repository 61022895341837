import type { FC } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { AppBar, Button, IconButton, Toolbar, Typography } from '@mui/material';

import { SlalomIcon } from '@/assets/icons/slalom';
import { paths } from '@/services/Router/paths';

import { useStyles } from './Header.styles';

export const Header: FC = () => {
  const { classes } = useStyles();
  const navigation = useNavigate();

  const handleClick = () => {
    navigation(paths.home);
  };

  const navigateToUserFeedbackChannel = () => {
    window.open(process.env.REACT_APP_TEAMS_URL);
  };

  return (
    <AppBar data-testid="Header" component="nav" position='sticky'>
      <Toolbar>
        <IconButton color={'inherit'} className={classes.iconButton} onClick={handleClick}>
          <SlalomIcon data-testid="Header-SVG" shape='logo' width='80px'/>
        </IconButton>
        <Typography data-testid="Header-Link" color='white' variant="body2" className={classes.appTitle}>
          Engagement Estimation App
        </Typography>
        <Button
          variant='outlined'
          style={{marginLeft: 'auto', borderColor:'white', color:'white'}}
          startIcon={<AddIcon />}
          onClick={navigateToUserFeedbackChannel}>
          FEEDBACK & SUPPORT
        </Button>
      </Toolbar>
    </AppBar>
  );
};
