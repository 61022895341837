import type { FC } from 'react';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';

import type { EstimationFormFieldsType } from '@/pages/Estimation/Estimation.interface';

import type { SummaryFormProps } from './SummaryForm.interface';
import { useStyles } from './SummaryForm.styles';

export const SummaryForm: FC<SummaryFormProps> = ({disabled}) => {
  const { classes } = useStyles();
  const { control, register, watch } = useFormContext<EstimationFormFieldsType>();

  const watchMarketValue = watch('marketValue');
  const watchTotalDiscounts = watch('totalDiscounts');
  const watchTotalRateDiscounts = watch('totalRateDiscounts');
  const watchProjectCost = watch('projectCost');

  return (
    <Stack
      direction='column'
      spacing={3}
      data-testid="SummaryForm"
    >
      <Typography variant='h3' color='text.secondary'>Summary</Typography>
      <Grid container direction="row" rowSpacing={1} columnSpacing={0} alignItems="center" columnGap={3}>
        <Grid item xs={3}>
          <Typography className={classes.textStyle}>Market Value</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className={classes.cost}
          >
            {watchMarketValue?.toLocaleString('en', {useGrouping:true})}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.discountStyle}>Total Rate Discounts</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className={classes.discountPercent}>{watchTotalRateDiscounts.toLocaleString(undefined, {
            useGrouping:true,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}%</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textStyle}>Project Cost</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className={classes.cost}>{watchProjectCost.toLocaleString('en', { useGrouping:true })}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textStyle}>Slalom Investment</Typography>
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            {...register('slalomInvestmentDisplay')}
            inputProps={{style: {fontSize: 13}}}
            disabled = {disabled}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name='slalomInvestmentType'
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel
                  value="currency"
                  control={<Radio />}
                  label="$"
                  disabled = {disabled}
                />
                <FormControlLabel
                  value="percentage"
                  control={<Radio />}
                  label="%"
                  disabled = {disabled}
                />
              </RadioGroup>
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textStyle}>Partner/Other Funding</Typography>
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            {...register('otherInvestmentDisplay')}
            inputProps={{style: {fontSize: 13}}}
            disabled = {disabled}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name='otherInvestmentType'
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel
                  value="currency"
                  control={<Radio />}
                  label="$"
                  disabled = {disabled}
                />
                <FormControlLabel
                  value="percentage"
                  control={<Radio />}
                  label="%"
                  disabled = {disabled}
                />
              </RadioGroup>
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textStyle}>Total Discounts</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className={classes.cost}>{watchTotalDiscounts.currency?.toLocaleString('en', {useGrouping:true})} / {Math.round(watchTotalDiscounts.percent * 100)}%</Typography>
        </Grid>
        <Grid item xs={7}>
          <Box className={classes.divider}></Box>
        </Grid>
      </Grid>
    </Stack>
  );
};