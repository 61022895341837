import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  root: {},
  dropDown: {
    marginTop: 0,
    paddingTop: 0,
    maxWidth: 170,
  },
  deleteButton:{
    padding: 0,
  },
  tableContainer: {
    maxHeight: '40vh',
    overflow: 'auto',
  },
  table:{
    maxWidth: 1000
  },
});