import type { PropsWithChildren } from 'react';
import React, { createContext, useContext, useReducer } from 'react';

import { type UnknownAction,useDispatchers } from '@/store/utils';

import type { NotificationState } from './notification.interface';
import { defaultNotificationContext, notificationActions } from './notification.interface';

const NotificationContext = createContext(defaultNotificationContext);
export const NotificationProvider = NotificationContext.Provider;
NotificationContext.displayName = 'NotificationContext';

const { addNotification, removeNotification } = notificationActions;
const reducer = (state: NotificationState, action: UnknownAction) => {
  if(addNotification.checker(action)) {
    const notifications = [...state.notifications, action.payload];
    return { ...state, notifications };
  }
  else if(removeNotification.checker(action)) {
    const notifications = state.notifications.filter(({ id }) => id !== action.payload);
    return { ...state, notifications };
  }

  return state;
};

export function NotificationContextProvider(props: PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, defaultNotificationContext.state);
  const notificationDispatch = useDispatchers(notificationActions, dispatch);

  return (
    <NotificationContext.Provider value={{ state: state, dispatch: notificationDispatch }}>
      {props.children}
    </NotificationContext.Provider>
  );
}

export const useNotificationContext = () => useContext(NotificationContext);