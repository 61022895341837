import type { FC} from 'react';
import React, { useEffect, useState } from 'react';
import type { UseFormReturn} from 'react-hook-form';
import { useFieldArray, useFormContext } from 'react-hook-form';
import type { SelectChangeEvent} from '@mui/material';
import { FormControl, MenuItem, Select, Stack, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { addWeeks, format,  isSameWeek, } from 'date-fns';
import { debounce } from 'ts-debounce';

import { getMonday } from '@/pages/Estimation/Estimation.func';
import type { EstimationFormFieldsType } from '@/pages/Estimation/Estimation.interface';
import { useNotificationContext } from '@/store/notification';

import { useTeamMemberUpdateHook } from './WeeklyHoursTable.func';
import type { TotalsType, ValuesPerRowType, WeeklyHoursCellProps, WeeklyHoursTableProps } from './WeeklyHoursTable.interface';
import { useStyles } from './WeeklyHoursTable.styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    fontSize: 14,
    fontWeight: 600,
    height: 50,
    border: '1px solid',
    borderColor: theme.palette.divider,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    height: 50,
    border: '1px solid',
    borderColor: theme.palette.divider,
    '&:last-child td, &:last-child th': {
      borderBottom: 0,
    },
  },
}));

const TotalCell = styled(StyledTableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 20,
  },
}));

export const WeeklyHoursCell: FC<WeeklyHoursCellProps> = ({index, disabled}) => {
  const { control, register, setValue } = useFormContext<EstimationFormFieldsType>();
  const { classes } = useStyles();

  const weeklyBreakdown = (index: number) => `deliveryTeamMembers.${index}.weeklyBreakdown`;
  const { fields } = useFieldArray<EstimationFormFieldsType>({
    control,
    name: weeklyBreakdown(index) as `deliveryTeamMembers.${number}.weeklyBreakdown`,
  });

  return (
    <>
      {fields.map((field, weekIndex) => {
        return (
          <StyledTableCell align='center' key={field.id} >
            <TextField
              size='small'
              className={classes.weekColumn}
              {...register(`deliveryTeamMembers.${index}.weeklyBreakdown.${weekIndex}.hours`)}
              inputProps={{
                'data-testid': `deliveryTeamMembers.${index}.weeklyBreakdown.${weekIndex}.hours`
              }}
              onChange={debounce(async (e) => {
                await setValue(`deliveryTeamMembers.${index}.weeklyBreakdown.${weekIndex}.hours`, Number(e.target.value), {shouldDirty: true});
              }, 500)}
              disabled = {disabled}
            />
          </StyledTableCell>
        );
      })}
    </>
  );
};

export const WeeklyHoursTable: FC<WeeklyHoursTableProps> = ({salesforceLabelList,disabled}) => {
  const { classes } = useStyles();
  const { watch } = useFormContext<EstimationFormFieldsType>();
  const [ totals, setTotals ] = useState<TotalsType>({hoursPerRow: {}, costPerRow: {}, totalCost: 0, totalHours: 0});
  const [ tempTotals, setTempTotals ] = useState<TotalsType>({hoursPerRow: {}, costPerRow: {}, totalCost: 0, totalHours: 0});
  const [ columnValue, setColumnValue ] = React.useState('');
  const [ filterLabelValue, setFilterLabelValue ] = React.useState('');

  const watchDeliveryTeamMembers = watch('deliveryTeamMembers');
  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');
  const watchDuration = watch('duration');
  const { dispatch: notification } = useNotificationContext();
  const columuns = new Set<string>(['Proficiency','Project Cost','Project Hours','Rate','Salesforce label']);
  const columnsOptions = Array.from(columuns)?.sort((a,b) => a.localeCompare(b)).map((item, index) => (<MenuItem key={index} value={item}>{item}</MenuItem>));

  useTeamMemberUpdateHook(watchDeliveryTeamMembers, setTotals, watch as unknown as UseFormReturn['watch']);

  useEffect(()=>{
    let isNotified = false;
    watchDeliveryTeamMembers.length && watchDeliveryTeamMembers?.forEach(teamMember => {
      teamMember?.weeklyBreakdown?.forEach((hour,index)=> {
        const id = `teamMember.${teamMember.id}-${index}`;
        if(hour.hours > 45 && !isNotified){
          notification?.addNotification({
            id,
            severity: 'error',
            message: 'Hours per week exceeds the recommended max of 45. Please review.',
          });
          isNotified = true;
        } else {
          notification?.removeNotification(id);
        }
      });
    });
  },[totals]);

  const numWeekHeaders = () => {
    if (isSameWeek(watchEndDate, (watchStartDate.getDay() == 6 ? addWeeks(watchStartDate, watchDuration + 1):addWeeks(watchStartDate, watchDuration)), {weekStartsOn: 0}) ) {
      return watchDuration + 1;
    }
    return watchDuration;
  };

  const handleColumnChange = (event: SelectChangeEvent) => {
    setColumnValue(event.target.value);
  };

  const handleSelectedLabelChange = (event: SelectChangeEvent) =>{
    setFilterLabelValue(event.target.value);
    const hoursPerRow:  ValuesPerRowType = {};
    const costPerRow: ValuesPerRowType  = {};

    let totalHours = 0;
    let totalCost = 0;
    watchDeliveryTeamMembers.forEach(teamMember => {
      if(!teamMember.isValid) return;
      if(event.target.value){
        if(teamMember.salesforceLabel === event.target.value){
          hoursPerRow[teamMember.id] = teamMember.weeklyBreakdown.reduce((total, current) => total = total + Number(current.hours), 0);
          costPerRow[teamMember.id] = hoursPerRow[teamMember.id] * Number(teamMember.clientRate);
          totalHours += hoursPerRow[teamMember.id];
          totalCost += costPerRow[teamMember.id];
        }
      }else{
        hoursPerRow[teamMember.id] = teamMember.weeklyBreakdown.reduce((total, current) => total = total + Number(current.hours), 0);
        costPerRow[teamMember.id] = hoursPerRow[teamMember.id] * Number(teamMember.clientRate);
        totalHours += hoursPerRow[teamMember.id];
        totalCost += costPerRow[teamMember.id];
      }
    });
    setTempTotals({hoursPerRow, costPerRow, totalCost, totalHours});
  };

  useEffect(()=>{
    setColumnValue('');
    setFilterLabelValue('');
  },[ watchDeliveryTeamMembers]);

  return (
    <Stack direction='column' spacing={1} >
      {
        <Stack direction='row' spacing={4} justifyContent='flex-end' sx={{ paddingBottom : 2 }}>
          <FormControl variant="standard" size="small">
            <Select
              className={classes.dropDown}
              labelId="filter-by-label"
              id="filter-by-label"
              data-testid="filter-by-label"
              value={filterLabelValue}
              onChange={handleSelectedLabelChange}
              displayEmpty={true}
            >
              <MenuItem value=''>Filter by label</MenuItem>
              {salesforceLabelList}
            </Select>
          </FormControl>
          <FormControl variant="standard" size="small">
            <Select
              className={classes.dropDown}
              labelId="sort-by-label"
              id="sort-by"
              data-testid="sort-by"
              value={columnValue}
              onChange={handleColumnChange}
              displayEmpty={true}
            >
              <MenuItem value=''>Sort by</MenuItem>
              {columnsOptions}
            </Select>
          </FormControl>
        </Stack>
      }
      <Stack direction='row' spacing={0} justifyContent='left' alignItems='top' >
        <TableContainer data-testid='WeeklyHoursTable' className={classes.leftTable}>
          <Table aria-label='customized table' stickyHeader={true}>
            <TableHead>
              <TableRow>
                <StyledTableCell align='left' sx={{width: 200}} className={classes.moveRight}>Salesforce Label</StyledTableCell>
                <StyledTableCell align='center' sx={{width: 50}} >Rate</StyledTableCell>
                <StyledTableCell align='center' sx={{width: 110}}>Proficiency</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {watchDeliveryTeamMembers.length && watchDeliveryTeamMembers
                ?.sort((a, b) => {
                  switch (columnValue) {
                  case 'Proficiency':
                    return a.proficiency.localeCompare(b.proficiency);
                  case 'Project Cost':
                    return Number(totals.costPerRow[a.id]) - Number(totals.costPerRow[b.id]);
                  case 'Salesforce label':
                    return a.salesforceLabel.localeCompare(b.salesforceLabel);
                  case 'Project Hours':
                    return Number(totals.hoursPerRow[a.id]) - Number(totals.hoursPerRow[b.id]);
                  case 'Rate':
                    return Number(a.clientRate) - Number(b.clientRate);
                  default:
                    return 1;
                  }
                }).map((field, index) =>  {
                  if(!watchDeliveryTeamMembers[index]?.isValid) return;

                  return (!filterLabelValue || filterLabelValue == field.salesforceLabel) && (
                    <TableRow key={field.id}>
                      <StyledTableCell align='left' className={classes.moveRight}>
                        <Stack spacing={0} justifyContent='left'>
                          {watchDeliveryTeamMembers[index].salesforceLabel}
                          {/*
                        TODO: add this back once we add the ability to update the team member's dates
                        <span className={classes.dateLinks}>
                          Start: {format(watchDeliveryTeamMembers[index].startDate, 'M/dd')}  End: {format(watchDeliveryTeamMembers[index].endDate, 'M/dd')}
                        </span>
                      */}
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell align='center'>{watchDeliveryTeamMembers[index].clientRate.toLocaleString('en-US')}</StyledTableCell>
                      <StyledTableCell align='center'>{watchDeliveryTeamMembers[index].proficiency}</StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer className={classes.middleTable}>
          <Table aria-label='customized table' stickyHeader={true}>
            <TableHead>
              <TableRow>
                {Array.from({length: numWeekHeaders()}, (_v, index) => {
                  const week = addWeeks(getMonday(watchStartDate), index);
                  return (
                    <StyledTableCell align='center' sx={{minWidth: 50, maxWidth: 50}} key={index}>
                      <Stack spacing={0} justifyContent='center'>
                        {`Wk${index+1}`}
                        <span>{format(week, 'M/dd')}</span>
                      </Stack>
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {watchDeliveryTeamMembers.length && watchDeliveryTeamMembers
                ?.sort((a, b) => {
                  switch (columnValue) {
                  case 'Proficiency':
                    return a.proficiency.localeCompare(b.proficiency);
                  case 'Project Cost':
                    return Number(totals.costPerRow[a.id]) - Number(totals.costPerRow[b.id]);
                  case 'Salesforce label':
                    return a.salesforceLabel.localeCompare(b.salesforceLabel);
                  case 'Project Hours':
                    return Number(totals.hoursPerRow[a.id]) - Number(totals.hoursPerRow[b.id]);
                  case 'Rate':
                    return Number(a.clientRate) - Number(b.clientRate);
                  default:
                    return 1;
                  }
                }).map((field, index) =>  {
                  if(!watchDeliveryTeamMembers[index]?.isValid) return;

                  return (!filterLabelValue || filterLabelValue == field.salesforceLabel) &&  (
                    <TableRow key={field.id}>
                      <WeeklyHoursCell index={index} disabled = {disabled}/>
                    </TableRow>
                  );}
                )}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack direction='column' spacing={0} justifyContent='left' alignItems='top' >
          <TableContainer className={classes.rightTable}>
            <Table aria-label='customized table' stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align='center' >Project Hours</StyledTableCell>
                  <StyledTableCell align='center' >Project Cost</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {watchDeliveryTeamMembers.length && watchDeliveryTeamMembers
                  ?.sort((a, b) => {
                    switch (columnValue) {
                    case 'Proficiency':
                      return a.proficiency.localeCompare(b.proficiency);
                    case 'Project Cost':
                      return Number(totals.costPerRow[a.id]) - Number(totals.costPerRow[b.id]);
                    case 'Salesforce label':
                      return a.salesforceLabel.localeCompare(b.salesforceLabel);
                    case 'Project Hours':
                      return Number(totals.hoursPerRow[a.id]) - Number(totals.hoursPerRow[b.id]);
                    case 'Rate':
                      return Number(a.clientRate) - Number(b.clientRate);
                    default:
                      return 1;
                    }
                  }).map((field, index) =>  {
                    if(!watchDeliveryTeamMembers[index].isValid) return;
                    const id = watchDeliveryTeamMembers[index].id;

                    return (!filterLabelValue || filterLabelValue == field.salesforceLabel) &&  (
                      <TableRow key={field.id}>
                        <StyledTableCell align='center'>{(totals.hoursPerRow[id] ?? 0).toLocaleString('en-US')} hrs</StyledTableCell>
                        <StyledTableCell align='center'>
                          {`${(Math.ceil(totals.costPerRow[id]) ?? 0).toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0})}`}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                <TableRow>
                  {filterLabelValue ?
                    <>
                      <TotalCell align='center' className={classes.totals}>{(tempTotals.totalHours ?? 0).toLocaleString('en-US')} hrs</TotalCell>
                      <TotalCell align='center' className={classes.totals}>{(Math.ceil(tempTotals.totalCost) ?? 0).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}</TotalCell>
                    </> :
                    <><TotalCell align='center' className={classes.totals}>{(totals.totalHours ?? 0).toLocaleString('en-US')} hrs</TotalCell>
                      <TotalCell align='center' className={classes.totals}>{(Math.ceil(totals.totalCost) ?? 0).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}</TotalCell>
                    </>
                  }
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography color='text.secondary' className={classes.totalsLabel} >Project Totals</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
