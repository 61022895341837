import type { ClientProfileType } from '@/api/ClientService/client.interface';

export interface StateType {
    clientProfiles: ClientProfileType[] | undefined;
}

export enum ClientActionType {
    ADD_PROFILE = 'ADD_PROFILE',
    UPDATE_PROFILE = 'UPDATE_PROFILE',
    GET_PROFILES = 'GET_PROFILES',
    DELETE_PROFILE = 'DELETE_PROFILE',
}

export type PayloadType =
| { type: ClientActionType.GET_PROFILES, payload: { clientProfiles: ClientProfileType[] } }

export interface ActionsType {
    getProfiles: () => void;
    refresh: () => void;
}

export interface ContextType {
    state: StateType;
    actions: ActionsType | undefined;
}