import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  site: {
    maxWidth: 1920,
    margin: 'auto',
    overflow: 'hidden',
  },
  nav: {
    float: 'right',
    paddingTop: 26,
    marginRight: 20,
  },
});