import type {FC} from 'react';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chip, IconButton, Stack } from '@mui/material';

import type { DeleteChipProps } from './DeleteChip.interface';
import { useStyles } from './DeleteChip.styles';

export const DeleteChip: FC<DeleteChipProps> = ({label, onDelete, width, disabled}) => {
  const { classes } = useStyles();

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      alignItems="center"
      data-testid='DeleteChip'
    >
      <Chip label={label} className={classes.chip} sx={{width: width}} />
      <IconButton onClick={onDelete} title={label} className={classes.button} data-testid='DeleteChip-button' disabled = {disabled}>
        <DeleteIcon color={disabled ?'disabled':'primary'} />
      </IconButton>
    </Stack>
  );
};