import type { FC} from 'react';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, Button, Grid, Stack, Typography} from '@mui/material';

import type { PursuitMemberAPIType } from '@/api/DataService/data.interface';
import { dataService } from '@/api/DataService/data.service';
import DeleteChip from '@/components/DeleteChip';
import Search from '@/components/Search';
import type { EstimationFormFieldsType } from '@/pages/Estimation/Estimation.interface';
import { useLoadingContext } from '@/store/loading';

import type { PursuitTeamFormProps } from './PursuitTeamForm.interface';
import { useStyles } from './PursuitTeamForm.styles';


export const PursuitTeamForm: FC<PursuitTeamFormProps> = ({fields, onAdd, onRemove, disabled}) => {

  const { classes } = useStyles();
  const { dispatch: loader } = useLoadingContext();
  const [inputText, setInputText] = useState<(PursuitMemberAPIType | string)>('');
  const [optionsLoading, setOptionsLoading] = useState<boolean>(false);
  const [pursuitOptions, setPursuitOptions] = useState<(PursuitMemberAPIType | string)[]>([]);
  const { watch } = useFormContext<EstimationFormFieldsType>();

  const watchPursuitTeamMembers = watch('pursuitTeamMembers');

  const handlePursuitSearch = async (inputText: string) => {
    setInputText(inputText);
    setOptionsLoading(true);
    const res = await dataService.getEmployeeByQuery(inputText);
    setPursuitOptions(res);
    setOptionsLoading(false);
  };

  const handleSubmit = async (input: PursuitMemberAPIType | string) => {
    if (input != '') {
      if (typeof input === 'string') {
        if (pursuitOptions.length == 0) {
          onAdd(input);
          setInputText('');
        }
      } else {
        loader?.setLoading({enable: true});
        const res = await dataService.getEmployeeById(input.id);
        onAdd(res.email);
        setInputText('');
        loader?.setLoading({enable: false});
      }
    }
  };


  useEffect(() => {
    return () => clearTimeout(timeoutId);
  });
  let timeoutId: NodeJS.Timeout;
  const handleInputChange = (value: string) => {
    clearTimeout(timeoutId); // Clear previous timeout
    timeoutId = setTimeout(async () => {
      console.log(value);
      await handlePursuitSearch(value);
    }, 1000);
  };

  const clearSearchText = () => {
    setInputText('');
  };

  return (
    <div data-testid="PursuitTeamForm" className={classes.divStyle}>
      <Stack
        direction='column'
        className={classes.stackStyle}
        spacing={1}
      >
        <Autocomplete
          options={pursuitOptions}
          loading={optionsLoading}
          noOptionsText={'No results. Please manually submit email.'}
          value={inputText}
          data-testid="PursuitTextBox"
          onChange={(event, value) => {
            if (value) {
              setInputText(value);
            }
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            } else {
              return option.name;
            }
          }}
          renderOption={(props, option) =>
            <li data-testid={`option-${(option as PursuitMemberAPIType).name}`} {...props}>
              <Stack>
                <Typography>
                  {(option as PursuitMemberAPIType).name}
                </Typography>
                <Typography className={classes.subLabelText}>
                  {`${(option as PursuitMemberAPIType).title} | ${(option as PursuitMemberAPIType).market}`}
                </Typography>
              </Stack>
            </li>
          }
          renderInput={(props) =>
            <Search
              props={props}
              onSubmit={handleSubmit}
              label="Add a Team Member"
              value={inputText}
              showOptionalCloseIcon={true}
              clearSearchText={clearSearchText}
              onChange={(e) => {
                handleInputChange(e.target.value);
              }}
            />
          }
          disabled = {disabled}
        />
        <Button
          data-testid='add-delivery-team-member'
          startIcon={<AddIcon />}
          variant='outlined'
          size='small'
          onClick={() => {
            handleSubmit(inputText);
          }}
          style={{ width: '90px'}}
          disabled = {disabled}
        >
          Add
        </Button>
      </Stack>

      <Grid className={classes.gridStyle} container direction="row" columnGap={2} rowGap={1}>
        {fields.map((field, index) =>  {
          return (
            <DeleteChip
              key={field.id}
              width={'130px'}
              label={watchPursuitTeamMembers[index].email}
              onDelete={() => {
                onRemove(index);
              }}
              disabled = {disabled}
            />
          );
        })}
      </Grid>
    </div>
  );
};