import type { PropsWithChildren} from 'react';
import React, { createContext, useContext, useReducer } from 'react';

import { type UnknownAction,useDispatchers } from '@/store/utils';

import type {LoadingState } from './loading.interface';
import { deafultLoadingContext,loadingActions} from './loading.interface';

const LoadingContext = createContext(deafultLoadingContext);
LoadingContext.displayName = 'LoadingContext';
const { setLoading } = loadingActions;
const reducer = (state: LoadingState, action: UnknownAction) => {
  if(setLoading.checker(action)) {
    return { ...state, loadingState: {
      loading: action.payload.enable,
      message: action.payload.message
    }};
  }
  return state;
};


export function LoadingContextProvider(props: PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, deafultLoadingContext.state);
  const loadingDispatch = useDispatchers(loadingActions, dispatch);

  return (
    <LoadingContext.Provider value={{ state: state, dispatch: loadingDispatch }}>
      {props.children}
    </LoadingContext.Provider>
  );
}

export const useLoadingContext = () =>  useContext(LoadingContext);