import { addWeeks,  differenceInBusinessDays, isSameWeek } from 'date-fns';

import { roundDecimal } from '@/utils/math/math-helper';

import type { DeliveryTeamMemberType, EstimationFormFieldsType, InvestmentType } from './Estimation.interface';

export const isTeamMemberValid = (teamMember: DeliveryTeamMemberType) => {
  return teamMember?.clientRate !== ''
        && teamMember?.defaultHoursPerWeek !== ''
        && teamMember?.practice !== ''
        && teamMember?.proficiency !== ''
        && teamMember?.salesforceLabel !== ''
        && teamMember?.slalomRole !== '';
};

export const getMonday = (date: Date) => {
  const d = new Date(date);
  const day = d.getDay();
  const diff = d.getDate() - day + (day == 6 ? day + 2:1);
  return new Date(d.setDate(diff));
};

export const createWeeklyBreakdown = (
  duration: number,
  startDate: Date,
  endDate: Date,
  contingency: number,
  teamMember: DeliveryTeamMemberType): DeliveryTeamMemberType['weeklyBreakdown'] =>
{
  const hours = Math.floor(teamMember?.contingency ? Number(teamMember?.defaultHoursPerWeek) * (1 + contingency) : Number(teamMember?.defaultHoursPerWeek));

  const numHoursInputCells = (
    (isSameWeek(endDate, (startDate.getDay() == 6 ? addWeeks(startDate, duration + 1):addWeeks(startDate, duration)), {weekStartsOn: 0}))
  ) ? duration + 1 : duration;
  const weeklyBreakdown = Array.from({length: numHoursInputCells}, () => ({hours}));
  return weeklyBreakdown;
};

export const calculateSummaryValues = (
  deliveryTeamMembers: EstimationFormFieldsType['deliveryTeamMembers'],
): Pick<EstimationFormFieldsType, 'totalRateDiscounts' | 'projectCost' | 'marketValue'> => {
  let marketValue = 0;
  let projectCost = 0;
  let totalRateDiscounts = 0;

  deliveryTeamMembers.forEach((member) => {
    if(!member.isValid) return;

    const hours = member.weeklyBreakdown.reduce((total, current) => total = total + Number(current.hours), 0);
    const slalomRate = Number(member.slalomRate);
    const clientRate = Number(member.clientRate);
    const marketCost = hours * slalomRate;
    const clientCost = hours * clientRate;

    marketValue += marketCost;
    projectCost += clientCost;
  });

  totalRateDiscounts = (1 - (projectCost / marketValue)) * 100;

  marketValue = Number.isNaN(marketValue) ? 0 : marketValue;
  totalRateDiscounts = Number.isNaN(totalRateDiscounts) ? 0 : roundDecimal(totalRateDiscounts);
  projectCost = Number.isNaN(projectCost) ? 0 : projectCost;

  return {
    marketValue,
    totalRateDiscounts,
    projectCost
  };
};

export const calculateInvestment = (
  slalomInvestment: number,
  slalomInvestmentType: InvestmentType,
  otherInvestment: number,
  otherInvestmentType: InvestmentType,
  projectCost: number,
) => {
  let slalomPercent = slalomInvestment / 100;
  if(slalomInvestmentType === 'currency') {
    slalomPercent = (Number(slalomInvestment) / projectCost);
  }

  let otherPercent = otherInvestment / 100;
  if(otherInvestmentType === 'currency') {
    otherPercent = (Number(otherInvestment) / projectCost);
  }

  const percent = slalomPercent + otherPercent;
  const currency = percent * projectCost;
  const clientResponsibility = projectCost - currency;

  return {
    percent: Number.isNaN(percent) ? 0 : roundDecimal(percent),
    currency: Number.isNaN(currency) ? 0 : Math.round(currency),
    clientResponsibility: Number.isNaN(clientResponsibility) ? 0 : (clientResponsibility < 0 ? 0 : clientResponsibility),
  };
};

export const getDuration = (startDate: Date, endDate: Date) => {
  const diffBusinessDays = differenceInBusinessDays(endDate, startDate) + 1;
  return Math.ceil(diffBusinessDays / 5);
};

export const calculateInvestmentValue = (
  projectCost: number,
  type: InvestmentType,
  value: number
) => {
  let val = 0;

  if(type === 'currency') {
    // changed from percent
    val = (Number(value) / 100) * Number(projectCost);
  } else {
    // change from currecny
    val = (Number(value) / Number(projectCost)) * 100;
  }

  return Number.isNaN(val) ? 0 : val;
};

export const convertPercentToCurrency = (investmentType: string, funding: number, projectCost: number): number => {
  if(investmentType == 'percentage'){
    return (Number(funding) / 100) * Number(projectCost);
  }
  return funding;
};
