import type {FC} from 'react';
import React, { useEffect} from 'react';
import { useFieldArray,useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import type {
  SelectChangeEvent} from '@mui/material';
import {
  Button as MuiButton,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,  Switch,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography} from '@mui/material';
import { styled } from '@mui/material/styles';

import { createSlalomRolesProficiencies } from '@/containers/DeliveryTeamForm/DeliveryTeamForm';
import { getSlalomRate } from '@/pages/ClientProfile/ClientProfile.func';
import type { Proficiency } from '@/utils/constants';

import type { ClientProfileFormProps, ClientProfileFormType, ClientRolesType } from './ClientProfileForm.interface';
import { useStyles } from './ClientProfileForm.styles';

export const emptyClientRole: ClientRolesType = {
  clientRole: '',
  slalomRole: '',
  slalomProficiency: '',
  clientRate: 0,
  isFixedRate: false,
  slalomRate: 0,
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.text.secondary,
    border: 0,
    padding:'6px 20px',
    fontSize: 11,
    lineHeight: '16px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '13px',
  },
  ' td, th': {
    border: 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  ' td, th': {
    border: 0,
    padding:'6px 20px',
  },
  'div,input':{
    fontSize: 13,
    lineHeight: '24px',
    color: theme.palette.text.secondary,

  }
}));
const StyledTextField = styled(TextField)(() => ({
  'div': {
    margin:0,
    maxWidth:170,
  }
}));

const StyledTextFieldTypeNumber = styled(TextField)(({ theme }) => ({
  margin:0,
  width:'50px',
  color: theme.palette.grey[600]
}));

export const ClientProfileForm: FC<ClientProfileFormProps> = ({marketRates}) => {
  const { classes } = useStyles();
  const { control, register, getValues, setValue, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'roles',
  });
  const [slalomRolesArray, setSlalomRoles] = React.useState<{role: string; proficiencies: string[]}[]>([]);
  const [slalomProficiencies, setSlalomProficiencies] =  React.useState<string[][]>();
  const slalomRoles = marketRates?.map(rate => rate.role).sort((a, b) => a.localeCompare(b)) ?? [];
  const watchClientRoles = watch('roles');

  useEffect(() => {
    let slalomRoleArray: { role: string; proficiencies: string[]; }[] = [];
    slalomRoleArray = createSlalomRolesProficiencies(marketRates);
    slalomRoleArray.sort((a,b) => a.role.localeCompare(b.role));
    setSlalomRoles(slalomRoleArray);
    const subscription = watch((value, { name }) => {
      const clientRoles = value as ClientProfileFormType;
      if(name?.includes('roles')) {
        const split = name.split('.');
        const index = Number(split[1]);
        const action: keyof ClientRolesType = split[2] as keyof ClientRolesType;
        const rolesList = clientRoles.roles;
        if(action === 'slalomRole' || action === 'slalomProficiency') {
          const role = rolesList[index].slalomRole;
          const prof: Proficiency = rolesList[index].slalomProficiency as Proficiency;
          if (role && prof) {
            const rate = getSlalomRate(marketRates,role,prof);
            setValue(`roles.${index}.slalomRate`, rate);
          }
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, marketRates]);

  useEffect(() => {
    let watchDeliveryTeamMembersSlalomRoles: string[] = [];
    watchDeliveryTeamMembersSlalomRoles = watchClientRoles?.map((item: { slalomRole: string; }) => item.slalomRole) as string[];
    const filteredRoles = watchDeliveryTeamMembersSlalomRoles?.map(
      role => slalomRolesArray.find(item => item?.role === role)
    ).filter(Boolean);
    const proficiencies: string[][] = [];
    if (filteredRoles != undefined) {
      for (const slalomRole of filteredRoles) {
        if(slalomRole != undefined) {
          proficiencies.push(slalomRole.proficiencies);
        }
      }
    }
    setSlalomProficiencies(proficiencies);
  }, [watch, marketRates, slalomRolesArray]);

  const updateProficiencies = () => {
    setSlalomProficiencies([]);
    let watchDeliveryTeamMembersSlalomRoles: string[] = [];
    watchDeliveryTeamMembersSlalomRoles = watchClientRoles?.map((item: { slalomRole: string; }) => item.slalomRole) as string[];
    const filteredRoles = watchDeliveryTeamMembersSlalomRoles?.map(
      role => slalomRolesArray.find(item => item?.role === role)
    ).filter(Boolean);
    const proficiencies: string[][] = [];
    if (filteredRoles != undefined) {
      for (const slalomRole of filteredRoles) {
        if(slalomRole != undefined) {
          proficiencies.push(slalomRole.proficiencies);
        }
      }
    }
    setSlalomProficiencies(proficiencies);
  };

  const updateProficiency = (index: number, event: SelectChangeEvent) => {
    const roleName = event.target.value;
    watchClientRoles[index].slalomRole = roleName;
    updateProficiencies();
  };

  return (
    <div data-testid="ClientProfileForm" className={classes.root}>
      <Grid container spacing={2} data-testid="profileForm">
        <Grid item xs={12}>
          <TableContainer data-testid="RatesRolesForm" className={classes.tableContainer}>
            <Table aria-label="customized table" className={classes.table} sx={{ minWidth: 1030 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Client role</StyledTableCell>
                  <StyledTableCell align="left">Equivalant Slalom role*</StyledTableCell>
                  <StyledTableCell align="left">Equivalant Slalom proficiency*</StyledTableCell>
                  <StyledTableCell align="left">Market rate <Tooltip sx={{padding: 0}} title={'Displayed rates are based on your market.'}><IconButton><InfoIcon color='primary' style={{ fontSize: 15 }}/></IconButton ></Tooltip></StyledTableCell>
                  <StyledTableCell align="left">Client rate* <Tooltip sx={{padding: 0}} title={'Rate to be used to generate cost estimate. If “Negotiated Fixed Rate” is toggled on, pursuit team will not be able to override this rate.'}><IconButton><InfoIcon color='primary' style={{ fontSize: 15 }}/></IconButton ></Tooltip></StyledTableCell>
                  <StyledTableCell align="left">Delete</StyledTableCell>
                  <StyledTableCell align="left">Negotiated fixed rate</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((field, index) =>  {
                  return (
                    <StyledTableRow key={field.id}>
                      <StyledTableCell component="th" scope="row">
                        <StyledTextField
                          {...register(`roles.${index}.clientRole`)}
                          data-testid={`roles.${index}.clientRole`}
                          value={getValues(`roles.${index}.clientRole`)}
                          size="small"
                          placeholder="Enter client role"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <FormControl variant="standard" fullWidth>
                          <Select
                            className={classes.dropDown}
                            {...register(`roles.${index}.slalomRole`)}
                            inputProps={{ 'data-testid': `roles.${index}.slalomRole`}}
                            value={getValues(`roles.${index}.slalomRole`) || ''}
                            onChange={async (e) => {
                              updateProficiency(index, e);
                              await setValue(`roles.${index}.slalomRole`, e.target.value, {shouldDirty: true, shouldValidate: true});
                            }}
                            displayEmpty
                          >
                            <MenuItem value=''>Select option</MenuItem>
                            {slalomRoles?.map((item:string) =>(<MenuItem key={item} value={item}>{item}</MenuItem>) )}
                          </Select>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <FormControl variant="standard" fullWidth>
                          <Select
                            className={classes.dropDown}
                            {...register(`roles.${index}.slalomProficiency`)}
                            inputProps={{ 'data-testid': `roles.${index}.slalomProficiency`}}
                            value={getValues(`roles.${index}.slalomProficiency`) || ''}
                            displayEmpty
                          >
                            <MenuItem value=''>Select option</MenuItem>
                            {slalomProficiencies?.[index]?.map((item, i) => <MenuItem key={i} value={item}>{item}</MenuItem>)}
                          </Select>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <StyledTextFieldTypeNumber
                          {...register(`roles.${index}.slalomRate`)}
                          data-testid={`roles.${index}.slalomRate`}
                          value={watchClientRoles[index].slalomRate || ''}
                          size='small'
                          InputProps={{
                            disabled: true,
                            startAdornment:
                              <InputAdornment position="start">
                                <Typography color='lightgrey'>$</Typography>
                              </InputAdornment>
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <StyledTextFieldTypeNumber
                          {...register(`roles.${index}.clientRate`)}
                          data-testid={`roles.${index}.clientRate`}
                          defaultValue={getValues(`roles.${index}.clientRate`)}
                          size='small'
                          InputProps={{
                            startAdornment:
                              <InputAdornment position="start">
                                <Typography>$</Typography>
                              </InputAdornment>
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <IconButton
                          data-testid={`roles.${index}.DeleteRowButton`}
                          onClick={() => remove(index)}
                          className={classes.deleteButton}
                        >
                          <DeleteIcon color='primary' />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Switch
                          {...register(`roles.${index}.isFixedRate`)}
                          data-testid={`roles.${index}.isFixedRate`}
                          defaultChecked={getValues(`roles.${index}.isFixedRate`)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <MuiButton data-testid='add-role-button' startIcon={<AddIcon />} size='small' variant="outlined" onClick={() => append(emptyClientRole)}>
            ADD ROLE
          </MuiButton>
        </Grid>
      </Grid>
    </div>
  );
};