import type { CostAPIType, CostsRolesAPIType, CostsRolesType, CostType, UpdateCostRequestType } from '@/api/CostsService/costs.interface';

export const mapToCostsRolesType = (data: CostsRolesAPIType): CostsRolesType => {
  return {
    role: data.role,
    foundationalCost: data.foundational,
    proficientCost: data.proficient,
    advancedCost: data.advanced,
    expertCost: data.expert,
    lastUpdated: new Date(data.last_updated),
  };
};

export const mapToCostType = (data: CostAPIType): CostType => {
  return {
    market: data.market.split('_cost_data')[0],
    revisedDate: new Date(data.revised_date),
    capabilities: data.capabilities,
    costs: data.costs.map((item: CostsRolesAPIType) => {
      return mapToCostsRolesType(item);
    })
  };
};

export const mapFormToRequest = (form: UpdateCostRequestType): Partial<CostAPIType> => {
  return {
    market: form.market + '_cost_data',
    capabilities: form.capabilities,
    costs: form.costs.map((obj) => ({
      role: obj.role,
      foundational: obj.foundationalCost,
      proficient: obj.proficientCost,
      advanced: obj.advancedCost,
      expert: obj.expertCost,
      last_updated: obj.lastUpdated.toString(),
      modified: obj.modified
    }))
  };
};