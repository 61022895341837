import type {FC} from 'react';
import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import type { SelectChangeEvent} from '@mui/material';
import { Button, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack,Switch,Typography } from '@mui/material';

import type { EstimatesSummaryTableProps,EstimateSummaryInfo } from '@/components/EstimatesSummaryTable';
import { EstimatesSummaryTable } from '@/components/EstimatesSummaryTable';
import { PageLayout } from '@/components/PageLayout';
import { Search } from '@/components/Search';
import { paths } from '@/services/Router/paths';
import { useAuthContext } from '@/store/auth';
import { useEstimateContext } from '@/store/estimates';

import { useStyles } from './Home.styles';

export const Home: FC = () => {
  const navigation = useNavigate();
  const {state: authState} = useAuthContext();

  const [searchText, setSearchText] = useState('');
  const [clientValue, setClientValue] = React.useState('');
  const [marketValue, setMarketValue] = React.useState('');
  const [showOthersEstimates, setShowOthersEstimates] = React.useState(false);
  const [showHistoricalEstimates, setShowHistoricalEstimates] = React.useState(false);
  const [estimatesSummaryTableProps, setEstimateSummaryProps] = React.useState({estimatesInfo: []} as EstimatesSummaryTableProps);
  const [filteredEstimatesSummary, setFilteredEstimatesSummary] = useState({...estimatesSummaryTableProps});
  const [clientList, setClientList] = React.useState<JSX.Element[]>([]);
  const [marketList, setMarketList] = React.useState<JSX.Element[]>([]);

  const createMenuItems = (options: Set<string>): JSX.Element[] => {
    const marketOptions = Array.from(options)?.sort((a,b) => a.localeCompare(b)).map((item, index) => (<MenuItem key={index} value={item}>{item}</MenuItem>));
    return marketOptions;
  };

  const getClients = (): JSX.Element[] => {
    const clients = new Set<string>();
    estimatesSummaryTableProps.estimatesInfo?.map(estimate => {
      clients.add(estimate.clientName);
    });
    return createMenuItems(clients);
  };
  //unique markets
  const getMarkets = (): JSX.Element[] => {
    const markets = new Set<string>();
    estimatesSummaryTableProps.estimatesInfo?.map(estimate =>{
      markets.add(estimate.market);
    });
    return createMenuItems(markets);
  };
  const clientOptions = getClients();
  const marketOptions = getMarkets();

  const {state: estimates, estimateService} = useEstimateContext();

  React.useEffect(() => {
    let userMarket = '';
    if(authState.user?.market){
      userMarket = authState.user.market;
      if(marketValue === ''){
        setMarketValue(userMarket);
      }
    }
  }, [authState.user?.market]);

  React.useEffect(() => {
    setEstimateSummaryProps({ estimatesInfo: estimates.summaries || [] });
  }, [estimates.summaries]);

  React.useEffect(() => {
    if(clientValue !=='' || marketValue !== ''){
      filterEstimateSummaryTable(clientValue,marketValue);
    }else{
      setFilteredEstimatesSummary({...estimatesSummaryTableProps});
    }
    setClientList(clientOptions);
    setMarketList(marketOptions);
  }, [estimatesSummaryTableProps]);

  React.useEffect(() => {
    estimateService.getEstimatesSummary({ showOthersEstimates, showHistoricalEstimates });
  }, [showOthersEstimates, showHistoricalEstimates]);

  const handleClientChange = (event: SelectChangeEvent) => {
    setClientValue(event.target.value as string);
    if (event.target.value==='') {
      const clientOptions = getClients();
      setClientList(clientOptions);
      const marketOptions = getMarkets();
      setMarketList(marketOptions);
    } else {
      const uniqueMarkets = new Set(
        estimatesSummaryTableProps.estimatesInfo
          .filter((item) => item.clientName === event.target.value)
          .map((estimate) => estimate.market)
      );
      const marketOptions = createMenuItems(uniqueMarkets);
      setMarketList(marketOptions);
    }
    filterEstimateSummaryTable(event.target.value,marketValue);
  };

  const handleMarketChange = (event: SelectChangeEvent) => {
    setMarketValue(event.target.value as string);
    if (event.target.value ==='') {
      const clientOptions = getClients();
      setClientList(clientOptions);
      const marketOptions = getMarkets();
      setMarketList(marketOptions);
    } else {
      const uniqueClients = new Set(
        estimatesSummaryTableProps.estimatesInfo
          .filter((item) => item.market === event.target.value)
          .map((estimate) => estimate.clientName)
      );
      const clientOptions = createMenuItems(uniqueClients);
      setClientList(clientOptions);
    }

    filterEstimateSummaryTable(clientValue,event.target.value );
  };

  const handleOthersEstimatesChange = (event: React.ChangeEvent<HTMLInputElement>, newState: boolean) => {
    setShowOthersEstimates(newState);
  };

  const handleHistoricalEstimatesChange = (event: React.ChangeEvent<HTMLInputElement>, newState: boolean) => {
    setShowHistoricalEstimates(newState);
  };

  const handleEstimatesSearch = (text: string) => {
    let filteredEstimateSummaryTable: EstimateSummaryInfo[] =[];
    filteredEstimateSummaryTable = estimatesSummaryTableProps.estimatesInfo.filter((item) => SearchItem(item, text));
    setFilteredEstimatesSummary({estimatesInfo:filteredEstimateSummaryTable});
  };

  const SearchItem = (item: EstimateSummaryInfo, text: string) => {
    let isFound = false;
    JSON.stringify(item, (key, val) => {
      if (key != 'salesforceLink'
        && key != 'teamsLink'
        && typeof val === 'string'
        && val.toLowerCase().includes(text.toLowerCase())) isFound = true;
      return val;
    });
    return isFound;
  };

  const gotoNewEstimatimationPage = () => {
    navigation(paths.estimation);
  };

  const filterEstimateSummaryTable = (byClient?: string, byMarket?: string) =>{
    let filteredEstimateSummaryTable: EstimateSummaryInfo[] =[];

    if (byClient !== '' && byMarket !== '') {
      filteredEstimateSummaryTable = estimatesSummaryTableProps.estimatesInfo.filter(estimate => estimate.clientName == byClient && estimate.market=== byMarket);
    } else if (byClient !== '') {
      filteredEstimateSummaryTable =  estimatesSummaryTableProps.estimatesInfo.filter(estimate => estimate.clientName === byClient);
    } else if (byMarket !== '') {
      filteredEstimateSummaryTable = estimatesSummaryTableProps.estimatesInfo.filter(estimate => estimate.market=== byMarket);
    } else {
      filteredEstimateSummaryTable = estimatesSummaryTableProps.estimatesInfo;
    }

    setFilteredEstimatesSummary({estimatesInfo:filteredEstimateSummaryTable});
  };

  const clearSearchText = () => {
    setFilteredEstimatesSummary({...estimatesSummaryTableProps});
    setSearchText('');
  };

  const { classes } = useStyles();

  return (
    <PageLayout title={'Your Estimates'}>
      <Grid container spacing={2} data-testid="Home">
        <Grid item xs={12}>
          <Button
            data-testid="create-new-estimate-button"
            onClick={gotoNewEstimatimationPage}
            startIcon={<AddIcon />}
          >
            Create New Estimate
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.stackRow}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Search
              label='Search'
              value={searchText}
              showOptionalCloseIcon={true}
              clearSearchText={clearSearchText}
              onChange={(v) => {
                setSearchText(v.target.value);
                handleEstimatesSearch(v.target.value);
              }}
              onSubmit={handleEstimatesSearch}
            />
            <FormControl variant="standard" size="small">
              <InputLabel id="client-select-label" variant="standard" shrink={true}>
                <Typography variant="h5">Filter by client</Typography>
              </InputLabel>
              <Select
                className={classes.dropDown}
                labelId="client-select-label"
                id="client-select"
                inputProps={{ 'data-testid': 'client-select' }}
                value={clientValue}
                onChange={handleClientChange}
                displayEmpty={true}
              >
                <MenuItem value=''>Select option</MenuItem>
                {clientList}
              </Select>
            </FormControl>
            <FormControl variant="standard" size="small">
              <InputLabel id="market-select-label"  variant="standard" shrink={true} >
                <Typography variant="h5">Filter by market</Typography>
              </InputLabel>
              <Select
                className={classes.dropDown}
                labelId="market-select-label"
                id="market-select"
                data-testid="market-select"
                value={marketValue}
                onChange={handleMarketChange}
                displayEmpty={true}
                renderValue={marketValue !== '' ? undefined : () => 'Select option'}
              >
                <MenuItem value={marketValue}>Select option</MenuItem>
                {marketList}
              </Select>
            </FormControl>
            <FormControlLabel className={classes.toggle} control=
              {<Switch checked={showOthersEstimates} onChange={handleOthersEstimatesChange} name="otherEstimates" size='label' />}
            label="See other's estimates"
            />
            <FormControlLabel className={classes.toggle} control=
              {<Switch checked={showHistoricalEstimates} onChange={handleHistoricalEstimatesChange} name="historicalEstimates" size='label' />}
            label="See historical estimates"
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <EstimatesSummaryTable  {...filteredEstimatesSummary} />
        </Grid>
      </Grid>
    </PageLayout>
  );

};
