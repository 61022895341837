import type { PropsWithChildren} from 'react';
import React, { createContext, useContext, useMemo, useReducer } from 'react';

import { EstimateService } from '@/api/EstimateService/estimate.service';
import type { EstimateSummaryInfo } from '@/components/EstimatesSummaryTable';
import { useLoadingContext } from '@/store/loading';
import { useNotificationContext } from '@/store/notification';
import { buildEmptyDispatchers, type UnknownAction,useDispatchers} from '@/store/utils';

import { estimateSummarySorter } from './estimates.functions';
import type { EstimatesState } from './estimates.interface';
import { estimatesActions } from './estimates.interface';

export type EstimatesContextValue = {
  state: EstimatesState;
  estimateService: EstimateService;
};

const emptyDispatchers = buildEmptyDispatchers(estimatesActions);
export const defaultEstimatesContext: EstimatesContextValue = {
  state: {
    summaries: undefined,
    details: {},
  },
  estimateService: new EstimateService(emptyDispatchers, () => undefined, () => undefined),
};

export const EstimateContext = createContext<EstimatesContextValue>(defaultEstimatesContext);
EstimateContext.displayName = 'EstimateContext';

function replaceSummary(estimates: EstimateSummaryInfo[], newEstimate: EstimateSummaryInfo) {
  const newEstimates = [...estimates.filter((estimate) => estimate.id !== newEstimate.id), newEstimate];
  newEstimates.sort(estimateSummarySorter);
  return newEstimates;
}

const {
  receivedSummaries,
  receivedOneSummary,
  deleteEstimateSuccess,
  receivedDetails,
} = estimatesActions;

export const estimatesReducer = (state: EstimatesState, action: UnknownAction): EstimatesState => {

  if(receivedSummaries.checker(action)) {
    const estimates = action.payload.sort(estimateSummarySorter);
    return { ...state, summaries: estimates };
  }

  else if(receivedOneSummary.checker(action)) {
    const estimates = replaceSummary(state.summaries ?? [], action.payload);
    return { ...state, summaries: estimates };
  }

  else if(deleteEstimateSuccess.checker(action)) {
    const estimates = (state.summaries ?? [])
      .filter(({id}) => id !== action.payload);
    const details = { ...state.details };
    delete details[action.payload];
    return { ...state, summaries: estimates, details };
  }

  else if(receivedDetails.checker(action)) {
    const summary = EstimateService.mapEstimatesAPITypeToEstimateSummaryInfo(action.payload);
    const estimates = replaceSummary(state.summaries ?? [], summary);
    const details = { ...state.details, [action.payload.estimate_id]: action.payload};

    return { ...state, details, summaries: estimates };
  }

  // Default if no action recognized
  return state;
};

export function EstimateContextProvider(props: PropsWithChildren) {
  const { dispatch: loadingDispatch } = useLoadingContext();
  const { dispatch: notificationDispatch } = useNotificationContext();
  const [state, dispatch] = useReducer(estimatesReducer, defaultEstimatesContext.state);
  const dispatchers = useDispatchers(estimatesActions, dispatch);
  const estimateService = useMemo(() => new EstimateService(
    dispatchers,
    notificationDispatch.addNotification,
    loadingDispatch.setLoading,
  ), [loadingDispatch, dispatchers, notificationDispatch]);

  return (
    <EstimateContext.Provider value={{ state, estimateService }}>
      {props.children}
    </EstimateContext.Provider>
  );
}

export const useEstimateContext = () => useContext(EstimateContext);