
export interface AzureADUser {
    employeeId: string;
}

export type UserState = {
    user: AzureADUser
}

export enum AzureActionsType {
    GET_USER = 'GET_USER',
}

export type PayloadType =
| { type: AzureActionsType.GET_USER, payload: { user: AzureADUser } }


export interface AzureActions {
    getUser: () => void
}

export interface AzureContextType {
    state: UserState
    actions: AzureActions | undefined
}