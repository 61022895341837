import * as yup from 'yup';

import type {CostsRolesFormType } from '@/containers/CostsRolesForm';

export const schema = yup.object<CostsRolesFormType>({
  roleInfo: yup.array().of(
    yup.object().shape({
      role: yup.string().required(),
      foundationalCost: yup.number().min(0).required(),
      proficientCost: yup.number().min(0).required()
        .test({
          name: 'isGreaterThanFoundational',
          message: 'Foundational cost cannot be greater than Proficient cost',
          test: function (value) {
            if (value != 0) {
              return value > this.parent.foundationalCost;
            }
            return true;
          },
        }),
      advancedCost: yup.number().min(0).required()
        .test({
          name: 'isGreaterThanProficient',
          message: 'Proficient cost cannot be greater than Advanced cost',
          test: function (value) {
            if (value != 0) {
              return value > this.parent.proficientCost;
            }
            return true;
          },
        }),
      expertCost: yup.number().min(0).required()
        .test({
          name: 'isGreaterThanAdvanced',
          message: 'Advanced cost cannot be greater than Expert cost',
          test: function (value) {
            if (value != 0) {
              return value > this.parent.advancedCost;
            }
            return true;
          },
        })
    })
  )
});