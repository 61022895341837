import type { WeeklyHoursType } from '@/pages/Estimation/Estimation.interface';

// Data types from the API
export interface RatesRolesAPIType {
  expert: number,
  last_updated: string, //yyyy-mm-dd
  role: string,
  advanced: number,
  foundational: number,
  proficient: number
}

export interface CapabilitiesType {
  capability: string,
  practice: string[],
}

export interface RateAPIType {
  market: string,
  revised_date: string, //yyyy-mm-dd
  rates: RatesRolesAPIType[],
  capabilities: CapabilitiesType[],
}

// Data types for the UI
export interface RatesRolesType {
  role: string,
  foundationalRate: number,
  proficientRate: number,
  advancedRate: number,
  expertRate: number,
  lastUpdated: Date,
}

export interface UpdateRatesRolesType extends RatesRolesType {
  modified: boolean
}

export interface RateType {
  market: string,
  revisedDate: Date,
  rates: RatesRolesType[],
  capabilities: CapabilitiesType[],
}

export interface UpdateRateRequestType extends RateType {
  rates: UpdateRatesRolesType[]
}

export interface MarginData {
  slalomRole: string,
  slalomRate: number,
  clientRate: number,
  proficiency: string,
  weeklyBreakdown: WeeklyHoursType[]
}

export interface MarginDataItemRequest {
  teamMembers: MarginData[],
  partner_other_funding: number,
  slalom_funding: number,
  market: string
}

export interface MarginDataItemResponse {
  marketRateMargin: number,
  estimatedProjectMargin: number
}

export function getRatesDefaultValues(selectedMarket: string): RateType {
  return {
    market: selectedMarket,
    revisedDate: new Date(),
    rates: [
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Application Integration Engineer',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Cloud Architect',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Cloud Engineer',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Data Analyst',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Data Architect',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Data Engineer',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Data Guardian',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Data Scientist',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Data Strategist',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Data Visualization Specialist',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'DevOps Engineer',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Engineering Lead',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Enterprise Architect',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Experience Designer',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Experience Strategist',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Industry Practitioner',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Platform Solution Architect',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Platform Specialist',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Platform Technical Architect',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Quality Engineer',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Security Engineer',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Software Engineer',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Solution Architect',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Technology Consultant',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Technology Strategist',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Visual Designer',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Accountable Executive',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Business Analyst',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Business Architect',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Business Strategist',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Change Manager',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Customer Strategist',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Digital Strategist',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Engagement Lead',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Learning & Development Specialist',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Organizational Strategist',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Process Optimization Specialist',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Product Manager',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Product Owner',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Product Strategist',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Program Manager',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Project Manager',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Scrum Master',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Solution Owner',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      },
      {
        expertRate: 0,
        lastUpdated: new Date(),
        role: 'Talent Strategist',
        advancedRate: 0,
        foundationalRate: 0,
        proficientRate: 0
      }
    ],
    capabilities: [
      {
        capability: 'Business Advisory Services',
        practice: [
          'Agile Enablement',
          'Associates',
          'Business Transformation',
          'Client / Practice Leadership',
          'Connected Planning',
          'Customer Strategy',
          'Experience Led Strategy Alignment (ESA)',
          'Organizational Effectiveness',
          'Process Optimization',
          'Product Management',
          'Program Portfolio Management',
          'Strategy & Operations'
        ]
      },
      {
        capability: 'Client Leadership',
        practice: [
          'Client / Practice Leadership'
        ]
      },
      {
        capability: 'Data & Analytics',
        practice: [
          'Data & Analytics'
        ]
      },
      {
        capability: 'Experience Design',
        practice: [
          'Experience Design'
        ]
      },
      {
        capability: 'Salesforce',
        practice: [
          'Salesforce'
        ]
      },
      {
        capability: 'Technology Enablement',
        practice: [
          'Client / Practice Leadership',
          'Cloud Enablement',
          'Experience Design',
          'Software Engineering',
          'Technology Enablement',
          'Technology Strategy'
        ]
      },
      {
        capability: 'Versa',
        practice: [
          'Associates'
        ]
      }
    ]
  };
}