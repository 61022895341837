import type {FC} from 'react';
import React from 'react';
import { useFieldArray,useFormContext } from 'react-hook-form';
import { InputAdornment,styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';

import type { RatesRolesFormProps } from './RatesRolesForm.interface';
import { useStyles } from './RatesRolesForm.styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  borderBottom: 'none',
  [`&.${tableCellClasses.head}`]: {
    fontSize: 13,
    paddingTop: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const StyledTextField = styled(TextField)(({ theme, disabled }) => ({
  '& .MuiInput-root': {
    width: 55,
    marginTop: 0,
  },
  '& .MuiInput-input': {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    marginLeft: 0,
  },
  '& .MuiInputAdornment-root': {
    marginRight: 0,
    marginLeft: 10,
    color: disabled ? theme.palette.text.secondary : theme.palette.text.disabled
  }
}));

export const RatesRolesForm: FC<RatesRolesFormProps> = ({disabled})  => {
  const { classes } = useStyles();
  const { control, register, getValues } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: 'roleInfo',
  });

  return (
    <TableContainer data-testid="RatesRolesForm" className={classes.tableContainer}>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" colSpan={2}><Typography variant='h6'>Roles</Typography></StyledTableCell>
            <StyledTableCell align="left" colSpan={5}><Typography variant='h6'>Market Rates</Typography></StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell className={classes.subHeader} colSpan={2}></StyledTableCell>
            <StyledTableCell align="center" className={classes.subHeader}>Foundational</StyledTableCell>
            <StyledTableCell align="center" className={classes.subHeader}>Proficient</StyledTableCell>
            <StyledTableCell align="center" className={classes.subHeader}>Advanced</StyledTableCell>
            <StyledTableCell align="center" className={classes.subHeader}>Expert</StyledTableCell>
            <StyledTableCell align="center" className={classes.subHeader}>Last updated</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((field, index) =>  {
            return (
              <TableRow key={field.id}>
                <StyledTableCell component="th" scope="row" colSpan={2}>
                  <Typography variant='body1'>{getValues(`roleInfo[${index}].role`)}</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <StyledTextField
                    {...register(`roleInfo.${index}.foundationalRate`)}
                    InputProps={{
                      disabled: disabled,
                      startAdornment: <InputAdornment disableTypography={disabled} position="start">$</InputAdornment>,
                    }}/>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <StyledTextField
                    {...register(`roleInfo.${index}.proficientRate`)}
                    InputProps={{
                      disabled: disabled,
                      startAdornment: <InputAdornment disableTypography={disabled} position="start">$</InputAdornment>,
                    }}/>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <StyledTextField
                    {...register(`roleInfo.${index}.advancedRate`)}
                    InputProps={{
                      disabled: disabled,
                      startAdornment: <InputAdornment disableTypography={disabled} position="start">$</InputAdornment>,
                    }}/>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <StyledTextField
                    {...register(`roleInfo.${index}.expertRate`)}
                    InputProps={{
                      disabled: disabled,
                      startAdornment: <InputAdornment disableTypography={disabled} position="start">$</InputAdornment>,
                    }}/>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant='body1'>
                    {format(getValues(`roleInfo[${index}].lastUpdated`), 'M/dd/yyyy')}
                  </Typography>
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
