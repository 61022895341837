import type { FC} from 'react';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Stack, styled, Table, TableBody,TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { format } from 'date-fns';

import salesforceLogo from '@/assets/Salesforce-Logo.png';
import teamsLogo from '@/assets/Teams-Logo.png';
import KabobMenu from '@/components/KabobMenu';
import DeleteModal from '@/containers/DeleteModal';
import { paths } from '@/services/Router/paths';
import { useEstimateContext } from '@/store/estimates';

import type { EstimatesSummaryTableProps,EstimateSummaryInfo } from './EstimatesSummaryTable.interface';
import { useStyles } from './EstimatesSummaryTable.styles';

const ASCENDING = 'asc';
const DESCENDING = 'desc';
const CLIENT_NAME = 'clientName';
const PROJECT_NAME = 'projectName';
const LINKS = 'links';
const LASTMODIFIED = 'lastModified';
const MODIFIEDBY = 'modifiedBy';
const MARKET = 'market';
let lastModifiedIsTrueByDefault = true; //by default, Last Modified column should be active and sorted in descending order

export const EstimatesSummaryTable: FC<EstimatesSummaryTableProps> = ({estimatesInfo: estimatesInfo}) => {

  const navigation = useNavigate();

  const { estimateService } = useEstimateContext();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [focusedEstimateId, setFocusedEstimateId] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string | null>(LASTMODIFIED);
  const [order, setOrder] = useState<string>(DESCENDING); // Initial order is descending

  const handleEdit = (id: string) => {
    navigation(`${paths.estimation}/${id}`);
  };

  const handleDuplicate = async (estimate: EstimateSummaryInfo) => {
    estimateService?.copyEstimate(estimate.id);
  };

  const handleModalClose = () => {
    setDeleteModalOpen(false);
    setFocusedEstimateId('');
  };

  const handleModalDelete = () => {
    estimateService?.deleteEstimate(focusedEstimateId);
    setFocusedEstimateId('');
    setDeleteModalOpen(false);
  };

  const { classes } = useStyles();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      fontSize: 14,
      fontWeight: 600,
      height: 46,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
    },
  }));

  const handleRequestSort = (event: React.MouseEvent<HTMLElement>, property: string) => {
    lastModifiedIsTrueByDefault = false;
    const updateOrder = (newOrder: string) => {
      setOrder(newOrder);
      setOrderBy(property);

      switch (property) {
      case CLIENT_NAME:
        sortEstimatesByClientName(estimatesInfo, newOrder);
        break;
      case PROJECT_NAME:
        sortEstimatesByProjectName(estimatesInfo, newOrder);
        break;
      case LINKS:
        sortEstimatesByLinks(estimatesInfo, newOrder);
        break;
      case LASTMODIFIED:
        sortEstimatesByLastModified(estimatesInfo, newOrder);
        break;
      case MODIFIEDBY:
        sortEstimatesByModifiedBy(estimatesInfo, newOrder);
        break;
      case MARKET:
        sortEstimatesByMarket(estimatesInfo, newOrder);
        break;
      }
    };

    const nextOrder = order === ASCENDING ? DESCENDING : ASCENDING;
    updateOrder(nextOrder);
  };

  const getSortDirection = () => {
    if (lastModifiedIsTrueByDefault) {
      return DESCENDING;
    }
    return order == ASCENDING ? ASCENDING : DESCENDING;
  };

  return (
    <TableContainer data-testid="EstimatesSummaryTable" >
      <Table className={classes.table} aria-label="customized table" stickyHeader={true}>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" width="20%" className={classes.moveRight}>
              Client
              <TableSortLabel
                active={orderBy=== CLIENT_NAME}
                direction={getSortDirection()}
                onClick={(event) => handleRequestSort(event, CLIENT_NAME)}
                sx={{
                  '& .MuiTableSortLabel-icon': {
                    color: '#565656 !important'
                  },
                }}
              >
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell align="left" width="25%">
              Project Name
              <TableSortLabel
                active={orderBy=== PROJECT_NAME}
                direction={getSortDirection()}
                onClick={(event) => handleRequestSort(event, PROJECT_NAME)}
                sx={{
                  '& .MuiTableSortLabel-icon': {
                    color: '#565656 !important'
                  },
                }}
              >
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell align="left" width="10%">
              Links
              <TableSortLabel
                active={orderBy=== LINKS}
                direction={getSortDirection()}
                onClick={(event) => handleRequestSort(event, LINKS)}
                sx={{
                  '& .MuiTableSortLabel-icon': {
                    color: '#565656 !important'
                  },
                }}
              >
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell align="left" width="15%">
              Last Modified
              <TableSortLabel
                active={orderBy=== LASTMODIFIED}
                direction={getSortDirection()}
                onClick={(event) => handleRequestSort(event, LASTMODIFIED)}
                sx={{
                  '& .MuiTableSortLabel-icon': {
                    color: '#565656 !important'
                  },
                }}
              >
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell align="left" width="20%">
              Last Modified by
              <TableSortLabel
                active={orderBy=== MODIFIEDBY}
                direction={getSortDirection()}
                onClick={(event) => handleRequestSort(event, MODIFIEDBY)}
                sx={{
                  '& .MuiTableSortLabel-icon': {
                    color: '#565656 !important'
                  },
                }}
              >
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell align="left" width="7%" >
              Market
              <TableSortLabel
                active={orderBy=== MARKET}
                direction={getSortDirection()}
                onClick={(event) => handleRequestSort(event, MARKET)}
                sx={{
                  '& .MuiTableSortLabel-icon': {
                    color: '#565656 !important'
                  },
                }}
              >
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell align="left" width="3%" ></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {estimatesInfo.map((estimate) => (
            <TableRow key={estimate.id} className={classes.noBottomBorder} >
              <StyledTableCell align="left" className={classes.moveRight} >{estimate.clientName}</StyledTableCell>
              <StyledTableCell align="left">
                <Link data-testid={`project-link-${estimate.id}`} to={`${paths.estimation}/${estimate.id}`} className={classes.projectLink} >{estimate.projectName}</Link>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Link to={estimate.salesforceLink} target="_blank" data-testid={`salesforce-link-${estimate.id}`}>
                  <img src={salesforceLogo} alt="salesforce logo" className={classes.salesforceStyle} ></img>
                </Link>
                {
                  estimate.teamsLink && <Link to={estimate.teamsLink} target="_blank" data-testid={`teams-link-${estimate.id}`}>
                    <img src={teamsLogo} alt="teamsLogo logo" className={classes.teamsStyle} ></img>
                  </Link>
                }
              </StyledTableCell>
              <StyledTableCell align="left">
                <Stack direction="column">
                  <div>{getDayAndTime(estimate.modifiedBy.date)}</div>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Stack direction="column">
                  <div>{estimate.modifiedBy.name}</div>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="left">{estimate.market}</StyledTableCell>
              <StyledTableCell align="left"><KabobMenu items={[
                {
                  option: 'Edit',
                  value: 'Edit',
                  onClick: () => {
                    handleEdit(estimate.id);
                  }
                },
                {
                  option: 'Duplicate',
                  value: 'Duplicate',
                  onClick: () => {

                    handleDuplicate(estimate);
                  }
                },
              ]}/></StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DeleteModal open={deleteModalOpen} onClose={handleModalClose} onDelete={handleModalDelete}/>
    </TableContainer>
  );
};

function getDayAndTime(date: Date) {
  return !isNaN(date.getTime()) ? format(date, 'MM.dd.yyyy, hh:mm aaa') : '';//American format
}

function sortEstimatesByClientName(estimates: EstimateSummaryInfo[], order: string): void {
  estimates.sort((a, b) => {
    // Handle potentially undefined values
    const aClientName = a.clientName ?? '';
    const bClientName = b.clientName ?? '';
    const comparisonResult = aClientName.localeCompare(bClientName);
    // Consistent sorting direction logic
    return order === ASCENDING
      ? comparisonResult
      : -comparisonResult;
  });
}

function sortEstimatesByProjectName (estimatesInfo: EstimateSummaryInfo[], order: string): void {
  estimatesInfo.sort((a, b) => {
    const aProjectName = a.projectName ?? ''; // Handle potentially undefined values
    const bProjectName = b.projectName ?? '';
    const comparisonResult = aProjectName.localeCompare(bProjectName);
    return order === ASCENDING
      ? comparisonResult
      : -comparisonResult;
  });
}

function sortEstimatesByLinks (estimatesInfo: EstimateSummaryInfo[], order: string): void {
  estimatesInfo.sort((a, b) => {
    const aSalesforceLink = a.salesforceLink ?? ''; // If a.teamsLink is undefined, use an empty string
    const bSalesforceLink = b.salesforceLink ?? ''; // If b.teamsLink is undefined, use an empty string
    return order === ASCENDING
      ? aSalesforceLink.localeCompare(bSalesforceLink)
      : -aSalesforceLink.localeCompare(bSalesforceLink);
  });
}

function sortEstimatesByLastModified (estimatesInfo: EstimateSummaryInfo[], order: string): void {
  estimatesInfo.sort((a, b) => {
    const aDate = a.modifiedBy.date ?? new Date(-Infinity); // Use an ancient date for undefined
    const bDate = b.modifiedBy.date ?? new Date(-Infinity); // Use an ancient date for undefined
    return order === ASCENDING
      ? aDate.getTime() - bDate.getTime()
      : -(aDate.getTime() - bDate.getTime());
  });
}

function sortEstimatesByModifiedBy (estimatesInfo: EstimateSummaryInfo[], order: string): void {
  estimatesInfo.sort((a, b) => {
    const aModifiedBy = a.modifiedBy.name ?? ''; // Handle potentially undefined values
    const bModifiedBy = b.modifiedBy.name ?? '';
    const comparisonResult = aModifiedBy.localeCompare(bModifiedBy);
    return order === ASCENDING
      ? comparisonResult
      : -comparisonResult;
  });
}

function sortEstimatesByMarket (estimatesInfo: EstimateSummaryInfo[], order: string): void{
  estimatesInfo.sort((a, b) => {
    const aMarket = a.market ?? ''; // Handle potentially undefined values
    const bMarket = b.market ?? '';
    const comparisonResult = aMarket.localeCompare(bMarket);
    return order === ASCENDING
      ? comparisonResult
      : -comparisonResult;
  });
}

export {
  getDayAndTime,
  sortEstimatesByClientName,
  sortEstimatesByLastModified,
  sortEstimatesByLinks,
  sortEstimatesByMarket,
  sortEstimatesByModifiedBy,
  sortEstimatesByProjectName
};
