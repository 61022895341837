import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  title: {
    paddingTop: 34,
    marginLeft: 30,
    paddingBottom: 12,
    whiteSpace: 'pre-wrap',
  },
  content: {
    marginLeft: 30,
    marginRight: 30,
  },
  page: {
    paddingBottom: 20,
  }
});