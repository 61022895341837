import { API } from 'aws-amplify';

import type { MarginDataItemRequest, MarginDataItemResponse, RateAPIType, RatesRolesAPIType, RatesRolesType, RateType, UpdateRateRequestType } from '@/api/RatesService/rates.interface';
import { mapFormToRequest,mapToRatesRolesType, mapToRateType } from '@/api/RatesService/rates.mapper';

const RATE_SERVICE_URL = process.env.REACT_APP_RATE_SERVICE_URL;
const RATES_PATH = '/rates';

class RateService {
  public apiServiceName = 'RateService';
  public endpointUrl: string = RATE_SERVICE_URL || '';

  async getRates(): Promise<Array<RateType>> {
    const res = await API.get(this.apiServiceName, RATES_PATH, {});
    const retValue: Array<RateType> = [];
    if (res.result) {
      res.result.forEach((rate: RateAPIType) => {
        retValue.push(mapToRateType(rate));
      });
    }
    return retValue;
  }

  async getMarketRateData(market: string, revisedDate?: string): Promise<RateType> {
    const url = revisedDate ? `${RATES_PATH}/${market}?revised_date=${revisedDate}` :
      `${RATES_PATH}/${market}`;
    const res = await API.get(this.apiServiceName, url, {});
    return mapToRateType(res.result);
  }

  async getMarketRoles(market: string, revisedDate?: string): Promise<Array<RatesRolesType>> {
    const url = revisedDate ? `${RATES_PATH}/roles/${market}?revised_date=${revisedDate}` :
      `${RATES_PATH}/roles/${market}`;
    const res = await API.get(this.apiServiceName, url, {});
    const retValue: Array<RatesRolesType> = [];
    res.result.rates.forEach((item: RatesRolesAPIType) => {
      retValue.push(mapToRatesRolesType(item));
    });
    return retValue;
  }

  async saveMarketRates(formData: UpdateRateRequestType): Promise<RateType> {
    const url = `${RATES_PATH}`;
    const reqBody = mapFormToRequest(formData);
    const res = await API.patch(this.apiServiceName, url, {body: reqBody});
    return res.result;
  }

  async getMargin(formData: MarginDataItemRequest): Promise<MarginDataItemResponse> {
    const url = `${RATES_PATH}/margin`;
    const res = await API.post(this.apiServiceName, url, {body: formData});
    return res.result;
  }

}

export const rateService = new RateService();