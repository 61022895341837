import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  leftTable: {
    maxWidth: 500,
    minWidth: 500,
  },
  middleTable: {
    maxWidth: 1000,
    minWidth: 300,
    overflowX: 'scroll',
    marginBottom: '32px'
  },
  rightTable: {
    maxWidth: 300,
    minWidth: 300,
  },
  border: {
    border: '2px solid',
    borderColor: theme.palette.divider,
  },
  moveRight: {
    paddingLeft: 10,
  },
  noBottomBorder: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  weekColumn: {
    Width: '80px',
    overflow: 'hidden',
    '& .MuiInput-root': {
      marginTop: 0,
      width: '70%',
      marginLeft: 8,
      fontSize: 6,
    },
    '& .MuiInput-input': {
      fontSize: 14,
    },
  },
  totals: {
    fontSize: 24,
    fontWeight: 700,
    color: 'blue',
  },
  totalsLabel: {
    marginLeft: '-105px',
    marginTop: '-25px',
    fontSize: 16,
    fontWeight: 500,
  },
  dateLinks: {
    fontSize: 12,
    whiteSpace: 'pre-wrap',
  },
  dropDown: {
    paddingTop: 4,
    width: 130,
    '& .css-64oheu-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select':{
      color:'#737373'
    }
  },
}));
