
import type { FC} from 'react';
import React, { useEffect, useState } from 'react';
import { FormProvider,useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import type { SelectChangeEvent} from '@mui/material';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select,Stack, TextField, Typography} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import type { ClientProfileAPIType, ClientProfileRoleType, ClientProfileType } from '@/api/ClientService/client.interface';
import { clientService } from '@/api/ClientService/client.service';
import CancelModal from '@/components/CancelModal';
import { PageLayout } from '@/components/PageLayout';
import type { ClientProfileFormType, ClientRolesType } from '@/containers/ClientProfileForm';
import { ClientProfileForm } from '@/containers/ClientProfileForm';
import { useAuthContext } from '@/store/auth';
import { useClientContext } from '@/store/client';
import { useLoadingContext } from '@/store/loading';
import { useNotificationContext } from '@/store/notification';
import { useRateContext } from '@/store/rate';

import { mapToClientRolesType } from './ClientProfile.func';
import { useStyles } from './ClientProfile.styles';
import { schema } from './clientProfile.validation';

export const ClientProfile: FC = () => {
  const { dispatch: loader } = useLoadingContext();
  const { classes } = useStyles();
  const { state: clients, actions: operation } = useClientContext();
  const { state: rates } = useRateContext();
  const { state: auth } = useAuthContext();
  const [market] = React.useState(auth.user?.market || 'Atlanta');
  const [showForm, setShowForm] = React.useState(false);
  const [marketClientNames, setMarketClientNames] = React.useState<{market: string; clientNames: string[]}[]>([]);
  const [clientNames, setClientNames] =  React.useState<string[]>([]);
  const [markets, setMarkets] = React.useState<string[] | null>(null);
  const initialRole: ClientProfileRoleType = {
    clientRole: '',
    slalomRole: '',
    slalomProficiency: '',
    clientRate: 0,
    fixedRate: false
  };

  const inputValue: ClientProfileType = {
    clientId: '',
    market: '',
    clientName: '',
    roles: [initialRole]
  };


  const { dispatch: notification } = useNotificationContext();
  const methods = useForm<ClientProfileFormType>({
    defaultValues: {
      clientId: '',
      market: '',
      clientName: '',
      roles: [initialRole],
    },
    resolver: yupResolver(schema),
    mode: 'onChange'
  });
  const onError = (errors: unknown) => {
    console.error(errors);
    if(errors){
      notification?.addNotification({
        id:'',
        severity: 'error',
        message: 'Changes have not been saved. Please review required fields and/or duplicate client roles.',
      });

    }

  };

  useEffect(() => {
    setDefaultMarketsClientNamesDropdowns();
  }, [clients.clientProfiles, markets, clientNames]);

  const setDefaultMarketsClientNamesDropdowns = () => {
    if (clients.clientProfiles != undefined && (markets == undefined || markets == null)) {
      const marketClientNamesCombo = createMarketClientNameCombo(clients.clientProfiles);
      const clientList = createClientNameList(marketClientNamesCombo);
      const marketList = createMarketList(marketClientNamesCombo);
      setMarkets(marketList);
      setClientNames(clientList);
      setMarketClientNames(marketClientNamesCombo);
    }
  };

  const addClientProfile = async(data: ClientProfileAPIType) => {
    const res = await clientService.createClientProfile(data);
    return res;
  };
  const updateClientProfile = async(data: ClientProfileAPIType) => {
    const res = await clientService.updateClientProfile(data);
    return res;
  };
  const clientProfileDataMapper = (data: ClientProfileFormType, clientId: string, market: string) =>{
    return {
      client_id: clientId ? clientId : '',
      market: market ? market : data.market,
      client_name: data.clientName,
      roles: data.roles.map(role => {
        return{
          client_rate: role.clientRate,
          client_role: role.clientRole,
          fixed_rate: role.isFixedRate,
          slalom_proficiency: role.slalomProficiency,
          slalom_role: role.slalomRole
        };
      })
    };
  };
  const onSubmit = (data: ClientProfileFormType) => {
    const clientProfile = clients.clientProfiles?.find((profile)=> profile.clientName == data.clientName);
    const map = new Map<string,object>();
    let isDuplicate = false;
    data.roles.map(element => {
      if(!map.has(element.clientRole)){
        map.set(element.clientRole,element);
      }else{
        isDuplicate = true;
      }
    });

    if (isDuplicate) {
      notification?.addNotification({
        id:'',
        severity: 'error',
        message: 'Changes have not been saved. Please review required fields and/or duplicate client roles.',
      });
    }else{
      loader?.setLoading({enable: true});
      if (clientProfile) {
        updateClientProfile(clientProfileDataMapper(data,clientProfile.clientId,clientProfile.market)).then(client =>{
          loader?.setLoading({enable: false});
          notification?.addNotification({
            id:client.clientId,
            severity: 'success',
            message: 'Changes have been successfully saved.',
          });
          operation?.refresh();
          methods.reset(data); // clears isDirty
        }).catch(e =>{
          loader?.setLoading({enable: false});
          notification?.addNotification({
            id: data.clientId || 'Client ID not found',
            severity: 'error',
            message: 'Error occurred updating client: ' + e.message,
          });
        });
      } else {
        addClientProfile(clientProfileDataMapper(data,'','')).then(client =>{
          loader?.setLoading({enable: false});
          notification?.addNotification({
            id:client.clientId,
            severity: 'success',
            message: 'Changes have been successfully saved.',
          });
          operation?.refresh();
        }).catch(e =>{
          loader?.setLoading({enable: false});
          notification?.addNotification({
            id: data.clientId || 'Client ID not found',
            severity: 'error',
            message: 'Error occurred adding client: ' + e.message,
          });
        });
      }
    }
  };
  const [addingNewProfile, setAddingNewProfile] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [tempProfiles, setTempProfiles] = useState<ClientProfileType[]>([]);
  const [selectedClient, setSelectedClient] = React.useState('');
  const [selectedMarket, setSelectedMarket] = React.useState('');

  React.useEffect(() => {
    getClientProfile(selectedClient);
  }, []);

  const getClientProfile = (clientName: string) => {
    const clientProfile = clients.clientProfiles?.find((profile)=> profile.clientName == clientName);
    const tempProfile = tempProfiles?.find((profile)=> profile.clientName == clientName);
    if(clientName !== ''){
      setShowForm(true);
    }
    if (clientProfile) {
      const rolesArr: ClientRolesType[] = [];
      clientProfile.roles.map((item) => {
        rolesArr.push(mapToClientRolesType(item, rates.marketRate?.rates || []));
      });
      methods.setValue('roles', rolesArr);
      methods.setValue('market', clientProfile.market);
    } else if (tempProfile) {
      const rolesArr: ClientRolesType[] = [];
      tempProfile.roles.map((item) => {
        rolesArr.push(mapToClientRolesType(item, rates.marketRate?.rates || []));
      });
      methods.setValue('roles', rolesArr);
      methods.setValue('market', market);
    }
  };

  const handleAddNewProfile = () => {
    const inputValueToLowerCase = inputValue.clientName.toLowerCase();
    if (!tempProfiles.some((profile)=>profile.clientName.toLowerCase() == inputValueToLowerCase ) && !clients.clientProfiles?.some((profile)=>profile.clientName.toLowerCase() == inputValueToLowerCase)) {
      setTempProfiles(tempProfiles.concat(inputValue));
      const updatedClientNames = [...clientNames];
      const index = clientNames?.findIndex((clientName) => clientName > inputValue.clientName);
      if (index!== undefined && index > -1) {
        updatedClientNames?.splice(index, 0, inputValue.clientName);
      } else {
        updatedClientNames.push(inputValue.clientName);
      }
      setClientNames(updatedClientNames);
    }else {
      const id = uuidv4();
      notification?.addNotification({
        id: id,
        severity: 'error',
        message: 'Error occurred adding client: client name already exists.',
      });
    }
    setAddingNewProfile(false);
  };

  const handleMarketChage = (event: SelectChangeEvent) => {
    if (event.target.value != '' && event.target.value != undefined) {
      methods.setValue('market', event.target.value as string);
      setSelectedMarket(event.target.value as string);
      const filteredClients = marketClientNames.filter((market) => {
        return market.market == event.target.value;
      });
      const clientNames = createClientNameList(filteredClients);
      setClientNames(clientNames);
    } else {
      resetDropDowns();
    }
    methods.reset(methods.getValues()); //This makes sure the isDirty flag gets cleared from any previous inputs
  };

  const handleClientChange = (event: SelectChangeEvent) => {
    if (event.target.value != '' && event.target.value != undefined) {
      methods.setValue('clientName', event.target.value as string);
      setSelectedClient(event.target.value as string);
      getClientProfile(event.target.value);
      const filteredMarkets = marketClientNames.filter((item) => {
        return item.clientNames.includes(event.target.value);
      });
      const marketNames = createMarketList(filteredMarkets);
      setMarkets(marketNames);
    } else {
      resetDropDowns();
    }
    methods.reset(methods.getValues()); //This makes sure the isDirty flag gets cleared from any previous inputs
  };

  const handleDiscard = () => {
    getClientProfile(selectedClient);
    if (tempProfiles?.find((profile)=> profile.clientName == selectedClient)) {
      const index = clientNames.indexOf(selectedClient);
      const updatedClientNames = [...clientNames];
      updatedClientNames.splice(index, 1);
      setClientNames(updatedClientNames);

      setTempProfiles(tempProfiles.filter(( obj ) => {
        return obj.clientName !== selectedClient;
      }));
      setSelectedClient('');
      setShowForm(false);
    }
    setShowModal(false);
    methods.reset(methods.getValues()); //This makes sure the isDirty flag gets cleared from any previous inputs
  };

  const resetDropDowns = () => {
    setSelectedClient('');
    setSelectedMarket('');
    setMarkets(null);
    setClientNames([]);
    setShowForm(false);
  };

  return (
    <PageLayout title='Your Client Profile' data-testid='ClientProfile'>
      <FormProvider {...methods} >
        <form onSubmit={methods.handleSubmit(onSubmit, onError)} data-testid='ClientForm'>
          <Grid container spacing={4}>
            <Grid item xs={2}>
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel variant="standard" shrink={true}>
                  <Typography variant="h5">Select markets</Typography>
                </InputLabel>
                <Select
                  displayEmpty
                  onChange={handleMarketChage}
                  value={selectedMarket}
                  renderValue={selectedMarket !== '' ? undefined : () => 'Select option'}
                >
                  <MenuItem value=''>Select option</MenuItem>
                  {markets?.map((item, i) => {return <MenuItem key={i} value={item}>{item}</MenuItem>;})}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={10}>
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel variant="standard" shrink={true}>
                  <Typography variant="h5">Select client profile</Typography>
                </InputLabel>
                {/* // TODO:fix select */}
                <Select
                  displayEmpty
                  onChange={handleClientChange}
                  value={selectedClient}
                  renderValue={selectedClient !== '' ? undefined : () => 'Select option'}>
                  {!addingNewProfile && <MenuItem className={classes.addNewLabel} onClick={() => setAddingNewProfile(true)}>+ Add New</MenuItem>}
                  {addingNewProfile &&
                    <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                      <TextField
                        autoFocus
                        fullWidth
                        onChange={(e) => inputValue.clientName = e.target.value }
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleAddNewProfile();
                          }
                        }}
                      />
                    </MenuItem>
                  }
                  <MenuItem value=''>Select option</MenuItem>
                  {clientNames?.map((item, i) => {return <MenuItem key={i} value={item}>{item}</MenuItem>;})}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} >
              {/* // TODO: fix naming */}
              <Typography variant='h3' color='text.secondary'>{'Client'} Profile</Typography>
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <Typography variant='body1'>
                Roles added to this card will appear in the drop down in the role selection for the delivery team when creating an estimate.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {showForm && <ClientProfileForm marketRates={rates.marketRate?.rates || []}/>}
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
              >
                <Button
                  type='submit'
                  size='form'
                  disabled={!selectedClient || !methods.formState.isDirty || !methods.formState.isValid}>
                  SAVE
                </Button>
                <Button
                  variant='outlined'
                  size='formExtend'
                  disabled={!selectedClient || !methods.formState.isDirty || !methods.formState.isValid}
                  onClick={() => setShowModal(true)}>
                  CANCEL
                </Button>
                <CancelModal
                  shouldNavBlock={methods.formState.isDirty}
                  manual={{
                    open: showModal,
                    reset: () => setShowModal(false),
                    proceed: handleDiscard,
                  }}
                  titleText=""
                  content=""
                />
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </PageLayout>
  );
};
function createMarketClientNameCombo(clientProfiles: ClientProfileType[]): { market: string; clientNames: string[] }[] {
  // Initialize an empty array to store the results
  const marketClientNameCombos: { market: string; clientNames: string[] }[] = [];

  // Group client profiles by market
  const clientProfilesByMarket = clientProfiles.reduce((acc, clientProfile) => {
    const { market, clientName } = clientProfile;
    acc[market] = acc[market] || [];
    acc[market].push(clientName);
    return acc;
  }, {} as Record<string, string[]>);

  // Sort markets alphabetically
  const sortedMarkets = Object.keys(clientProfilesByMarket).sort();

  // For each market, sort client names alphabetically and create the result object
  sortedMarkets.forEach((market) => {
    const clientNames = clientProfilesByMarket[market].sort();
    marketClientNameCombos.push({ market, clientNames });
  });

  return marketClientNameCombos;
}
//market dropdown
function createMarketList(marketClientNameCombos: {market: string; clientNames: string[] }[]): string[] {
  const uniqueMarket = new Set<string>();
  marketClientNameCombos.forEach((marketCombo) => {
    uniqueMarket.add(marketCombo.market);
  });

  return Array.from(uniqueMarket).sort();
}
//clientname dropdown
function createClientNameList(marketClientNamesCombos: { market: string; clientNames: string[] }[]): string[] {
  const uniqueClientNames = new Set<string>();
  marketClientNamesCombos.forEach((marketCombo) => {
    marketCombo.clientNames.forEach((clientName) => {
      uniqueClientNames.add(clientName);
    });
  });

  return Array.from(uniqueClientNames).sort();
}

export { createClientNameList,createMarketClientNameCombo , createMarketList };