import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

import type { EstimationFormFieldsType } from '@/pages/Estimation/Estimation.interface';

const columns = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','AA','AB','AC','AD','AE','AF','AG','AH','AI','AJ','AK','AL','AM','AN','AO','AP','AQ','AR','AS','AT','AU','AV','AW','AX','AY','AZ','BA','BB','BC','BD','BE','BF','BG','BH','BI','BJ','BK','BL','BM','BN','BO','BP','BQ','BR','BS','BT','BU','BV','BW','BX','BY','BZ'];

const dollarFormat = '$ #,##0.00';
const percentFormat = '0%';
const commaSeperator = '#,##0';
const percentMarginFormat = '##.0%';

const weekDisplayRow = 14;
const teamMembersStarRow = 18;
const extraRowsForSum = 5;

export const exportToExcel = async (estimate: EstimationFormFieldsType) => {
  const filename = `${estimate.client}_${estimate.project}_${estimate.startDate.toISOString().split('T')[0]}`;
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF8';
  const fileExtension = '.xlsx';
  const teamMembers = estimate.deliveryTeamMembers.filter(member => member.isValid);

  const weekFunc = Array.from({length: estimate.duration - 1}).map((_ignore, index) => {
    return { t: 'd', f: `${columns[index+9]}${weekDisplayRow} + 7` };
  });

  const weekDisplay = Array.from({length: estimate.duration}).map((_ignore, index) => {
    return { v: `WK${index+1}` };
  });

  const slalomInvestment = {
    t:'n',
    v: estimate.slalomInvestmentType === 'currency' ? estimate.slalomInvestment : estimate.slalomInvestment / 100,
    z: estimate.slalomInvestmentType === 'currency' ? dollarFormat : percentFormat
  };

  const otherInvestment = {
    t:'n',
    v: estimate.otherInvestmentType === 'currency' ? estimate.otherInvestment : estimate.otherInvestment / 100,
    z: estimate.otherInvestmentType === 'currency' ? dollarFormat : percentFormat
  };

  let clientResponsibilityFunc = 'SUM(B10,';

  if(estimate.slalomInvestmentType === 'currency') {
    clientResponsibilityFunc += '-B11,';
  } else {
    clientResponsibilityFunc += '-(B11*B10),';
  }

  if(estimate.otherInvestmentType === 'currency') {
    clientResponsibilityFunc += '-B12)';
  } else {
    clientResponsibilityFunc += '-(B12*B10))';
  }

  const clientResponsibility = { t: 'n', z: dollarFormat, f: clientResponsibilityFunc};

  const getPreviousMonday = (date: Date) => {
    date.setDate(date.getDate() - (date.getDate() + 6) % 7);
    return date;
  };

  const excelData: unknown[][] = [
    [],
    ['Forecasted Start Date:', {t: 'd', v: getPreviousMonday(estimate.startDate)}],
    ['Teams Folder:', estimate.teamsLink],
    ['Salesforce Link:', estimate.salesforceLink],
    ['SOW Type:', estimate.sow],
    ['Duration:', `${estimate.duration} week(s)`],
    ['Total Hours:', { f: `SUM(H${teamMembersStarRow}:H${teamMembersStarRow + teamMembers.length + extraRowsForSum})`, z: commaSeperator }],
    ['Blended Rate;', { t:'n', f: 'B10/B7', z: dollarFormat}],
    ['Contingency:', { t:'n', v: estimate.contingency / 100, z: percentFormat }],
    ['Total Fees:', { t:'n', f: `SUM(I${teamMembersStarRow}:I${teamMembersStarRow + teamMembers.length + extraRowsForSum})`, z: dollarFormat}],
    ['Slalom Investment:', slalomInvestment],
    ['Other Investment:', otherInvestment],
    ['Client Responsibilty: ', clientResponsibility ],
    ['Market Rate Margin:', { t:'n', v: estimate.marketRateMargin / 100 , z: percentMarginFormat}],
    ['Estimated Project Margin:', { t:'n', v: estimate.estimatedProjectMargin / 100, z: percentMarginFormat }],
    ['','','','','','','','','Week Starting >', { t: 'd', f: 'B2' }, ...weekFunc],
    ['Client Role', 'Slalom Role',  'Proficiency', 'Salesforce Label', 'Capability', 'Market Rate', 'Rate',  'Total Project Hours',  'Total Project Cost', ...weekDisplay],
    ...teamMembers.map((member, index) => {
      const row = teamMembersStarRow + index;
      return [ member.clientRole, member.slalomRole, member.proficiency, member.salesforceLabel, member.practice, { t: 'n', v: member.slalomRate, z: dollarFormat }, { t: 'n', v: member.clientRate, z: dollarFormat}, { t: 'n', f: `SUM(J${row}:BZ${row})`, z: commaSeperator }, { t: 'n', f: `G${row}*H${row}`, z: dollarFormat },
        ...member.weeklyBreakdown.map((week) => week.hours)
      ];
    }),
  ];

  const ws = XLSX.utils.aoa_to_sheet(excelData);
  if(estimate.teamsLink) {
    ws['B3'].l = { Target: estimate.teamsLink, Tooltip: estimate.teamsLink };
  }

  if(estimate.salesforceLink) {
    ws['B4'].l = { Target: estimate.salesforceLink, Tooltip: estimate.salesforceLink };
  }

  const wb = { Sheets: {'Staffing Estimate': ws }, SheetNames: ['Staffing Estimate'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, filename + fileExtension);
};