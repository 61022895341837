import React from 'react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import type { ThemeOptions, Transitions } from '@mui/material';
import { alpha } from '@mui/material';

import { SlalomCalendarArrowLeftIcon } from '@/icons/SlalomCalendarArrowLeftIcon';
import { SlalomCalendarArrowRightIcon } from '@/icons/SlalomCalendarArrowRightIcon';
import { SlalomCalendarIcon } from '@/icons/SlalomCalendarIcon';
import { SlalomCalendarSwitchViewIcon } from '@/icons/SlalomCalendarSwitchViewIcon';

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    form: true;
    formExtend: true;
    formNarrow: true;
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsSizeOverrides {
    label: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h7: true;
    body3: true;
  }
}

export const components: ThemeOptions['components'] = {
  MuiAlert: {
    styleOverrides: {
      root: ({theme}) => ({
        border: '1px solid',
        borderRadius: 3,
        '& .MuiButtonBase-root': {
          '& .MuiSvgIcon-root': {
            color: theme.palette.grey[600],
          }
        }
      }),
      message: ({theme}) => ({
        color: theme.palette.text.primary,
      }),
      standardError: ({theme}) => ({
        borderColor: alpha(theme.palette.error.main, 0.4),
      }),
      standardSuccess: ({theme}) => ({
        borderColor: alpha(theme.palette.success.main, 0.4),
      }),
      standardInfo: ({theme}) => ({
        borderColor: alpha(theme.palette.info.main, 0.4),
      }),
      standardWarning: ({theme}) => ({
        borderColor: alpha(theme.palette.warning.main, 0.4),
      }),
    }
  },
  MuiButton: {
    styleOverrides: {
      root: ({theme}) => ({
        borderRadius: 3,
        '&.Mui-disabled': {
          background: theme.palette.text.disabled,
          color: theme.palette.text.primary,
        }
      }),
      sizeSmall: {
        padding: '4px 10px',
        fontSize: 12,
      },
      sizeMedium: {
        padding: '12px 10px',
      },
      startIcon: {
        '& > *:first-of-type': {
          fontSize: 14,
        },
      },
    },
    defaultProps: {
      variant: 'contained'
    },
    variants: [
      {
        props: { size: 'form' },
        style: { padding: '12px 32px' }
      },
      {
        props: { size: 'formExtend' },
        style: { padding: '12px 40px' }
      },
      {
        props: { size: 'formNarrow' },
        style: { padding: '12px' }
      }
    ]
  },
  MuiChip: {
    styleOverrides: {
      root: ({theme}) => ({
        borderRadius: 22,
        borderWidth: 1,
        borderColor: theme.palette.primary.main,
      }),
      label: ({theme}) => ({
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 3,
        color: theme.palette.text.primary
      })
    },
    defaultProps: {
      variant: 'outlined',
      color: 'primary',
    }
  },
  MuiDatePicker: {
    defaultProps: {
      components: {
        OpenPickerIcon: SlalomCalendarIcon,
        LeftArrowIcon: SlalomCalendarArrowLeftIcon,
        RightArrowIcon: SlalomCalendarArrowRightIcon,
        SwitchViewIcon: SlalomCalendarSwitchViewIcon,
      }
    }
  },
  MuiDayPicker: {
    styleOverrides: {
      weekDayLabel: ({theme}) => ({
        fontSize: 13,
        color: theme.palette.text.primary,
        fontWeight: 800,
      })
    }
  },

  MuiInput: {
    styleOverrides: {
      root: {
        '&.Mui-disabled:before': {
          borderBottomStyle: 'solid',
        }
      },
      underline: ({theme}) => ({
        '&:after': {
          borderBottomColor: theme.palette.primary.main,
        },
        '&:before': {
          borderBottomColor: theme.palette.grey[500],
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
          borderBottomColor: theme.palette.primary.main
        }
      })
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({theme}) => ({
        ...theme.typography.h3,
        fontWeight: 400,
      })
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({theme}) => ({
        ...theme.typography.body1,
        '&.Mui-selected': {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.grey[300],
          '&:hover': {
            backgroundColor: theme.palette.grey[300],
          }
        },
        '&:hover': {
          backgroundColor: theme.palette.grey[300],
        },
      })
    }
  },
  MuiPickersArrowSwitcher: {
    styleOverrides: {
      button: {
        marginRight: 4,
        marginTop: 5,
        '& .MuiSvgIcon-root': {
          fontSize: 30,

          // the background when hovering over the button was not aligned correctly. Values will need to change if fontSize changes
          position: 'relative',
          left: 10,
          top: 6,
        },
        '&:hover': {
          borderRadius: 5,
        }
      },
      spacer: {
        width: 44
      }
    }
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        '&:first-of-type': {
          order: 0,
          paddingRight: 14,
          paddingLeft: 20
        }
      },
      label: ({theme}) => ({
        ...theme.typography.h4,
        color: theme.palette.primary.main,
        marginLeft: 10,
      }),
      switchViewButton: {
        display: 'flex',
        position: 'absolute',
        marginTop: 2,
        marginLeft: 197,
        '& .MuiSvgIcon-root': {
          fontSize: 36,

          // disable the switch view icon rotation that MUI does
          webKitTransition: '-webkit-transform 0s',
          transition: 'transform 0s',
          webkitTransform: 'rotate(0deg)',
          transform: 'rotate(0deg)',

          // the background when hovering over the button was not aligned correctly. Values will need to change if fontSize changes
          position: 'relative',
          left: 6,
          top: 6,
        },
        '&:hover': {
          borderRadius: 5,
        }
      }
    }
  },
  MuiPickersDay: {
    styleOverrides: {
      root: ({theme}) => ({
        fontWeight: 400,
        fontSize: 13,
        color: theme.palette.text.primary,
        '&.Mui-selected': {
          borderRadius: 5,
          color: theme.palette.common.white,
          fontWeight: 800,
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
          }
        },
        '&:hover': {
          borderRadius: 5,
        }
      })
    }
  },
  MuiRadio: {
    styleOverrides: {
      root: ({theme}) => ({
        color: theme.palette.grey[600],
        '&.Mui-checked': {
          color: theme.palette.grey[600],
        }
      }),
    },
    defaultProps: {
      size: 'small',
    }
  },
  MuiSelect: {
    styleOverrides: {
      select: ({theme}) => ({
        ...theme.typography.body1,
        fontSize: 13,
        color: theme.palette.text.secondary,
        '& .MuiSvgIcon-root': {
          fontSize: 20,
        },
        '&:focus':{
          backgroundColor: 'inherit',
        },
      }),
    },
    defaultProps: {
      IconComponent: KeyboardArrowDownRoundedIcon,
      MenuProps: {
        MenuListProps: {
          disablePadding: true,
          sx: {maxHeight: 198},
        }
      }
    }
  },
  MuiSnackbar: {
    defaultProps: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
    }
  },
  MuiSwitch: {
    styleOverrides: {
      root: ({theme}) => {
        const radius = 14;
        return {

          width: radius * 2 + 5,
          height: radius + 4,
          padding: 0,
          // margin: 10,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: `translateX(${radius}px)`,
              color: theme.palette.background.default,
              '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },

            '&.Mui-disabled .MuiSwitch-thumb': {
              color: theme.palette.grey[100]
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: radius,
            height: radius,
          },
          '& .MuiSwitch-track': {
            borderRadius: radius,
            backgroundColor: theme.palette.grey[500],
            opacity: 1,
            transition: (theme.transitions as Transitions).create(['background-color'], {
              duration: 500,
            }),
          },
        };},

    },
    variants: [
      {
        props: { size: 'label' },
        style: { marginRight: 10 }
      },
    ]
  },
  MuiTab: {
    styleOverrides: {
      root: ({theme}) => ({
        minHeight: 24,
        height: 24,
        textTransform: 'none',
        color: theme.palette.text.primary,
        ...theme.typography.body1,
        '&.Mui-selected': {
          color: theme.palette.text.secondary,
        }
      }),
    }
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: 24,
        height: 24,
      },
      indicator: {
        height: 3,
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontWeight: 400,
        color: theme.palette.text.primary,
        '&.Mui-selected': {
          borderRadius: 5,
          fontWeight: 800,
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
          }
        },
        '& .MuiSvgIcon-root': {
          fontSize: 20,
        },
        '&:hover': {
          borderRadius: 5,
        }
      })
    },
    defaultProps: {
      variant: 'standard',
    }
  },
};