import type { PropsWithChildren} from 'react';
import React, { createContext, useContext, useEffect,useReducer } from 'react';
import { InteractionRequiredAuthError, InteractionStatus, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';

import { getAzureADUserProfile } from './azure.func';
import type { AzureActions, AzureADUser,AzureContextType, PayloadType, UserState} from './azure.interface';
import { AzureActionsType} from './azure.interface';

export const AzureContext = createContext<AzureContextType>({ state: {user: {} as AzureADUser}, actions: undefined });
AzureContext.displayName = 'AzureADContext';

const initialState: UserState = {
  user: {} as AzureADUser
};

export function azureADReducer(state: UserState, action: PayloadType): UserState {
  switch (action.type) {
  case AzureActionsType.GET_USER:
    return { user: action.payload.user };
  }
}

function AzureADContextProvider(props: PropsWithChildren)  {
  const request = { scopes: ['User.Read'] };

  const [state, dispatch] = useReducer(azureADReducer, initialState);
  const { login, error } = useMsalAuthentication(InteractionType.Silent, request);
  const { instance, accounts, inProgress} = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, request);
    }
  }, [error]);

  useEffect(() => {
    instance.handleRedirectPromise()
      .then((s) => {
        if (s?.account) {
          instance.setActiveAccount(s.account);
          getActiveDirectoryProfile();
        }
      })
      .catch((err) => {
        console.log('Azure SSO Error', err);
      });
  }, []);

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      getActiveDirectoryProfile();
    }
  }, [inProgress, isAuthenticated, accounts, instance]);

  async function getActiveDirectoryProfile() {
    const res = await instance.acquireTokenSilent({
      account: accounts[0],
      scopes: ['User.Read']
    });
    const user = await getAzureADUserProfile(res.accessToken);
    dispatch({type: AzureActionsType.GET_USER, payload: { user }});
  }

  const actions: AzureActions = {
    getUser: () => { getActiveDirectoryProfile(); }
  };

  return (
    <AzureContext.Provider value={{ state, actions }}>
      {props.children}
    </AzureContext.Provider>
  );
}

function AzureAuthProvider(props: PropsWithChildren)  {
  const publicClientApplication = new PublicClientApplication({
    auth: {
      clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID || '',
      authority: process.env.REACT_APP_AZURE_AD_TENANT_URL || '',
      redirectUri: window.location.origin,
    }
  });

  return (
    <MsalProvider instance={publicClientApplication}>
      <AzureADContextProvider>
        {props.children}
      </AzureADContextProvider>
    </MsalProvider>
  );
}

export const useAzureContext = () => useContext(AzureContext);
export { AzureADContextProvider, AzureAuthProvider };