import type { PropsWithChildren} from 'react';
import React, { createContext, useContext, useEffect, useReducer } from 'react';

import type { RateType } from '@/api/RatesService/rates.interface';
import { rateService } from '@/api/RatesService/rates.service';
import { AuthStatus, useAuthContext } from '@/store/auth';
import type { Notification } from '@/store/notification';
import { useNotificationContext } from '@/store/notification';

import type { ActionsType,ContextType, PayloadType, StateType } from './rate.interface';
import { RateActionType } from './rate.interface';

const RateContext = createContext<ContextType>({state: { marketRate: {} as RateType }, actions: undefined});
RateContext.displayName = 'RateContext';
const initialState: StateType = {
  marketRate: undefined,
};

const reducer = (state: StateType, action: PayloadType): StateType => {
  switch (action.type) {
  case RateActionType.GET_RATES_BY_MARKET:
    return { ...state, marketRate: action.payload };
  }
};

function RateContextProvider(props: PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { dispatch: notification } = useNotificationContext();
  const {state: authState} = useAuthContext();

  async function getRatesData(market: string) {
    await rateService.getMarketRateData(market).then(rates => {
      dispatch({ type: RateActionType.GET_RATES_BY_MARKET, payload: rates});
    }).catch(err => {
      let error;
      if (typeof err === 'string') {
        error = err;
      } else if (err instanceof Error) {
        error = err.message;
      } else {
        error = 'An unknown error has occured';
      }

      const message = <>Ran into an issue while attempting to retrieve rates<br/>Error: {error}</>;

      const notify: Notification = {
        message,
        title: 'Market Rates',
        severity: 'error',
        id: `${Date.now()}`
      };

      notification?.addNotification(notify);
    });
  }

  const actions: ActionsType = {
    getRatesByMarket: (market: string) => {
      if(state.marketRate) return;
      getRatesData(market);
    },
    refresh: (market: string) => {
      getRatesData(market);
    },
  };

  useEffect(() => {
    const market = (authState.status === AuthStatus.Authenticated) && authState.user.market ? authState.user.market : 'Atlanta';
    actions.refresh(market);
  }, [authState]);

  return (
    <RateContext.Provider value={{ state, actions }}>
      {props.children}
    </RateContext.Provider>
  );
}

export const useRateContext = () => useContext(RateContext);
export { RateContext, RateContextProvider };