import type { FC} from 'react';
import React, { useEffect } from 'react';
import { FormProvider,useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, DialogActions, DialogContent, DialogContentText, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { isEqual } from 'lodash';

import type { Market } from '@/api/MarketService/market.interface';
import { marketService } from '@/api/MarketService/market.service';
import type {UpdateRateRequestType } from '@/api/RatesService/rates.interface';
import { getRatesDefaultValues, type RateType } from '@/api/RatesService/rates.interface';
import { rateService } from '@/api/RatesService/rates.service';
import BaseModal from '@/components/BaseModal';
import CancelModal from '@/components/CancelModal';
import type { RatesRolesFormType } from '@/containers/RatesRolesForm';
import { RatesRolesForm } from '@/containers/RatesRolesForm';
import { paths } from '@/services/Router/paths';
import { useAuthContext } from '@/store/auth';
import { isAdmin, isBasicAdmin, isRachelOrHamilton } from '@/store/auth/auth.func';
import { useLoadingContext } from '@/store/loading';
import { useNotificationContext } from '@/store/notification';

import { useStyles } from './Rates.styles';
import { schema } from './Rates.validation';

type DirtyFormChangeHandler = (isDirty: boolean) => void;
type OnSubmitHandler = (updateRequestBody: UpdateRateRequestType) => Promise<unknown>;

interface RatesComponentProps {
  dirtyFormCallback?: DirtyFormChangeHandler;
  onSubmitCallback: OnSubmitHandler;
  isOnboarding: boolean;
}

export const Rates: FC<RatesComponentProps> = ({dirtyFormCallback, isOnboarding, onSubmitCallback}) => {
  const navigation = useNavigate();
  const { dispatch: loader } = useLoadingContext();
  const { dispatch: notification } = useNotificationContext();
  const {state: authState} = useAuthContext();

  const [selectedMarket, setSelectedMarket] = React.useState('');
  const [marketData, setMarketData] = React.useState<RateType>();
  const [openCancel, setOpenCancel] = React.useState(false);
  const [openOnboarding, setOpenOnboarding] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const [marketSelectData, setMarketSelectData] = React.useState<Array<{value:string, text:string}>>([]);
  const canSeeOtherMarkets = isRachelOrHamilton();

  useEffect(() => {
    let userMarket = '';
    if(authState.user?.market && !isOnboarding){
      userMarket = authState.user.market;
      if(selectedMarket === ''){
        setSelectedMarket(userMarket);
      }
    }
  }, [authState.user?.market]);

  const fetchOnboardedMarkets = async() => {
    const data = await rateService.getRates();
    return data;
  };

  const fetchAllMarkets = async() => {
    const data = await marketService.getAllMarkets();
    return data;
  };

  useEffect(() => {
    let calculatedOnboardedMarkets: Market[];
    fetchOnboardedMarkets().then((rates) => {
      if(rates){
        calculatedOnboardedMarkets = rates.map(rate => ({ name: rate.market, region: ''}));

        if (isOnboarding) {
          return fetchAllMarkets();
        } else {
          if (!canSeeOtherMarkets) { // todo update with proper market authentication when defined
            calculatedOnboardedMarkets = calculatedOnboardedMarkets.filter(market => market.name === authState.user?.market);
          }
          const marketOptions = createMarketDropdownData(calculatedOnboardedMarkets);
          setMarketSelectData(marketOptions);
          return Promise.resolve(null); // don't call second API since we don't need the data
        }
      }
    })
      .then((allMarkets) => {
        if (allMarkets && calculatedOnboardedMarkets) {
          const remainingMarkets = allMarkets.filter(market => !calculatedOnboardedMarkets.some(onboardedMarket => market.name === onboardedMarket.name));
          const marketOptions = createMarketDropdownData(remainingMarkets);
          setMarketSelectData(marketOptions);
        }
      });
  },[]);

  const handleReset = () => {
    setOpenCancel(false);
    fetchMarketData().then(() => {
      loader?.setLoading({enable: false});
    }).catch(e => {
      loader?.setLoading({enable: false});
      notification?.addNotification({
        id: 'reset',
        severity: 'error',
        message: 'An error occurred while canceling changes: ' + e.message,
      });
    });
  };

  const methods = useForm<RatesRolesFormType>({
    defaultValues: {
      roleInfo: [],
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: RatesRolesFormType) => {
    const findModifiedRates = data.roleInfo.filter(formRoleInfo => !isEqual(formRoleInfo, marketData?.rates.find(marketRoleInfo => marketRoleInfo.role === formRoleInfo.role))).map(rateObj => rateObj.role);
    if (marketData) {
      const body: UpdateRateRequestType = {
        ...marketData,
        rates: data.roleInfo.map(roleInfo => ({
          ...roleInfo,
          modified: findModifiedRates.includes(roleInfo.role)
        })),
      };
      onSubmitCallback(body)
        .then(() => methods.reset(data));
    }
  };

  const goToOnboardingWorkflow = () => {
    setOpenOnboarding(false);
    navigation(paths.onboarding);
  };

  const handleCancel = () => {
    setOpenCancel(true);
  };

  const fetchMarketData = async () => {
    loader?.setLoading({enable: true});
    let rate: RateType;

    if (!isOnboarding) { // get the existing data for market if onboarded
      rate = await rateService.getMarketRateData(selectedMarket);
    } else {
      rate = getRatesDefaultValues(selectedMarket);
    }

    loader?.setLoading({enable: false});
    rate.rates.sort((a, b) => a.role.localeCompare(b.role));
    setMarketData(rate);
    methods.reset({'roleInfo': rate.rates});
  };

  useEffect(() => {
    setShowForm(false);
    if (selectedMarket != '') {
      fetchMarketData().then(() => {
        setShowForm(true);
      });
    }
  }, [selectedMarket]);

  const { classes } = useStyles();

  useEffect(() => dirtyFormCallback && dirtyFormCallback(methods.formState.isDirty), [dirtyFormCallback, methods.formState.isDirty]);

  return (
    <Box data-testid='Rates'>
      <CancelModal
        shouldNavBlock={methods.formState.isDirty}
        manual={{
          open: openCancel,
          reset: () => setOpenCancel(false),
          proceed: handleReset,
        }}
        titleText=""
        content=""
      />
      <BaseModal
        open={openOnboarding}
        title={'Are you sure?'}
        onClose={() => setOpenOnboarding(false)}>
        <DialogContent>
          <DialogContentText>
            {'Once rates and costs cards are created, users will be able to create estimates for new market. Please make sure you have all rates and costs on hand for each me@Slalom role before you start this process. For a worksheet to guide you, close out this window and click on the “+ Feedback & Support” button to download a worksheet.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={goToOnboardingWorkflow}>CONTINUE ONBOARDING</Button>
        </DialogActions>
      </BaseModal>
      <FormProvider {...methods} >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h3'>Me@Slalom Roles & Rates</Typography>
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <Typography variant='body1'>
                Roles & Rates added to this card will appear in the drop down in the role selection for the delivery team when creating an estimate.
                Read/write access to this page will be restricted based on title.
              </Typography>
            </Grid>
            <Grid item sm={12}>
              {(!isOnboarding && isRachelOrHamilton()) &&
              <Button
                data-testid="create-new-estimate-button"
                onClick={() => setOpenOnboarding(true)}
                startIcon={<AddIcon />}
              >
                Onboard New Market
              </Button>}
            </Grid>
            <Grid item xs={10}>
              <FormControl variant="standard" size="small">
                <InputLabel id="market-select-label" variant="standard" shrink>
                  <Typography variant="h5">Choose Your Market</Typography>
                </InputLabel>
                <Select
                  className={classes.dropDown}
                  labelId="market-select-label"
                  id="market-select"
                  value={selectedMarket}
                  onChange={(event) => setSelectedMarket(event.target.value)}
                  displayEmpty={true}
                  renderValue={selectedMarket !== '' ? undefined : () => 'Select option'}
                  data-testid='market-select'
                >
                  {marketSelectData.map((market)=>
                    <MenuItem key={market.value} value={market.value}>{market.text}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {showForm && <RatesRolesForm disabled={!isBasicAdmin()}/>}
            </Grid>
            <Grid item xs={12}>
              {!isOnboarding && (
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2, md: 2 }}
                >
                  <Button type='submit' size='form' disabled={!isAdmin() || !selectedMarket || (!methods.formState.isDirty || !methods.formState.isValid)}>Save</Button>
                  <Button size='formExtend' variant='outlined' disabled={!isAdmin() || !selectedMarket || (!methods.formState.isDirty || !methods.formState.isValid)} onClick={handleCancel}>Cancel</Button>
                </Stack>
              )}
              {isOnboarding && (
                <Button type='submit' size='form' disabled={!isRachelOrHamilton() || !selectedMarket || (!methods.formState.isDirty || !methods.formState.isValid)}>Next</Button>
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};

function createMarketDropdownData(markets: Market[]){
  return Array.from(
    new Set(markets.map(item => item.name))
  ).sort().map(value => ({ value, text: value }));
}

export { createMarketDropdownData };