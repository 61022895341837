import type {FC} from 'react';
import React from 'react';
import { SearchOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import type { SearchProps } from './Search.interface';
import { useStyles } from './Search.styles';

export const Search: FC<SearchProps> = ({props, value, label, type, showOptionalCloseIcon, clearSearchText, onSubmit, onChange}) => {
  const { classes } = useStyles();
  const submit = () => {
    onSubmit(value as string);
  };

  const clearSearchTextHandler = () => {
    clearSearchText?.();
  };

  return (
    <TextField
      {...props}
      data-testid="Search"
      label={label}
      value={value}
      onChange={onChange}
      InputLabelProps={{
        className: classes.labelText,
      }}
      InputProps={{
        ...props?.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined className={classes.searchIcon} data-testid="Search-SVG" onClick={() => onSubmit(value as string)} />
          </InputAdornment>
        ),
        endAdornment: showOptionalCloseIcon && (
          <IconButton
            onClick={clearSearchTextHandler}
            style={{padding:'4px', position: 'static'}}>
            <CloseIcon style={{visibility: value ? 'visible' : 'hidden', marginTop:0, position: 'absolute', right:0}}/>
          </IconButton>
        )
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          submit();
        }
      }}
      type={type ?? 'text'}
      variant="standard"
    />
  );
};