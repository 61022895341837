import { createTheme } from '@mui/material/styles';

import { defaultComponents } from './components';
import { defaultTypography } from './typography';

// TODO: use the following link to setup the dark mode - https://mui.com/material-ui/customization/dark-mode/

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: defaultTypography,
  components: defaultComponents,
});