import type {FC, PropsWithChildren} from 'react';
import React from 'react';

import LoadingOverlay from '@/components/LoadingOverlay';
import { Notifications } from '@/components/Notifications';
import { AuthContextProvider } from '@/store/auth';
import { AzureAuthProvider } from '@/store/azure';
import { ClientContextProvider } from '@/store/client';
import { EstimateContextProvider } from '@/store/estimates';
import { LoadingContextProvider } from '@/store/loading';
import { NotificationContextProvider } from '@/store/notification';
import { RateContextProvider } from '@/store/rate';


export const ContextProviders: FC<PropsWithChildren> = ({children}) => {
  return (
    <div data-testid="ContextProviders">
      <AzureAuthProvider>
        <AuthContextProvider>
          <NotificationContextProvider>
            <LoadingContextProvider>
              <Notifications />
              <LoadingOverlay />
              <ClientContextProvider>
                <RateContextProvider>
                  <EstimateContextProvider>
                    {children}
                  </EstimateContextProvider>
                </RateContextProvider>
              </ClientContextProvider>
            </LoadingContextProvider>
          </NotificationContextProvider>
        </AuthContextProvider>
      </AzureAuthProvider>
    </div>
  );
};