import type { FC} from 'react';
import React, {useEffect,useState} from 'react';
import { Button, DialogActions, DialogContent, TextField, Typography } from '@mui/material';

import BaseModal from '@/components/BaseModal';

import type { DeleteModalProps } from './DeleteModal.interface';

export const DeleteModal: FC<DeleteModalProps> = ({onDelete, open, onClose}) => {

  const [confirmedDelete, setConfirmedDelete] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (inputValue.toLowerCase() == 'delete') {
      setConfirmedDelete(true);
    } else { setConfirmedDelete(false); }
  }, [inputValue]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleClose=() => {
    setInputValue('');
    onClose();
  };

  return (
    <div data-testid="DeleteModal">
      <BaseModal title='Are you sure?' open={open} onClose={handleClose}>
        <DialogContent>
          <Typography>
            To delete this estimate, type the word DELETE into the field below.
          </Typography>
          <TextField
            placeholder='Type DELETE'
            value={inputValue}
            onChange={onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}> Cancel </Button>
          <Button variant="contained" disabled={!confirmedDelete} onClick={() => {setInputValue(''); onDelete();}}> Delete </Button>
        </DialogActions>
      </BaseModal>
    </div>
  );
};