import type { ClientProfileRoleType } from '@/api/ClientService/client.interface';
import type { RatesRolesType } from '@/api/RatesService/rates.interface';
import type { ClientRolesType, } from '@/containers/ClientProfileForm';
import { Proficiency } from '@/utils/constants';

export const getSlalomRate = (marketRates: RatesRolesType[], slalomRole: string, slalomProficiency: Proficiency) => {
  const slalomRates = marketRates?.find(rate => rate.role === slalomRole);
  switch (slalomProficiency) {
  case Proficiency.FOUNDATIONAL:
    return slalomRates?.foundationalRate;
  case Proficiency.PROFICIENT:
    return slalomRates?.proficientRate;
  case Proficiency.ADVANCED:
    return slalomRates?.advancedRate;
  case Proficiency.EXPERT:
    return slalomRates?.expertRate;
  default:
    return 0;
  }
};
export const mapToClientRolesType = (
  data: ClientProfileRoleType, marketRates: RatesRolesType[]
): ClientRolesType => {
  return {
    clientRole: data.clientRole,
    slalomRole: data.slalomRole,
    slalomProficiency: data.slalomProficiency,
    slalomRate: getSlalomRate(marketRates, data.slalomRole, data.slalomProficiency as Proficiency) || 0,
    clientRate: data.clientRate,
    isFixedRate: data.fixedRate,
  };
};