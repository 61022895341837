import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  dropDown: {
    marginTop: 1
  },
  button: {
    padding: 0,
    paddingLeft: 3
  },
  contingencyField: {
    marginTop: -3,
    width:45,
    '& .MuiInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    }
  },
  labelText: {
    ...theme.typography.body3,
    marginTop: -3,
  },
  sowField: {
    width: 90,
  },
  datePicker: {
    '& .MuiSvgIcon-root': {
      fontSize: 23,
    },
  }
}));
