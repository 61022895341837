import * as yup from 'yup';

import type {RatesRolesFormType } from '@/containers/RatesRolesForm';

export const schema = yup.object<RatesRolesFormType>({
  roleInfo: yup.array().of(
    yup.object().shape({
      role: yup.string().required(),
      foundationalRate: yup.number().min(0).required(),
      proficientRate: yup.number().min(0).required()
        .test({
          name: 'isGreaterThanFoundational',
          message: 'Foundational rate cannot be greater than Proficient rate',
          test: function (value) {
            if (value != 0) {
              return value > this.parent.foundationalRate;
            }
            return true;
          },
        }),
      advancedRate: yup.number().min(0).required()
        .test({
          name: 'isGreaterThanProficient',
          message: 'Proficient rate cannot be greater than Advanced rate',
          test: function (value) {
            if (value != 0) {
              return value > this.parent.proficientRate;
            }
            return true;
          },
        }),
      expertRate: yup.number().min(0).required()
        .test({
          name: 'isGreaterThanAdvanced',
          message: 'Advanced rate cannot be greater than Expert rate',
          test: function (value) {
            if (value != 0) {
              return value > this.parent.advancedRate;
            }
            return true;
          },
        })
    })
  )
});