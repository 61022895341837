import type { FC, ReactElement } from 'react';
import React from 'react';
import { SvgIcon } from '@mui/material';

import { ReactComponent as AgileProcessLoop } from './svg/agile-process-loop.svg';
import { ReactComponent as Alliance } from './svg/alliance.svg';
import { ReactComponent as AwardV1 } from './svg/award-v1.svg';
import { ReactComponent as AwardV2 } from './svg/award-v2.svg';
import { ReactComponent as BarGraph } from './svg/bar-graph.svg';
import { ReactComponent as Beverage } from './svg/beverage.svg';
import { ReactComponent as Book } from './svg/book.svg';
import { ReactComponent as Calendar } from './svg/calendar.svg';
import { ReactComponent as Campfire } from './svg/campfire.svg';
import { ReactComponent as Cancel } from './svg/cancel.svg';
import { ReactComponent as Car } from './svg/car.svg';
import { ReactComponent as CardReader } from './svg/card-reader.svg';
import { ReactComponent as CashRegister } from './svg/cash-register.svg';
import { ReactComponent as Celebration } from './svg/celebration.svg';
import { ReactComponent as Chat } from './svg/chat.svg';
import { ReactComponent as CheckMark } from './svg/check-mark.svg';
import { ReactComponent as Checkbox } from './svg/checkbox.svg';
import { ReactComponent as Choice } from './svg/choice.svg';
import { ReactComponent as City } from './svg/city.svg';
import { ReactComponent as Clock } from './svg/clock.svg';
import { ReactComponent as Cloud } from './svg/cloud.svg';
import { ReactComponent as CloudDownload } from './svg/cloud-download.svg';
import { ReactComponent as CloudUpload } from './svg/cloud-upload.svg';
import { ReactComponent as Coffee } from './svg/coffee.svg';
import { ReactComponent as CustomerService } from './svg/customer-service.svg';
import { ReactComponent as DataLake } from './svg/data-lake.svg';
import { ReactComponent as Database } from './svg/database.svg';
import { ReactComponent as Design } from './svg/design.svg';
import { ReactComponent as Document } from './svg/document.svg';
import { ReactComponent as DollarBills } from './svg/dollar-bills.svg';
import { ReactComponent as DollarSign } from './svg/dollar-sign.svg';
import { ReactComponent as DownArrow } from './svg/down-arrow.svg';
import { ReactComponent as Download } from './svg/download.svg';
import { ReactComponent as Ear } from './svg/ear.svg';
import { ReactComponent as Education } from './svg/education.svg';
import { ReactComponent as EngineeringInfrastructure } from './svg/engineering-infrastructure.svg';
import { ReactComponent as Factory } from './svg/factory.svg';
import { ReactComponent as Flag } from './svg/flag.svg';
import { ReactComponent as GameController } from './svg/game-controller.svg';
import { ReactComponent as Gear } from './svg/gear.svg';
import { ReactComponent as GlobeV1 } from './svg/globe-v1.svg';
import { ReactComponent as GlobeV2 } from './svg/globe-v2.svg';
import { ReactComponent as Government } from './svg/government.svg';
import { ReactComponent as GroundTransportation } from './svg/ground-transportation.svg';
import { ReactComponent as Growth } from './svg/growth.svg';
import { ReactComponent as Handshake } from './svg/handshake.svg';
import { ReactComponent as Healthcare } from './svg/healthcare.svg';
import { ReactComponent as Heart } from './svg/heart.svg';
import { ReactComponent as Home } from './svg/home.svg';
import { ReactComponent as HotAirBalloon } from './svg/hot-air-balloon.svg';
import { ReactComponent as Hourglass } from './svg/hourglass.svg';
import { ReactComponent as Integration } from './svg/integration.svg';
import { ReactComponent as JusticeScales } from './svg/justice-scales.svg';
import { ReactComponent as Laptop } from './svg/laptop.svg';
import { ReactComponent as LeftArrow } from './svg/left-arrow.svg';
import { ReactComponent as LightBulb } from './svg/light-bulb.svg';
import { ReactComponent as LightningBolt } from './svg/lightning-bolt.svg';
import { ReactComponent as LineGraph } from './svg/line-graph.svg';
import { ReactComponent as Location } from './svg/location.svg';
import { ReactComponent as Lock } from './svg/lock.svg';
import { ReactComponent as Logo } from './svg/logo.svg';
import { ReactComponent as LogoAtlanta } from './svg/logo-atlanta.svg';
import { ReactComponent as LogoAustin } from './svg/logo-austin.svg';
import { ReactComponent as LogoAustralia } from './svg/logo-australia.svg';
import { ReactComponent as LogoBoston } from './svg/logo-boston.svg';
import { ReactComponent as LogoCanada } from './svg/logo-canada.svg';
import { ReactComponent as LogoCharlotte } from './svg/logo-charlotte.svg';
import { ReactComponent as LogoChicago } from './svg/logo-chicago.svg';
import { ReactComponent as LogoColumbus } from './svg/logo-columbus.svg';
import { ReactComponent as LogoDallas } from './svg/logo-dallas.svg';
import { ReactComponent as LogoDenver } from './svg/logo-denver.svg';
import { ReactComponent as LogoDetroit } from './svg/logo-detroit.svg';
import { ReactComponent as LogoDeutschland } from './svg/logo-deutschland.svg';
import { ReactComponent as LogoEastBay } from './svg/logo-east-bay.svg';
import { ReactComponent as LogoFortWorth } from './svg/logo-fort-worth.svg';
import { ReactComponent as LogoGermany } from './svg/logo-germany.svg';
import { ReactComponent as LogoHartford } from './svg/logo-hartford.svg';
import { ReactComponent as LogoHouston } from './svg/logo-houston.svg';
import { ReactComponent as LogoJapan } from './svg/logo-japan.svg';
import { ReactComponent as LogoJapanJapanese } from './svg/logo-japan-japanese.svg';
import { ReactComponent as LogoKansasCity } from './svg/logo-kansas-city.svg';
import { ReactComponent as LogoLondon } from './svg/logo-london.svg';
import { ReactComponent as LogoLosAngeles } from './svg/logo-los-angeles.svg';
import { ReactComponent as LogoManchester } from './svg/logo-manchester.svg';
import { ReactComponent as LogoMelbourne } from './svg/logo-melbourne.svg';
import { ReactComponent as LogoMinneapolis } from './svg/logo-minneapolis.svg';
import { ReactComponent as LogoMontreal } from './svg/logo-montreal.svg';
import { ReactComponent as LogoMunich } from './svg/logo-munich.svg';
import { ReactComponent as LogoNashville } from './svg/logo-nashville.svg';
import { ReactComponent as LogoNewJersey } from './svg/logo-new-jersey.svg';
import { ReactComponent as LogoNewYork } from './svg/logo-new-york.svg';
import { ReactComponent as LogoOrangeCounty } from './svg/logo-orange-county.svg';
import { ReactComponent as LogoPhiladelphia } from './svg/logo-philadelphia.svg';
import { ReactComponent as LogoPhoenix } from './svg/logo-phoenix.svg';
import { ReactComponent as LogoPortland } from './svg/logo-portland.svg';
import { ReactComponent as LogoRaleigh } from './svg/logo-raleigh.svg';
import { ReactComponent as LogoSaltLakeCity } from './svg/logo-salt-lake-city.svg';
import { ReactComponent as LogoSanDiego } from './svg/logo-san-diego.svg';
import { ReactComponent as LogoSanFrancisco } from './svg/logo-san-francisco.svg';
import { ReactComponent as LogoSeattle } from './svg/logo-seattle.svg';
import { ReactComponent as LogoSouthFlorida } from './svg/logo-south-florida.svg';
import { ReactComponent as LogoStLouis } from './svg/logo-st-louis.svg';
import { ReactComponent as LogoSydney } from './svg/logo-sydney.svg';
import { ReactComponent as LogoTokyo } from './svg/logo-tokyo.svg';
import { ReactComponent as LogoTokyoJapanese } from './svg/logo-tokyo-japanese.svg';
import { ReactComponent as LogoToronto } from './svg/logo-toronto.svg';
import { ReactComponent as LogoUnitedKingdom } from './svg/logo-united-kingdom.svg';
import { ReactComponent as LogoVancouver } from './svg/logo-vancouver.svg';
import { ReactComponent as LogoWashingtonDc } from './svg/logo-washington-dc.svg';
import { ReactComponent as LogoWestchester } from './svg/logo-westchester.svg';
import { ReactComponent as Love } from './svg/love.svg';
import { ReactComponent as Lungs } from './svg/lungs.svg';
import { ReactComponent as Mail } from './svg/mail.svg';
import { ReactComponent as Manufacturing } from './svg/manufacturing.svg';
import { ReactComponent as Market } from './svg/market.svg';
import { ReactComponent as Minus } from './svg/minus.svg';
import { ReactComponent as Mobile } from './svg/mobile.svg';
import { ReactComponent as Molecule } from './svg/molecule.svg';
import { ReactComponent as Monitor } from './svg/monitor.svg';
import { ReactComponent as MountainPeak } from './svg/mountain-peak.svg';
import { ReactComponent as MousePointer } from './svg/mouse-pointer.svg';
import { ReactComponent as Network } from './svg/network.svg';
import { ReactComponent as News } from './svg/news.svg';
import { ReactComponent as NonProfit } from './svg/non-profit.svg';
import { ReactComponent as Options } from './svg/options.svg';
import { ReactComponent as Organic } from './svg/organic.svg';
import { ReactComponent as People } from './svg/people.svg';
import { ReactComponent as Person1 } from './svg/person-1.svg';
import { ReactComponent as Person2 } from './svg/person-2.svg';
import { ReactComponent as Person3 } from './svg/person-3.svg';
import { ReactComponent as Pivot } from './svg/pivot.svg';
import { ReactComponent as Plus } from './svg/plus.svg';
import { ReactComponent as PointerFinger } from './svg/pointer-finger.svg';
import { ReactComponent as Presentation } from './svg/presentation.svg';
import { ReactComponent as Progress } from './svg/progress.svg';
import { ReactComponent as PublicSector } from './svg/public-sector.svg';
import { ReactComponent as PublicTransportation } from './svg/public-transportation.svg';
import { ReactComponent as Pyramid } from './svg/pyramid.svg';
import { ReactComponent as Rainbow } from './svg/rainbow.svg';
import { ReactComponent as Refresh } from './svg/refresh.svg';
import { ReactComponent as Retail } from './svg/retail.svg';
import { ReactComponent as RightArrow } from './svg/right-arrow.svg';
import { ReactComponent as Roadmap } from './svg/roadmap.svg';
import { ReactComponent as RoboticAutomation } from './svg/robotic-automation.svg';
import { ReactComponent as Scale } from './svg/scale.svg';
import { ReactComponent as Science } from './svg/science.svg';
import { ReactComponent as Search } from './svg/search.svg';
import { ReactComponent as Security } from './svg/security.svg';
import { ReactComponent as Shipping } from './svg/shipping.svg';
import { ReactComponent as ShoppingCart } from './svg/shopping-cart.svg';
import { ReactComponent as Smile } from './svg/smile.svg';
import { ReactComponent as Sneaker } from './svg/sneaker.svg';
import { ReactComponent as Speed } from './svg/speed.svg';
import { ReactComponent as Stethoscope } from './svg/stethoscope.svg';
import { ReactComponent as Stopwatch } from './svg/stopwatch.svg';
import { ReactComponent as Strategy } from './svg/strategy.svg';
import { ReactComponent as Sunshine } from './svg/sunshine.svg';
import { ReactComponent as SupplyChainV1 } from './svg/supply-chain-v1.svg';
import { ReactComponent as SupplyChainV2 } from './svg/supply-chain-v2.svg';
import { ReactComponent as Syringe } from './svg/syringe.svg';
import { ReactComponent as Target } from './svg/target.svg';
import { ReactComponent as Technology } from './svg/technology.svg';
import { ReactComponent as TeddyBear } from './svg/teddy-bear.svg';
import { ReactComponent as Telecom } from './svg/telecom.svg';
import { ReactComponent as ThumbsUp } from './svg/thumbs-up.svg';
import { ReactComponent as TransitionHandoff } from './svg/transition-handoff.svg';
import { ReactComponent as TransitionPeople } from './svg/transition-people.svg';
import { ReactComponent as Travel } from './svg/travel.svg';
import { ReactComponent as Trophy } from './svg/trophy.svg';
import { ReactComponent as Unlock } from './svg/unlock.svg';
import { ReactComponent as UpArrow } from './svg/up-arrow.svg';
import { ReactComponent as Upload } from './svg/upload.svg';
import { ReactComponent as VennDiagram } from './svg/venn-diagram.svg';
import { ReactComponent as VideoCamera } from './svg/video-camera.svg';
import { ReactComponent as Vision } from './svg/vision.svg';
import { ReactComponent as WaterDroplets } from './svg/water-droplets.svg';
import { ReactComponent as WindTurbine } from './svg/wind-turbine.svg';
import { ReactComponent as Wrench } from './svg/wrench.svg';
import { ReactComponent as Write } from './svg/write.svg';
import { ReactComponent as XMark } from './svg/x-mark.svg';
import type { IconTable,SlalomIconProps } from './SlalomIcon.interface';

export const SlalomIcon: FC<SlalomIconProps> = ({color, disabled, height, shape, size, width, ...props}) => {
  const logos: IconTable = {
    'agile-process-loop': <AgileProcessLoop />,
    'alliance': <Alliance />,
    'award-v1': <AwardV1 />,
    'award-v2': <AwardV2 />,
    'bar-graph': <BarGraph />,
    'beverage': <Beverage />,
    'book': <Book />,
    'calendar': <Calendar />,
    'campfire': <Campfire />,
    'cancel': <Cancel />,
    'car': <Car />,
    'card-reader': <CardReader />,
    'cash-register': <CashRegister />,
    'celebration': <Celebration />,
    'chat': <Chat />,
    'check-mark': <CheckMark />,
    'checkbox': <Checkbox />,
    'choice': <Choice />,
    'city': <City />,
    'clock': <Clock />,
    'cloud-download': <CloudDownload />,
    'cloud-upload': <CloudUpload />,
    'cloud': <Cloud />,
    'coffee': <Coffee />,
    'customer-service': <CustomerService />,
    'data-lake': <DataLake />,
    'database': <Database />,
    'design': <Design />,
    'document': <Document />,
    'dollar-bills': <DollarBills />,
    'dollar-sign': <DollarSign />,
    'down-arrow': <DownArrow />,
    'download': <Download />,
    'ear': <Ear />,
    'education': <Education />,
    'engineering-infrastructure': <EngineeringInfrastructure />,
    'factory': <Factory />,
    'flag': <Flag />,
    'game-controller': <GameController />,
    'gear': <Gear />,
    'globe-v1': <GlobeV1 />,
    'globe-v2': <GlobeV2 />,
    'government': <Government />,
    'ground-transportation': <GroundTransportation />,
    'growth': <Growth />,
    'handshake': <Handshake />,
    'healthcare': <Healthcare />,
    'heart': <Heart />,
    'home': <Home />,
    'hot-air-balloon': <HotAirBalloon />,
    'hourglass': <Hourglass />,
    'integration': <Integration />,
    'justice-scales': <JusticeScales />,
    'laptop': <Laptop />,
    'left-arrow': <LeftArrow />,
    'light-bulb': <LightBulb />,
    'lightning-bolt': <LightningBolt />,
    'line-graph': <LineGraph />,
    'location': <Location />,
    'lock': <Lock />,
    'logo-atlanta': <LogoAtlanta />,
    'logo-austin': <LogoAustin />,
    'logo-australia': <LogoAustralia />,
    'logo-boston': <LogoBoston />,
    'logo-canada': <LogoCanada />,
    'logo-charlotte': <LogoCharlotte />,
    'logo-chicago': <LogoChicago />,
    'logo-columbus': <LogoColumbus />,
    'logo-dallas': <LogoDallas />,
    'logo-denver': <LogoDenver />,
    'logo-detroit': <LogoDetroit />,
    'logo-deutschland': <LogoDeutschland />,
    'logo-east-bay': <LogoEastBay />,
    'logo-fort-worth': <LogoFortWorth />,
    'logo-germany': <LogoGermany />,
    'logo-hartford': <LogoHartford />,
    'logo-houston': <LogoHouston />,
    'logo-japan-japanese': <LogoJapanJapanese />,
    'logo-japan': <LogoJapan />,
    'logo-kansas-city': <LogoKansasCity />,
    'logo-london': <LogoLondon />,
    'logo-los-angeles': <LogoLosAngeles />,
    'logo-manchester': <LogoManchester />,
    'logo-melbourne': <LogoMelbourne />,
    'logo-minneapolis': <LogoMinneapolis />,
    'logo-montreal': <LogoMontreal />,
    'logo-munich': <LogoMunich />,
    'logo-nashville': <LogoNashville />,
    'logo-new-jersey': <LogoNewJersey />,
    'logo-new-york': <LogoNewYork />,
    'logo-orange-county': <LogoOrangeCounty />,
    'logo-philadelphia': <LogoPhiladelphia />,
    'logo-phoenix': <LogoPhoenix />,
    'logo-portland': <LogoPortland />,
    'logo-raleigh': <LogoRaleigh />,
    'logo-salt-lake-city': <LogoSaltLakeCity />,
    'logo-san-diego': <LogoSanDiego />,
    'logo-san-francisco': <LogoSanFrancisco />,
    'logo-seattle': <LogoSeattle />,
    'logo-south-florida': <LogoSouthFlorida />,
    'logo-st-louis': <LogoStLouis />,
    'logo-sydney': <LogoSydney />,
    'logo-tokyo-japanese': <LogoTokyoJapanese />,
    'logo-tokyo': <LogoTokyo />,
    'logo-toronto': <LogoToronto />,
    'logo-united-kingdom': <LogoUnitedKingdom />,
    'logo-vancouver': <LogoVancouver />,
    'logo-washington-dc': <LogoWashingtonDc />,
    'logo-westchester': <LogoWestchester />,
    'logo': <Logo />,
    'love': <Love />,
    'lungs': <Lungs />,
    'mail': <Mail />,
    'manufacturing': <Manufacturing />,
    'market': <Market />,
    'minus': <Minus />,
    'mobile': <Mobile />,
    'molecule': <Molecule />,
    'monitor': <Monitor />,
    'mountain-peak': <MountainPeak />,
    'mouse-pointer': <MousePointer />,
    'network': <Network />,
    'news': <News />,
    'non-profit': <NonProfit />,
    'options': <Options />,
    'organic': <Organic />,
    'people': <People />,
    'person-1': <Person1 />,
    'person-2': <Person2 />,
    'person-3': <Person3 />,
    'pivot': <Pivot />,
    'plus': <Plus />,
    'pointer-finger': <PointerFinger />,
    'presentation': <Presentation />,
    'progress': <Progress />,
    'public-sector': <PublicSector />,
    'public-transportation': <PublicTransportation />,
    'pyramid': <Pyramid />,
    'rainbow': <Rainbow />,
    'refresh': <Refresh />,
    'retail': <Retail />,
    'right-arrow': <RightArrow />,
    'roadmap': <Roadmap />,
    'robotic-automation': <RoboticAutomation />,
    'scale': <Scale />,
    'science': <Science />,
    'search': <Search />,
    'security': <Security />,
    'shipping': <Shipping />,
    'shopping-cart': <ShoppingCart />,
    'smile': <Smile />,
    'sneaker': <Sneaker />,
    'speed': <Speed />,
    'stethoscope': <Stethoscope />,
    'stopwatch': <Stopwatch />,
    'strategy': <Strategy />,
    'sunshine': <Sunshine />,
    'supply-chain-v1': <SupplyChainV1 />,
    'supply-chain-v2': <SupplyChainV2 />,
    'syringe': <Syringe />,
    'target': <Target />,
    'technology': <Technology />,
    'teddy-bear': <TeddyBear />,
    'telecom': <Telecom />,
    'thumbs-up': <ThumbsUp />,
    'transition-handoff': <TransitionHandoff />,
    'transition-people': <TransitionPeople />,
    'travel': <Travel />,
    'trophy': <Trophy />,
    'unlock': <Unlock />,
    'up-arrow': <UpArrow />,
    'upload': <Upload />,
    'venn-diagram': <VennDiagram />,
    'video-camera': <VideoCamera />,
    'vision': <Vision />,
    'water-droplets': <WaterDroplets />,
    'wind-turbine': <WindTurbine />,
    'wrench': <Wrench />,
    'write': <Write />,
    'x-mark': <XMark />,
  };

  const getComonent = (): ReactElement => {
    return logos[shape];
  };

  const getSize = () => {
    if(size) return { width: size, height: size };
    if(height && !width) return { height: height };
    if(!height && width) return { width: width };
    if(height && width) return { height: height, width: width };
    return {};
  };

  const getColor = () => {
    if(disabled && color !== 'warning') return 'disabled';
    if(!color) return 'inherit';
    return color;
  };

  return (
    <SvgIcon data-testid="SlalomIcon" style={getSize()} color={getColor()} {...props} inheritViewBox={true}>
      {getComonent()}
    </SvgIcon>
  );
};
