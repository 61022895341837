import { addBusinessDays } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import type { DeliveryTeamMemberType, EstimationFormFieldsType } from './Estimation.interface';

const startDate = new Date();
const endDate = addBusinessDays(startDate, 4);

export const newDeliveryTeamMember: DeliveryTeamMemberType = {
  id: '',
  clientRole: '',
  slalomRole: '',
  proficiency: '',
  practice: '',
  salesforceLabel: '',
  slalomRate: '',
  clientRate: '',
  defaultHoursPerWeek: 40,
  contingency: true,
  startDate,
  endDate,
  weeklyBreakdown: [],
};

export const defaultValues: EstimationFormFieldsType = {
  // Project Details
  client: '',
  project: '',
  teamsLink: '',
  salesforceLink: '',
  contingency: 0.0,
  sow: '',
  startDate,
  duration: 1,
  endDate,

  // Delivery Team
  deliveryTeamMembers: Array.from({length: 3}, () => {
    const teamMember = {...newDeliveryTeamMember};
    teamMember.id = uuidv4();
    return teamMember;
  }),

  // Project Summary
  marketValue: 0,
  totalRateDiscounts: 0,
  projectCost: 0,
  slalomInvestment: 0,
  slalomInvestmentType: 'currency',
  slalomInvestmentDisplay: '0',
  otherInvestment: 0,
  otherInvestmentType: 'currency',
  otherInvestmentDisplay: '0',
  totalDiscounts: {
    percent: 0,
    currency: 0,
  },
  clientResponsibility: 0,

  // Pursuit Team
  pursuitTeamMembers: [],

  // Margin
  marketRateMargin: 0,
  estimatedProjectMargin: 0
};