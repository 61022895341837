import { useEffect } from 'react';
import type { UseFormReturn } from 'react-hook-form';

import type { DeliveryTeamMemberType, EstimationFormFieldsType } from '@/pages/Estimation/Estimation.interface';

import type { TotalsType, ValuesPerRowType } from './WeeklyHoursTable.interface';

export const updateTotals = (
  deliveryTeamMembers: DeliveryTeamMemberType[],
  setTotals: React.Dispatch<React.SetStateAction<TotalsType>>
) => {
  const hoursPerRow:  ValuesPerRowType = {};
  const costPerRow: ValuesPerRowType  = {};

  let totalHours = 0;
  let totalCost = 0;

  deliveryTeamMembers.forEach(teamMember => {
    if(!teamMember.isValid) return;
    hoursPerRow[teamMember.id] = teamMember.weeklyBreakdown.reduce((total, current) => total = total + Number(current.hours), 0);
    costPerRow[teamMember.id] = hoursPerRow[teamMember.id] * Number(teamMember.clientRate);
    totalHours += hoursPerRow[teamMember.id];
    totalCost += costPerRow[teamMember.id];
  });

  setTotals({hoursPerRow, costPerRow, totalCost, totalHours});
};

export const teamMemberUpdate = (
  estimationFormFields: EstimationFormFieldsType,
  name: string | undefined,
  setTotals: React.Dispatch<React.SetStateAction<TotalsType>>
) => {
  if(name === 'deliveryTeamMembers') {
    updateTotals(estimationFormFields.deliveryTeamMembers, setTotals);
  } else if(name?.includes('deliveryTeamMembers')) {
    const split = name.split('.');
    const index = Number(split[1]);
    const teamMembers = estimationFormFields.deliveryTeamMembers;
    const teamMember = teamMembers[index];

    if(teamMember?.isValid) {
      updateTotals(estimationFormFields.deliveryTeamMembers, setTotals);
    }
  }
};

export const useTeamMemberUpdateHook = (
  watchDeliveryTeamMembers: DeliveryTeamMemberType[],
  setTotals: React.Dispatch<React.SetStateAction<TotalsType>>,
  watch: UseFormReturn['watch']
) => {
  useEffect(() => {
    const subscription = watch((value, { name }) => teamMemberUpdate(value as EstimationFormFieldsType, name, setTotals));
    return () => subscription.unsubscribe();
  }, [watchDeliveryTeamMembers]);
};