import type { ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h7: React.CSSProperties;
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    h7?: React.CSSProperties;
    body3?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h7: true;
    body3: true;
  }
}

export const typography: ThemeOptions['typography'] = {
  fontFamily: ['\'Roboto\'', 'sans-serif'].join(','),
  h1: {
    fontSize: 32,
    fontWeight: 300,
  },
  h2: {
    fontSize: 28,
    fontWeight: 300,
  },
  h3: {
    fontSize: 22,
    fontWeight: 300,
  },
  h4: {
    fontSize: 18,
    fontWeight: 300,
  },
  h5: {
    fontSize: 16,
    fontWeight: 400,
  },
  h6: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '13px',
    fontFamily: ['\'Montserrat\'', 'sans-serif'].join(','),
  },
  h7: {
    fontSize: 14,
    fontWeight: 500,
  },
  body1: {
    fontSize: 14,
    fontWeight: 400,
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: ['\'Montserrat\'', 'sans-serif'].join(','),
  },
  body3: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
  },
  button: {
    fontFamily: ['\'Montserrat\'', 'sans-serif'].join(','),
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '14px',
    letterSpacing: 1,
  }
};
