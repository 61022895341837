import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  formControl:{
    m: 1,
    width: '194px'
  },
  addNewLabel: {
    color: theme.palette.primary.main,
  },
}));