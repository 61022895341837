import type { FC} from 'react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import TabGroup from '@/components/TabGroup';
import { paths } from '@/services/Router/paths';

const subNavMap = {
  [paths.estimation]: paths.home
};

const getBasePath = (pathname: string) => {
  const splitPath = pathname.split('/', 2);
  return  '/' + (splitPath[1] ?? '');
};

export const Navigation: FC<{navItems: Array<{name: string, value: string, hidden: boolean}>}> = ({navItems}) => {
  const navigation = useNavigate();
  const { pathname } = useLocation();
  const [defaultItemIndex, setDefaultItemIndex] = useState(navItems.findIndex(item => item.value === (subNavMap[getBasePath(pathname)] ? subNavMap[getBasePath(pathname)] : getBasePath(pathname))));

  useEffect(() => {
    const index = navItems.findIndex(item => item.value === (subNavMap[getBasePath(pathname)] ? subNavMap[getBasePath(pathname)] : getBasePath(pathname)));
    setDefaultItemIndex(index);
  }, [pathname]);

  const handleChange = (newPath: string) => {
    navigation(newPath);
  };

  return (
    <>
      <TabGroup tabItems={navItems} item={navItems[defaultItemIndex]} callback={handleChange} />
    </>
  );
};