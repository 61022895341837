import type { FC } from 'react';
import React, { useEffect } from 'react';
import type { FieldErrors } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';

import type { ErrorDescriptionProps, ErrorMessageType } from './ErrorDescription.interface';
import { useStyles } from './ErrorDescription.styles';

export const ErrorDescription: FC<ErrorDescriptionProps> = ({action}) => {
  const { classes } = useStyles();
  const { formState } = useFormContext();
  const [ errorMessage, setErrorMesage ] = React.useState<ErrorMessageType>({});

  useEffect(() => {
    const newErrorMessage: ErrorMessageType = {...errorMessage};

    const newKeys = Object.keys(formState.errors);
    const oldKeys = Object.keys(errorMessage);

    oldKeys.forEach(key => {
      if(!newKeys.includes(key)) {
        newErrorMessage[key].isError = false;
      }
    });

    newKeys.forEach(key => {
      if(!Array.isArray(formState.errors[key])) {
        newErrorMessage[key] = {
          isError: true,
          message: formState.errors[key]?.message as string,
          ref: formState.errors[key]?.ref as HTMLElement
        };
      } else if(Array.isArray(formState.errors[key])) {
        const sections = formState.errors[key] as unknown as FieldErrors[];
        sections.forEach((sec, i) => {
          if(!sec) return;

          Object.keys(sec).forEach(subkey => {
            const e = sec[subkey];
            const k = `${key}[${i}]-${subkey}`;
            newErrorMessage[k] = {
              isError: true,
              message: e?.message as string,
              ref: e?.ref as HTMLElement
            };
          });
        });
      }
    });

    // compare old to new
    let isEqual = true;
    const newErrorKeys = Object.keys(newErrorMessage);
    if (newErrorKeys.length !== oldKeys.length) {
      isEqual = false;
    } else {
      isEqual = newErrorKeys.every(key => {
        const objValue1 = errorMessage[key];
        const objValue2 = newErrorMessage[key];

        return objValue1?.message === objValue2?.message && objValue1?.isError === objValue2?.isError;
      });
    }

    if(!isEqual) {
      setErrorMesage(newErrorMessage);
    }
  }, [formState]);

  function createErrorList() {
    const list: JSX.Element[] = [];

    Object.keys(errorMessage).forEach(key => {
      const obj = errorMessage[key];
      const ErrorIcon = obj.isError ? <CloseIcon color='error'/> : <CheckIcon color='success'/>;

      list.push(
        React.cloneElement(
          <ListItemButton onClick={() => {obj.ref.focus();}}>
            <ListItem>
              {ErrorIcon}
              <ListItemText primary={obj.message as string} data-testid={`error-list-${key}`}/>
            </ListItem>
          </ListItemButton>,
          { key }
        )
      );
    });

    return list;
  }

  return (
    <Box data-testid="ErrorDescription" className={classes.borderOutter}>
      <Box className={classes.borderInner}>
        <Typography variant='h3' color='error'>Unable to {action}</Typography>
        <Divider/>
        <br/>
        <Typography variant='h5'>Please fix the following error(s) before attempting again:</Typography>
        <List dense={true}>
          {createErrorList()}
        </List>
      </Box>
    </Box>
  );
};