import type {FC} from 'react';
import React from 'react';
import {Box, Typography } from '@mui/material';

import type { PageLayoutProps } from './PageLayout.interface';
import { useStyles } from './PageLayout.styles';

export const PageLayout: FC<PageLayoutProps> = ({title, children}) => {
  const { classes } = useStyles();

  return (
    <div data-testid='PageLayout' className={classes.page}>
      <div className={classes.title}>
        <Typography variant='h1' color='text.secondary'>{title}</Typography>
      </div>
      <Box className={classes.content}>
        {children}
      </Box>
    </div>
  );
};