export enum Proficiency {
  FOUNDATIONAL = 'Foundational',
  PROFICIENT = 'Proficient',
  ADVANCED = 'Advanced',
  EXPERT = 'Expert'
}

export enum AvailableMarkets {
  ATLANTA = 'Atlanta',
  FLORIDA = 'Florida'
}