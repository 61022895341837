import type {FC} from 'react';
import React from 'react';
import { Box } from '@mui/material';

import { Header } from '@/components/Header';
import { Navigation } from '@/components/Navigation';
import { paths } from '@/services/Router/paths';
import { isBasicAdmin } from '@/store/auth/auth.func';

import type { LayoutProps } from './Layout.interface';
import { useStyles } from './Layout.styles';

export const Layout: FC<LayoutProps> = ({children}) => {

  const homeRoutes: Array<{name: string, value: string, hidden: boolean, adminRequired: boolean}> = [
    {name: 'Estimates', value: paths.home || paths.estimation, hidden: false, adminRequired: false},
    {name: 'Client Profiles', value: paths.clientProfile, hidden: false, adminRequired: false},
    {name: 'Admin', value: paths.admin, hidden: !isBasicAdmin(), adminRequired: true},
    {name: 'Onboarding', value: paths.onboarding, hidden: true, adminRequired: true}
  ];

  const { classes } = useStyles();
  return (
    <Box data-testid="Layout" className={classes.site}>
      <Header />
      <Box className={classes.nav} >
        <Navigation navItems={homeRoutes} />
      </Box>
      {children}
    </Box>
  );
};