//TODO: load from file: https://mui.com/material-ui/icons/
import React from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const SlalomCalendarSwitchViewIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.88891 4.44445C4.88891 4.68991 4.68992 4.88889 4.44446 4.88889C4.199 4.88889 4.00002 4.68991 4.00002 4.44445V1.77778C4.00002 1.53232 4.199 1.33334 4.44446 1.33334C4.68992 1.33334 4.88891 1.53232 4.88891 1.77778V4.44445ZM11.5556 4.88889C11.801 4.88889 12 4.68991 12 4.44445V1.77778C12 1.53232 11.801 1.33334 11.5556 1.33334C11.3101 1.33334 11.1111 1.53232 11.1111 1.77778V4.44445C11.1111 4.68991 11.3101 4.88889 11.5556 4.88889ZM5.7778 3.11111H10.2222V4H5.7778V3.11111ZM14.3334 3.11111H12.8889V4H14.2222V13.7778H1.7778V4H3.11113V3.11111H1.66669C1.45686 3.11462 1.25703 3.20135 1.11116 3.35222C0.96529 3.50309 0.885342 3.70573 0.888908 3.91556V13.8622C0.885342 14.072 0.96529 14.2747 1.11116 14.4256C1.25703 14.5764 1.45686 14.6632 1.66669 14.6667H14.3334C14.5432 14.6632 14.743 14.5764 14.8889 14.4256C15.0347 14.2747 15.1147 14.072 15.1111 13.8622V3.91556C15.1147 3.70573 15.0347 3.50309 14.8889 3.35222C14.743 3.20135 14.5432 3.11462 14.3334 3.11111ZM4.80446 9.55556L7.18669 11.9378L11.6534 7.45334C11.8044 7.277 11.7942 7.01414 11.63 6.84997C11.4659 6.68581 11.203 6.67566 11.0267 6.82667L7.18669 10.6667L5.43113 8.92889C5.25479 8.77788 4.99193 8.78803 4.82777 8.9522C4.66361 9.11636 4.65345 9.37922 4.80446 9.55556Z" fill="#0C62FB"/>
    </SvgIcon>
  );
};