import * as yup from 'yup';

import type { EstimationFormFieldsType } from './Estimation.interface';

function getDeliveryTeamMember(path: string) {
  const i = path.indexOf('[');
  const j = path.indexOf(']');
  const idx = path.substring(i+1, j);
  const number = parseInt(idx) + 1;
  return number;
}

function createDeliveryTeamMemberErrorMessage(path: string, error: string) {
  const teamMember = getDeliveryTeamMember(path);
  return `Delivery Team Member ${teamMember} ${error}`;
}

export const schema = yup.object<EstimationFormFieldsType>({
  client: yup.string().required('Client Name must be selected.'),
  project: yup.string().trim().required('Project Name must be entered.'),
  salesforceLink: yup.string().trim()
    .url('Salesforce Link must be a valid URL.')
    .required('Salesforce Link must be entered.'),
  sow: yup.string().required('SOW Type must be selected.'),
  deliveryTeamMembers: yup.array()
    .of(
      yup.object().shape({
        slalomRole: yup
          .string()
          .required((d) => createDeliveryTeamMemberErrorMessage(d.path, 'must have a Slalom Role entered.')),
        proficiency: yup
          .string()
          .required((d) => createDeliveryTeamMemberErrorMessage(d.path, 'must have a Proficiency entered.')),
        practice: yup
          .string()
          .required((d) => createDeliveryTeamMemberErrorMessage(d.path, 'must have a Practice entered.')),
        salesforceLabel: yup
          .string().trim()
          .required((d) => createDeliveryTeamMemberErrorMessage(d.path, 'must have a Salesforce Label entered.')),
        clientRate: yup.number()
          .required((d) => createDeliveryTeamMemberErrorMessage(d.path, 'must have a Client Rate entered.'))
          .typeError((d) => createDeliveryTeamMemberErrorMessage(d.path, 'must have a valid Client Rate number.')),
        defaultHoursPerWeek: yup.number()
          .required((d) => createDeliveryTeamMemberErrorMessage(d.path, 'must have a Default Hrs/Weeks entered.'))
          .typeError((d) => createDeliveryTeamMemberErrorMessage(d.path, 'must have a valid Default Hrs/Weeks number.')),
      })
    ).min(1)
});