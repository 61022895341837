import type {FC} from 'react';
import React, { useCallback } from 'react';
import { useBlocker } from 'react-router-dom';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import BaseModal from '@/components/BaseModal';

import type { CancelModalProps } from './CancelModal.interface';

export const CancelModal: FC<CancelModalProps> = ({titleText, content, shouldNavBlock, manual }) => {
  const blocker = useBlocker(shouldNavBlock);
  const reset = useCallback(() => {
    blocker.state === 'blocked' && blocker.reset();
    manual?.open && manual.reset();
  }, [blocker, manual]);
  const proceed = useCallback(() => {
    blocker.state === 'blocked' && blocker.proceed();
    manual?.open && manual.proceed();
  }, [blocker, manual]);
  return (
    <BaseModal
      open={ blocker.state === 'blocked' || (manual?.open === true) }
      title={titleText? titleText : 'Are you sure?'}
      onClose={reset}>
      <DialogContent>
        <DialogContentText>
          {content ? content : 'Any changes made will be lost. Click CONTINUE to discard changes.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={proceed}>CONTINUE</Button>
      </DialogActions>
    </BaseModal>
  );
};