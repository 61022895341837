import type { RateType } from '@/api/RatesService/rates.interface';

export interface StateType {
    marketRate: RateType | undefined;
}

export enum RateActionType {
    GET_RATES_BY_MARKET= 'GET_RATES_BY_MARKET',
}

export type PayloadType =
| { type: RateActionType.GET_RATES_BY_MARKET, payload: RateType }

export interface ActionsType {
    getRatesByMarket: (market: string) => void;
    refresh: (market: string) => void;
}

export interface ContextType {
    state: StateType;
    actions: ActionsType | undefined;
}