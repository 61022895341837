import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  stackRow: {
    minHeight: 110,
    display: 'flex',
    alignItems: 'flex-end'
  },
  dropDown: {
    paddingTop: 4,
    width: 130,
  },
  toggle: {
    paddingTop: 30,
  },
  searchIcon: {
    fontSize: '1.5rem',
  },
  labelText: {
    fontSize: '1rem',
  },
});