//TODO: load from file: https://mui.com/material-ui/icons/
import React from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const SlalomCalendarArrowLeftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M6.0089 0.880005L0.711121 6.00001L6.0089 11.12C6.30959 11.4096 6.78814 11.4007 7.07779 11.1C7.36743 10.7993 7.35848 10.3208 7.05779 10.0311L2.88445 6.00001L7.05779 1.96445C7.35848 1.67481 7.36743 1.19625 7.07779 0.89556C6.78814 0.594872 6.30959 0.585918 6.0089 0.87556V0.880005Z" fill="#0C62FB"/>
    </SvgIcon>
  );
};