import type {FC} from 'react';
import React, { useEffect} from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useLoadingContext } from '@/store/loading';

export const LoadingOverlay: FC = () => {
  const [open, setOpen] = React.useState(false);
  const {state: loader} = useLoadingContext();
  useEffect(() => {
    setOpen(loader.loadingState.loading);
  }, [loader]);

  return (
    <div
      data-testid="LoadingOverlay"
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
