import { API, Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

import { clientService } from '@/api/ClientService/client.service';
import { costService } from '@/api/CostsService/costs.service';
import { dataService } from '@/api/DataService/data.service';
import { EstimateService } from '@/api/EstimateService/estimate.service';
import { marketService } from '@/api/MarketService/market.service';
import { rateService } from '@/api/RatesService/rates.service';

import { useAuthContext } from './auth';
import type { User } from './auth.interface';
import { AdminJobTitles, AuthStatus, Role } from './auth.interface';
import { config } from './auth.interface';

export async function setupAuth() {
  const awsauth = {
    region: config.region,
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
    identityPoolId: config.identityPoolId,
    mandatorySignIn: true,
    oauth: {
      domain: config.domain,
      scope: ['openid'],
      redirectSignIn: `${window.location.origin}/`,
      redirectSignOut: `${window.location.origin}/`,
      responseType: 'code',
    },
  };
  Auth.configure(awsauth);
  API.configure({
    endpoints: [
      {
        name: rateService.apiServiceName,
        endpoint: rateService.endpointUrl,
        custom_header: getCustomHeader
      },
      {
        name: costService.apiServiceName,
        endpoint: costService.endpointUrl,
        custom_header: getCustomHeader
      },
      {
        name: marketService.apiServiceName,
        endpoint: marketService.endpointUrl,
        custom_header: getCustomHeader
      },
      {
        name: clientService.apiServiceName,
        endpoint: clientService.endpointUrl,
        custom_header: getCustomHeader,
      },
      {
        name: EstimateService.apiServiceName,
        endpoint: EstimateService.endpointUrl,
        custom_header: getCustomHeader,
      },
      {
        name: dataService.apiServiceName,
        endpoint: dataService.endpointUrl,
        custom_header: getCustomHeader,
      }
    ],
  });
}

export async function getAuthToken() {
  const session = await Auth.currentSession();
  const auth_token = session.getIdToken().getJwtToken();
  return auth_token;
}

async function getCustomHeader() {
  return {
    Authorization: await getAuthToken(),
    'Correlation-Object': `{"correlationId":"${uuidv4()}"}`,
  };
}

// TODO: EAS-321 Get All Profile Info from idToken
export async function getUserSession(): Promise<User> {
  const user: User = {} as User;

  await Auth.currentSession();
  const data = await Auth.currentUserPoolUser();

  user.email = data.signInUserSession.idToken.payload.email;

  return user;
}

export function isAdmin(): boolean {
  const {state: authState} = useAuthContext();
  return authState.status === AuthStatus.Authenticated &&
  (authState.user.role == Role.Admin
    || isRachelOrHamilton());
}

export function isBasicAdmin(): boolean {
  const {state: authState} = useAuthContext();
  return authState.status === AuthStatus.Authenticated &&
  (authState.user.role == Role.Admin || authState.user.role == Role.BasicAdmin);
}

export function isRachelOrHamilton(): boolean {
  const {state: authState} = useAuthContext();
  return (authState.user?.email === 'glennhe@slalom.com' || authState.user?.email === 'rachel.lieberman@slalom.com');
}

export function getRoleByJobTitle(jobTitle: string): Role {
  switch(jobTitle) {
  case AdminJobTitles.SeniorDirector:
  case AdminJobTitles.SeniorDeliveryDirector:
    return Role.BasicAdmin;
  case AdminJobTitles.ManagingDirector:
  case AdminJobTitles.GeneralManager:
    return Role.Admin;
  default:
    return Role.BasicUser;
  }
}