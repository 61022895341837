import type { FC} from 'react';
import React, { useEffect } from 'react';
import { FormProvider,useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { isEqual } from 'lodash';

import type { UpdateCostRequestType } from '@/api/CostsService/costs.interface';
import { type CostType, getCostsDefaultValues } from '@/api/CostsService/costs.interface';
import { costService } from '@/api/CostsService/costs.service';
import type { Market } from '@/api/MarketService/market.interface';
import { marketService } from '@/api/MarketService/market.service';
import CancelModal from '@/components/CancelModal';
import type { CostsRolesFormType } from '@/containers/CostsRolesForm';
import { CostsRolesForm } from '@/containers/CostsRolesForm';
import { useAuthContext } from '@/store/auth';
import { isRachelOrHamilton } from '@/store/auth/auth.func';
import { useLoadingContext } from '@/store/loading';
import { useNotificationContext } from '@/store/notification';

import { useStyles } from './Costs.styles';
import { schema } from './Costs.validation';

type DirtyFormChangeHandler = (isDirty: boolean) => void;
type OnSubmitHandler = (costRequestBody: UpdateCostRequestType) => Promise<unknown>;

interface CostsComponentProps {
  dirtyFormCallback?: DirtyFormChangeHandler;
  onSubmitCallback: OnSubmitHandler;
  isOnboarding: boolean;
  marketSelection?: string;
}

export const Costs: FC<CostsComponentProps> = ({dirtyFormCallback, isOnboarding, onSubmitCallback, marketSelection = ''}) => {
  const { dispatch: loader } = useLoadingContext();
  const { dispatch: notification } = useNotificationContext();
  const {state: authState} = useAuthContext();

  const [selectedMarket, setSelectedMarket] = React.useState(marketSelection);
  const [marketData, setMarketData] = React.useState<CostType>();
  const [openCancel, setOpenCancel] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const[marketSelectData, setMarketSelectData] = React.useState<Array<{value:string, text:string}>>([]);
  const canSeeOtherMarkets = isRachelOrHamilton();

  useEffect(() => {
    let userMarket = '';
    if(authState.user?.market && !isOnboarding){
      userMarket = authState.user.market;
      if(selectedMarket === ''){
        setSelectedMarket(userMarket);
      }
    }
  }, [authState.user?.market]);

  const fetchOnboardedMarkets = async() => {
    const data = await costService.getCosts();
    return data;
  };

  const fetchAllMarkets = async() => {
    const data = await marketService.getAllMarkets();
    return data;
  };

  useEffect(() => {
    let calculatedOnboardedMarkets: Market[];
    fetchOnboardedMarkets().then((costs) => {
      if(costs){
        calculatedOnboardedMarkets = costs.map(rate => ({ name: rate.market, region: ''}));

        if (isOnboarding) {
          return fetchAllMarkets();
        } else {
          if (!canSeeOtherMarkets) { // todo update with proper market authentication when defined
            calculatedOnboardedMarkets = calculatedOnboardedMarkets.filter(market => market.name === authState.user?.market);
          }
          const marketOptions = createMarketDropdownData(calculatedOnboardedMarkets);
          setMarketSelectData(marketOptions);
          return Promise.resolve(null); // don't call second API since we don't need the data
        }
      }
    })
      .then((allMarkets) => {
        if (allMarkets && calculatedOnboardedMarkets) {
          const remainingMarkets = allMarkets.filter(market => !calculatedOnboardedMarkets.some(onboardedMarket => market.name === onboardedMarket.name));
          const marketOptions = createMarketDropdownData(remainingMarkets);
          setMarketSelectData(marketOptions);
        }
      });
  },[]);

  const fetchMarketData = async () => {
    loader?.setLoading({enable: true});
    let cost: CostType;

    if (!isOnboarding) { // get the existing data for market if onboarded
      cost = await costService.getMarketCostData(selectedMarket);
    } else {
      cost = getCostsDefaultValues(selectedMarket);
    }

    loader?.setLoading({enable: false});
    cost.costs.sort((a, b) => a.role.localeCompare(b.role));
    setMarketData(cost);
    methods.reset({'roleInfo': cost.costs });
  };

  const handleReset = () => {
    setOpenCancel(false);
    fetchMarketData().then(() => {
      loader?.setLoading({enable: false});
    }).catch(e => {
      loader?.setLoading({enable: false});
      notification?.addNotification({
        id: 'reset',
        severity: 'error',
        message: 'An error occurred while canceling changes: ' + e.message,
      });
    });
  };

  const methods = useForm<CostsRolesFormType>({
    defaultValues: {
      roleInfo: [],
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: CostsRolesFormType) => {
    const findModifiedCosts = data.roleInfo.filter(formRoleInfo => !isEqual(formRoleInfo, marketData?.costs.find(marketRoleInfo => marketRoleInfo.role === formRoleInfo.role))).map(costObj => costObj.role);
    if (marketData) {
      const body: UpdateCostRequestType = {
        ...marketData,
        costs: data.roleInfo.map(roleInfo => ({
          ...roleInfo,
          modified: findModifiedCosts.includes(roleInfo.role)
        })),
      };
      onSubmitCallback(body)
        .then(() => methods.reset(data));
    }
  };

  const handleCancel = () => {
    setOpenCancel(true);
  };

  useEffect(() => {
    setShowForm(false);
    if (selectedMarket != '') {
      fetchMarketData().then(() => {
        setShowForm(true);
      });
    }
  }, [selectedMarket]);

  const { classes } = useStyles();

  useEffect(() => dirtyFormCallback && dirtyFormCallback(methods.formState.isDirty), [dirtyFormCallback, methods.formState.isDirty]);
  return (
    <Box data-testid='Costs'>
      <CancelModal
        shouldNavBlock={methods.formState.isDirty}
        manual={{
          open: openCancel,
          proceed: handleReset,
          reset: () => setOpenCancel(false),
        }}
        titleText=""
        content=""
      />
      <FormProvider {...methods} >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h3'>Me@Slalom Roles & Costs</Typography>
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <Typography variant='body1'>
                Roles & Costs added to this card will be used to estimate margins when creating an estimate. Read/write access to this page will be restricted to limited people.
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <FormControl variant="standard" size="small">
                <InputLabel id="market-select-label" variant="standard" shrink>
                  <Typography variant="h5">Choose Your Market</Typography>
                </InputLabel>
                <Select
                  className={classes.dropDown}
                  labelId="market-select-label"
                  id="market-select"
                  disabled={isOnboarding}
                  value={selectedMarket}
                  onChange={(event) => setSelectedMarket(event.target.value)}
                  displayEmpty={true}
                  renderValue={selectedMarket !== '' ? undefined : () => 'Select option'}
                  data-testid='market-select'
                >
                  {marketSelectData.map((market)=>
                    <MenuItem key={market.value} value={market.value}>{market.text}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {showForm && <CostsRolesForm disabled={!isRachelOrHamilton()}/>}
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
              >
                <Button type='submit' size='form' disabled={!isRachelOrHamilton() || !selectedMarket || (!methods.formState.isDirty || !methods.formState.isValid)}>Save</Button>
                {!isOnboarding && <Button size='formExtend' variant='outlined' disabled={!isRachelOrHamilton() || !selectedMarket || (!methods.formState.isDirty || !methods.formState.isValid)} onClick={handleCancel}>Cancel</Button>}
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};


function createMarketDropdownData(markets: Market[]) {
  return Array.from(
    new Set(markets.map(item => item.name))
  ).sort().map(value => ({ value, text: value }));
}

export { createMarketDropdownData };