import type { AlertProps, SnackbarProps } from '@mui/material';

import type { ActionListDispatch} from '@/store/utils';
import { buildEmptyDispatchers, newAction } from '@/store/utils';

export interface NotificationState {
  notifications: Notification[];
}
export interface Notification {
  id: string;
  severity?: AlertProps['severity'];
  message?: React.ReactNode;
  title?: string;
  autoHideDuration?: SnackbarProps['autoHideDuration'];
}

export const notificationActions = {
  addNotification: newAction<Notification>('addNotification'),
  removeNotification: newAction<string>('removeNotification'),
};

export type NotificationActions = typeof notificationActions;
export type NotificationDispatches = ActionListDispatch<NotificationActions>

export interface NotificationContext {
  state: NotificationState;
  dispatch: NotificationDispatches;
}

export const defaultNotificationContext: NotificationContext = {
  state: { notifications: [] },
  dispatch: buildEmptyDispatchers(notificationActions),
};