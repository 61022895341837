import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';

import type { BaseModalProps } from './BaseModal.interface';

export const BaseModal: React.FC<BaseModalProps> = ({open, title, onClose, children}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box id="dialog-content-container" sx={{ display: 'flex', flexDirection: 'column', margin: '16px'}}>
        <Box
          id="dialog-header"
          sx={{display: 'flex', justifyContent: 'space-between', height: '10%',}}
        >
          <DialogTitle><Typography sx={{fontWeight: 300, fontSize: 22, font: 'Roboto'}}>{title}</Typography></DialogTitle>
          <IconButton data-testid="modal-close-button" onClick={onClose}><CloseIcon fontSize='small' sx={{fontSize: 24}}/></IconButton>
        </Box>
        <Box sx={{minWidth: 240}}>
          {children}
        </Box>
      </Box>
    </Dialog>
  );
};