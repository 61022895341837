import { type Dispatch,useMemo } from 'react';

import type { Action, ActionChecker, ActionDispatch, ActionFunctions, ActionList, ActionListDispatch, UnknownAction } from './utils.interface';

export function newAction<P>(name: string) {
  const newSymbol = Symbol(name);
  type NewAction = Action<typeof newSymbol, P>;
  const createNewAction = (payload: P): NewAction => ({ type: newSymbol, payload, });
  const isAction: ActionChecker<NewAction> = (action: UnknownAction): action is NewAction => action.type === newSymbol;
  const dispatchAction: ActionDispatch<P, NewAction> = (dispatch: Dispatch<NewAction>): Dispatch<P> => (payload: P) => dispatch(createNewAction(payload));
  return { checker: isAction, dispatch: dispatchAction } as ActionFunctions<P, NewAction>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDispatchers<AL extends ActionList>(actions: AL, dispatch: Dispatch<any>): ActionListDispatch<AL> {
  return useMemo(() => {
    const tuples = Object.entries(actions)
      .map(([key, functions]) => ([key, functions.dispatch(dispatch)]));
    return Object.fromEntries(tuples);

  }, [actions, dispatch]);
}

export function buildEmptyDispatchers<AL extends ActionList>(actions: AL): ActionListDispatch<AL> {
  const tuples = Object.keys(actions)
    .map((key) => ([key, () => undefined]));
  return Object.fromEntries(tuples);
}

export async function asyncDispatch<A>(dispatch: Dispatch<A>, action: A) {
  dispatch(action);
}