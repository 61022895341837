import type { PropsWithChildren} from 'react';
import React, { useContext, useEffect, useReducer } from 'react';
import { createContext } from 'react';
import { Auth, Hub } from 'aws-amplify';

import { dataService } from '@/api/DataService/data.service';
import { useAzureContext } from '@/store/azure';
import { AvailableMarkets } from '@/utils/constants';

import { getRoleByJobTitle, getUserSession,setupAuth } from './auth.func';
import type { AuthAction, AuthActionsType, AuthContextType, AuthState, User} from './auth.interface';
import { AuthActionType, AuthStatus, config } from './auth.interface';

export const AuthContext = createContext<AuthContextType>({ state: {status: AuthStatus.Pending, user: undefined}, actions: undefined });
AuthContext.displayName = 'AuthContext';

export function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
  case AuthActionType.Logout:
    if (state.status === AuthStatus.Authenticated) {
      Auth.signOut();
    }
    return { status: AuthStatus.NoUser, user: undefined };
  case AuthActionType.Failed:
    return { status: AuthStatus.Failed, error: action.error, user: undefined  };
  case AuthActionType.NoUserFound:
    return { status: AuthStatus.NoUser, user: undefined  };
  case AuthActionType.Authenticated:
    return { status: AuthStatus.Authenticated, user: action.user };
  case AuthActionType.Pending:
    return { status: AuthStatus.Pending, user: undefined  };
  }
}

function AuthContextProvider(props: PropsWithChildren)  {

  const {state: azureState} = useAzureContext();
  const [authState, authDispatch] = useReducer(authReducer, {
    status: AuthStatus.Pending,
    user: undefined
  });

  useEffect(() => {
    if (azureState.user.employeeId) {
      dataService.getEmployeeById(azureState.user.employeeId).then((employee) => {
        // When the employee's market is not in the available markets, their estimates throw errors while getting the margin data
        // to fix this, we'll default the employee's market to atlanta if it is not in the list of available markets
        const market = (employee.market && employee.market.toUpperCase() in AvailableMarkets) ? employee.market: AvailableMarkets.ATLANTA;
        const user: User  = {
          employeeId: employee.employeeId,
          market: market,
          email: employee.email,
          firstName: employee.firstName,
          lastName: employee.lastName,
          capability: employee.capability,
          group: employee.group,
          jobTitle: employee.jobTitle,
          role: getRoleByJobTitle(employee.jobTitle)
        };
        authDispatch({ type: AuthActionType.Authenticated, user });
      });
    }
  }, [azureState.user.employeeId]);

  async function loginUser() {
    try {
      const user = await getUserSession();
      authDispatch({ type: AuthActionType.Authenticated, user });
    } catch (err) {
      console.log(err);
      if (err === 'No current user') {
        Auth.federatedSignIn({ customProvider: config.identityProvider });
        return authDispatch({ type: AuthActionType.NoUserFound });
      }
      return authDispatch({ type: AuthActionType.Failed, error: err as string });
    }
  }

  async function logoutUser() {
    authDispatch({ type: AuthActionType.Logout });
  }

  async function initAuth() {
    authDispatch({ type: AuthActionType.Pending });
    await setupAuth();
    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
      case 'signIn':
        loginUser();
        return loginUser();
      case 'signOut':
        logoutUser();
        break;
      }
    });
  }

  const actions: AuthActionsType = {
    initAuth: () => {
      initAuth();
      loginUser();
    }
  };

  return (
    <AuthContext.Provider value={{state: authState, actions}}>
      {props.children}
    </AuthContext.Provider>
  );
}

export const useAuthContext = () => useContext(AuthContext);
export { AuthContextProvider };