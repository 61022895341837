import type { FC } from 'react';
import React from 'react';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import Slide from '@mui/material/Slide';

import type { Notification} from '@/store/notification';
import { useNotificationContext } from '@/store/notification';

import type { SnackbarProviderType } from './Notifications.interface';

export const Notifications: FC = () => {
  const { state, dispatch } = useNotificationContext();

  const handleClose = (notification: Notification) => {
    dispatch?.removeNotification(notification.id);
  };

  return (
    <div data-testid="Notifications">
      {state?.notifications.length > 0 &&
        <SnackbarProvider
          key={state.notifications[state.notifications.length - 1].id}
          notification={state.notifications[state.notifications.length - 1]}
          handleClose={handleClose}
        />
      }
    </div>
  );
};

function SnackbarProvider({notification, handleClose}: SnackbarProviderType) {
  const onClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      // We want a timeout in caase of accidental double click
      setTimeout(() => {handleClose(notification);}, 3000);
      return;
    }

    handleClose(notification);
  };

  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={true} TransitionComponent={Slide} onClose={onClose} autoHideDuration={notification.autoHideDuration}>
      <Alert
        data-testid="Alert"
        onClose={onClose}
        id={`${notification.id}`}
        severity={notification.severity}
      >
        <AlertTitle>{notification.title}</AlertTitle>
        {notification.message}
      </Alert>
    </Snackbar>
  );
}