import * as yup from 'yup';

import type {  ClientProfileFormType } from '@/containers/ClientProfileForm';
export const schema = yup.object<ClientProfileFormType>({
  //clientId: yup.string().required(),
  market: yup.string().trim().required(),
  clientName: yup.string().trim().required(),
  roles: yup.array()
    .of(
      yup.object().shape({
        clientRate: yup.number().required(),
        clientRole: yup.string().trim().required(),
        isFixedRate: yup.boolean().required(),
        slalomProficiency: yup.string().required(),
        slalomRate: yup.number().required(),
        slalomRole: yup.string().trim().required(),
      })
    ).min(1)
});