import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  borderOutter: {
    borderWidth: 1,
    borderColor: theme.palette.common.black,
    borderStyle: 'solid',
    borderRadius: 5,
    padding: 5,
    margin: 5
  },
  borderInner: {
    borderWidth: 1,
    borderColor: theme.palette.common.black,
    borderStyle: 'dotted',
    borderRadius: 5,
    padding: 5
  },
}));