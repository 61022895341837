import type {FC} from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { Box, Button, debounce, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { addBusinessDays } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import type { ClientProfileType } from '@/api/ClientService/client.interface';
import type { EstimateAPIType, RoleAPIType } from '@/api/EstimateService/estimate.interface';
import type { MarginData, MarginDataItemRequest } from '@/api/RatesService/rates.interface';
import { rateService } from '@/api/RatesService/rates.service';
import CancelModal from '@/components/CancelModal';
import { ErrorDescription } from '@/components/ErrorDescription';
import { PageLayout } from '@/components/PageLayout';
import { DeliveryTeamForm } from '@/containers/DeliveryTeamForm';
import { ProjectDetailsForm } from '@/containers/ProjectDetailsForm';
import { PursuitTeamForm } from '@/containers/PursuitTeamForm';
import { SummaryForm } from '@/containers/SummaryForm';
import { WeeklyHoursTable } from '@/containers/WeeklyHoursTable';
import { AuthStatus, useAuthContext } from '@/store/auth';
import { useClientContext } from '@/store/client';
import { useEstimateContext } from '@/store/estimates';
import { useRateContext } from '@/store/rate';
import { Proficiency } from '@/utils/constants';
import { exportToExcel } from '@/utils/excel/excel-export';
import { roundDecimal } from '@/utils/math/math-helper';

import { defaultValues, newDeliveryTeamMember } from './Estimation.defaultFormValues';
import { calculateInvestment, calculateInvestmentValue, calculateSummaryValues, convertPercentToCurrency, createWeeklyBreakdown, getDuration, isTeamMemberValid } from './Estimation.func';
import type { DeliveryTeamMemberType, ErrorsType, EstimationFormFieldsType, InvestmentType, PursuitTeamMemberType, StatmentOfWorkType } from './Estimation.interface';
import { useStyles } from './Estimation.styles';
import { schema } from './Estimation.validation';

export const Estimation: FC = () => {
  const DEFAULT_TITLE = '{Client Name} Estimate For';
  const DEFAULT_PROJECT = '{Project Name}';

  const { classes } = useStyles();
  const { estimateId } = useParams();
  const { state: clients } = useClientContext();
  const clientsData = clients.clientProfiles || [];
  const [selectedClientProfile, setSelectedClientProfile] = useState<ClientProfileType | undefined>();
  const {state: authState} = useAuthContext();
  const navigate = useNavigate();

  const methods = useForm<EstimationFormFieldsType>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [refreshForm, setRefreshForm] = useState(true);
  const [isEstimateDisabled, setIsEstimateDisabled] = useState(true);
  const [estimateToUpdate, setEstimateToUpdate] = useState<EstimateAPIType>();
  const [salesforceLabelList, setSalesforceLabelList] = useState<Array<JSX.Element>>([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [errors, setErrors] = useState<ErrorsType>({found: false});
  const { estimateService, state: estimateState } = useEstimateContext();
  const existingEstimateData = estimateId ? estimateState.details[estimateId] : undefined;
  const [isSaving, setSaving] = useState(false);

  const { state: rates } = useRateContext();
  const { fields: deliveryFields, append: deliveryAppend, remove: deliveryRemove, insert: deliveryInsert } = useFieldArray({
    control: methods.control,
    name: 'deliveryTeamMembers',
  });
  const { fields: pursuitFields, append: pursuitAppend, remove: pursuitRemove } = useFieldArray({
    control: methods.control,
    name: 'pursuitTeamMembers',
  });

  const prevDuration = useRef(methods.getValues('duration'));
  const prevSlalomInvestmentDisplay = useRef(Number(methods.getValues('slalomInvestmentDisplay')));
  const prevOtherInvestmentDisplay = useRef(Number(methods.getValues('otherInvestmentDisplay')));

  const watchProjectCost = methods.watch('projectCost');
  const watchClientResponsibility = methods.watch('clientResponsibility');
  const watchTotalDiscountsPercent = methods.watch('totalDiscounts.percent');
  const watchDeliveryTeamMembers = methods.watch('deliveryTeamMembers');
  const watchMarketRateMargin = methods.watch('marketRateMargin');
  const watchEstimatedProjectMargin = methods.watch('estimatedProjectMargin');

  const getMarketRate = (slalomRole: string, proficiency: string) => {
    const ratesForRole = rates.marketRate?.rates.find(rateObj => rateObj.role === slalomRole);
    if (ratesForRole) {
      switch (proficiency) {
      case Proficiency.FOUNDATIONAL:
        return ratesForRole['foundationalRate'];
      case Proficiency.PROFICIENT:
        return ratesForRole['proficientRate'];
      case Proficiency.ADVANCED:
        return ratesForRole['advancedRate'];
      case Proficiency.EXPERT:
        return ratesForRole['expertRate'];
      default:
        return 0;
      }
    }
    return 0;
  };

  const mapRolesToTeamMembers = (roles: RoleAPIType[], startDate: string, endDate: string): DeliveryTeamMemberType[] => {
    return roles.map((role) => ({
      id: uuidv4(),
      clientRole: role.client_role,
      slalomRole: role.slalom_role,
      practice: role.practice,
      proficiency: role.proficiency,
      salesforceLabel: role.salesforce_label,
      slalomRate: getMarketRate(role.slalom_role, role.proficiency),
      clientRate: role.override_rate,
      defaultHoursPerWeek: role.hours_per_week,
      contingency: role.contingency == 'true',
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      weeklyBreakdown: role.weekly_hours.map(hours => ({hours})),
      isValid: true,
    }));
  };

  const updateMarketRate = (deliveryTeamMembers: DeliveryTeamMemberType[], index: number) => {
    const slalomRole = deliveryTeamMembers[index].slalomRole;
    const proficiency = deliveryTeamMembers[index].proficiency;
    if (slalomRole !== '' && proficiency !== '') {
      const rate = getMarketRate(slalomRole, proficiency);
      if (rate) {
        methods.setValue(`deliveryTeamMembers.${index}.slalomRate`, rate);
        if (!selectedClientProfile && deliveryTeamMembers[index].clientRate == '') {
          methods.setValue(`deliveryTeamMembers.${index}.clientRate`, rate, {shouldDirty: true, shouldValidate: true});
        }
      } else {
        methods.setValue(`deliveryTeamMembers.${index}.slalomRate`, 0);
        if (!selectedClientProfile ) {
          methods.setValue(`deliveryTeamMembers.${index}.clientRate`, 0, {shouldDirty: true, shouldValidate: true});
        }
      }
    }
  };

  useEffect(() => {
    if(estimateId && ! existingEstimateData) {
      estimateService.getById(estimateId);
    }
  }, [estimateId, existingEstimateData]);

  useEffect(() => {
    if (existingEstimateData) {
      setTitle(`${existingEstimateData.client_name} Estimate For`);
      setSubtitle(`${existingEstimateData.project_name}`);
      determineClientProfile(existingEstimateData.client_name);
      setEstimateToUpdate(existingEstimateData);
      const currentUserEmail: string = (authState.status === AuthStatus.Authenticated) && authState.user.email ? authState.user.email :'';
      const pursuitTeamEmailsSet:Set<string> = new Set(existingEstimateData?.pursuit_team.map(user =>  user.user_id));
      pursuitTeamEmailsSet.add(existingEstimateData.created_by);
      if(pursuitTeamEmailsSet.has(currentUserEmail)){
        setIsEstimateDisabled(false);
      }
    } else {
      setIsEstimateDisabled(false);
      setTitle(DEFAULT_TITLE);
      setSubtitle(DEFAULT_PROJECT);
    }
  }, [existingEstimateData]);

  useEffect(() => {
    if (refreshForm) {
      if (estimateId) {
        estimateService.getById(estimateId);
      } else {
        setIsEstimateDisabled(false);
        methods.reset();
        setTitle(DEFAULT_TITLE);
        setSubtitle(DEFAULT_PROJECT);
      }
      setRefreshForm(false);
    }
  }, [refreshForm]);

  useEffect(() => {
    if (estimateToUpdate && rates.marketRate) {
      populateForm(estimateToUpdate);
      setMargin(mapEstimationFormFieldsTypeToMarginRequest(methods.getValues()));
    }
  }, [estimateToUpdate, rates.marketRate]);

  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      const estimationFormFields = value as EstimationFormFieldsType;

      if(name === 'client') {
        setTitle(`${value.client ? value.client : 'New'} Estimate For`);
        // Clear client role inputs when a new client is selected;
        const teamMembers = estimationFormFields.deliveryTeamMembers;
        teamMembers.forEach((val, index) => {
          methods.setValue(`deliveryTeamMembers.${index}.clientRole`, '');
        });
        determineClientProfile(value.client);
      } else if(name === 'project') {
        setSubtitle(`${value.project || DEFAULT_PROJECT}`);
      } else if(name === 'deliveryTeamMembers') {
        const teamMembers = estimationFormFields.deliveryTeamMembers;
        updateSummaryValues(teamMembers);
      } else if(name?.includes('deliveryTeamMembers')) {
        const split = name.split('.');
        const index = Number(split[1]);
        const action: keyof DeliveryTeamMemberType = split[2] as keyof DeliveryTeamMemberType;
        const teamMembers = estimationFormFields.deliveryTeamMembers;
        const teamMember = teamMembers[index];


        //When a clientRole is selected, auto-set the values of the Slalom Role and Proficiency dropdowns from selected Client profile
        if (action === 'clientRole') {
          const clientProfileRoleFound = selectedClientProfile?.roles.find((item) => item.clientRole === estimationFormFields.deliveryTeamMembers[index].clientRole);
          if (clientProfileRoleFound) {
            methods.setValue(`deliveryTeamMembers.${index}.slalomRole`, clientProfileRoleFound.slalomRole, { shouldValidate: true });
            methods.setValue(`deliveryTeamMembers.${index}.proficiency`, clientProfileRoleFound.slalomProficiency, { shouldValidate: true });
            methods.setValue(`deliveryTeamMembers.${index}.salesforceLabel`, clientProfileRoleFound.clientRole, { shouldValidate: true });
            methods.setValue(`deliveryTeamMembers.${index}.clientRate`, clientProfileRoleFound.clientRate, { shouldValidate: true });
          }

          // In case slalomRole and proficiency was defined first, and then clientRole
          updateMarketRate(estimationFormFields.deliveryTeamMembers, index);
        }
        if (action === 'slalomRole' || action === 'proficiency') {
          updateMarketRate(estimationFormFields.deliveryTeamMembers, index);
        }

        if(action === 'clientRate'
          || action === 'practice'
          || action === 'proficiency'
          || action === 'salesforceLabel'
          || action === 'slalomRate'
        ) {
          const valid = isTeamMemberValid(teamMember);
          const duration = Number(estimationFormFields.duration);
          const contingency = Number(estimationFormFields.contingency) / 100;
          const startDate = estimationFormFields.startDate;
          const endDate = estimationFormFields.endDate;
          if(!teamMember.isValid && valid) {
            const weeklyBreakdown = createWeeklyBreakdown(duration, startDate, endDate, contingency, teamMember);
            teamMember.weeklyBreakdown = weeklyBreakdown;
          }

          teamMember.isValid = valid;
        }

        if ((action === 'contingency' || action === 'defaultHoursPerWeek') && teamMember.isValid) {
          const duration = Number(estimationFormFields.duration);
          const contingency = Number(estimationFormFields.contingency) / 100;
          const startDate = estimationFormFields.startDate;
          const endDate = estimationFormFields.endDate;
          const weeklyBreakdown = createWeeklyBreakdown(duration, startDate, endDate, contingency, teamMember);
          methods.setValue(`deliveryTeamMembers.${index}.weeklyBreakdown`, weeklyBreakdown);
        }

        if((action === 'clientRate' || action === 'weeklyBreakdown') && teamMember.isValid) {
          updateSummaryValues(teamMembers);
        }

      } else if(name === 'slalomInvestment'
        || name === 'otherInvestment'
        || name === 'projectCost')
      {
        const slalomInvestment = estimationFormFields.slalomInvestment;
        const slalomInvestmentType = estimationFormFields.slalomInvestmentType;
        const otherInvestment = estimationFormFields.otherInvestment;
        const otherInvestmentType = estimationFormFields.otherInvestmentType;
        const projectCost = Number(estimationFormFields.projectCost);
        const {
          percent,
          currency,
          clientResponsibility
        } = calculateInvestment(slalomInvestment, slalomInvestmentType, otherInvestment, otherInvestmentType, projectCost);

        methods.setValue('totalDiscounts', { percent, currency } );
        methods.setValue('clientResponsibility', clientResponsibility);
      } else if(name === 'slalomInvestmentDisplay') {
        if(prevSlalomInvestmentDisplay.current !== Number(estimationFormFields.slalomInvestmentDisplay.replaceAll(',', ''))) {
          updateSlalomInvestmentFields(estimationFormFields.slalomInvestmentDisplay);
        }
      } else if(name === 'otherInvestmentDisplay') {
        if(prevOtherInvestmentDisplay.current !== Number(estimationFormFields.otherInvestmentDisplay.replaceAll(',', ''))) {
          updateOtherInvestmentFields(estimationFormFields.otherInvestmentDisplay);
        }
      } else if(name === 'slalomInvestmentType') {
        const projectCost = estimationFormFields.projectCost;
        const investmentType = estimationFormFields.slalomInvestmentType;
        const investmentValue = estimationFormFields.slalomInvestment;

        const val = calculateInvestmentValue(projectCost, investmentType, investmentValue);
        const display = roundDecimal(val);
        prevSlalomInvestmentDisplay.current = display;
        methods.setValue('slalomInvestment', val);
        methods.setValue('slalomInvestmentDisplay', display.toLocaleString('en-US'));
      } else if(name === 'otherInvestmentType') {
        const projectCost = estimationFormFields.projectCost;
        const investmentType = estimationFormFields.otherInvestmentType;
        const investmentValue = estimationFormFields.otherInvestment;

        const val = calculateInvestmentValue(projectCost, investmentType, investmentValue);
        const display = roundDecimal(val);

        prevOtherInvestmentDisplay.current = display;
        methods.setValue('otherInvestment', val);
        methods.setValue('otherInvestmentDisplay', display.toLocaleString('en-US'));
      } else if(name === 'startDate' && type) {
        const startDate = estimationFormFields.startDate;
        const endDate = estimationFormFields.endDate;
        const teamMembers = estimationFormFields.deliveryTeamMembers;
        const duration = getDuration(startDate, endDate);
        const contingency = (Number(estimationFormFields.contingency) / 100);

        updateTeamMembersWeeklyHoursStartDate(duration, contingency, teamMembers);
        methods.setValue('duration', duration);
        prevDuration.current = duration;

        updateMembersWeeklyBreakdown(duration, startDate, endDate, contingency, teamMembers);
      } else if(name === 'endDate' && type) {
        const endDate = estimationFormFields.endDate;
        const startDate = estimationFormFields.startDate;
        const teamMembers = estimationFormFields.deliveryTeamMembers;
        const duration = getDuration(startDate, endDate);
        const contingency = (Number(estimationFormFields.contingency) / 100);

        updateTeamMembersWeeklyHoursEndDate(duration, contingency, teamMembers);
        methods.setValue('duration', duration);
        prevDuration.current = duration;

        updateMembersWeeklyBreakdown(duration, startDate, endDate, contingency, teamMembers);
      } else if(name === 'duration' && type) {
        const startDate = estimationFormFields.startDate;
        const duration = estimationFormFields.duration;
        const teamMembers = estimationFormFields.deliveryTeamMembers;
        const endDate = addBusinessDays(startDate, (duration * 5) - (startDate.getDay() == 0 || startDate.getDay() == 6 ? 0 : 1));
        const contingency = (Number(estimationFormFields.contingency) / 100);

        updateTeamMembersWeeklyHoursEndDate(duration, contingency, teamMembers);
        methods.setValue('endDate', endDate);
        prevDuration.current = duration;

        updateMembersWeeklyBreakdown(duration, startDate, endDate, contingency, teamMembers);
      } else if(name === 'contingency') {
        const startDate = estimationFormFields.startDate;
        const endDate = estimationFormFields.endDate;
        const duration = estimationFormFields.duration;
        const teamMembers = estimationFormFields.deliveryTeamMembers;
        const contingency = (Number(estimationFormFields.contingency) / 100);
        updateMembersWeeklyBreakdown(duration, startDate, endDate, contingency, teamMembers);
      }
    });

    return () => subscription.unsubscribe();
  }, [methods.watch, clientsData, selectedClientProfile]);

  useEffect(() => {
    const slalomLabels:{label: string; id: number}[] = [];
    const uniqueLabels = new Set<string>();
    estimateToUpdate?.roles.forEach((item,index) => {
      if(item.salesforce_label && !uniqueLabels.has(item.salesforce_label)){
        uniqueLabels.add(item.salesforce_label);
        slalomLabels.push({id: index, label: item.salesforce_label});
        slalomLabels.sort((a, b) => a.label.localeCompare(b.label));
      }
    });
    setSalesforceLabelList(Array.from(slalomLabels)?.sort((a,b) => a.label.localeCompare(b.label)).map((item, index) => (<MenuItem key={index} value={item.label}>{item.label}</MenuItem>)));
  },[estimateToUpdate]);

  const populateForm = (estimateData: EstimateAPIType) => {
    const teamMembers = mapRolesToTeamMembers(estimateData.roles, estimateData.start_date, estimateData.end_date);
    const {
      marketValue,
      totalRateDiscounts,
      projectCost
    } = calculateSummaryValues(teamMembers);
    const {
      percent,
      currency,
      clientResponsibility
    } = calculateInvestment(estimateData.slalom_investment_value, estimateData.slalom_investment_type as InvestmentType, estimateData.partner_funding_value, estimateData.partner_funding_type as InvestmentType, projectCost);

    methods.reset(formValues => ({
      ...formValues,
      // Project Details Form
      client: estimateData.client_name,
      project: estimateData.project_name,
      teamsLink: estimateData.teams_link,
      salesforceLink: estimateData.salesforce_link,
      contingency: estimateData.project_contingency,
      sow: estimateData.sow_type as StatmentOfWorkType,
      startDate: new Date(estimateData.start_date),
      duration: estimateData.duration,
      endDate: new Date(estimateData.end_date),
      // Delivery Team Form
      deliveryTeamMembers: teamMembers,
      // Project Summary Form
      marketValue,
      totalRateDiscounts,
      projectCost,
      otherInvestment: estimateData.partner_funding_value,
      otherInvestmentType: estimateData.partner_funding_type as InvestmentType,
      otherInvestmentDisplay: estimateData.partner_funding_value.toLocaleString('en-US'),
      slalomInvestment: estimateData.slalom_investment_value,
      slalomInvestmentType: estimateData.slalom_investment_type as InvestmentType,
      slalomInvestmentDisplay: estimateData.slalom_investment_value.toLocaleString('en-US'),
      totalDiscounts: {
        percent,
        currency,
      },
      clientResponsibility,
      // Pursuit Team Form
      pursuitTeamMembers: estimateData.pursuit_team.map(user => ({email: user.user_id}))
    }));
  };

  const determineClientProfile = (clientName: string | undefined) => {
    if (clientName) {
      const client = clientsData?.find((item) => item.clientName === clientName);
      if (client) {
        setSelectedClientProfile(client);
      } else setSelectedClientProfile(undefined);
    } else setSelectedClientProfile(undefined);
  };

  const updateSlalomInvestmentFields = useCallback(
    debounce((display: string) => {
      // Unfortunately we need to do a bit of messy casting here so that we can keep the display a comma delimited string and keep the other values numbers.
      methods.setValue('slalomInvestment', Number(display.replaceAll(',', '')));
      prevSlalomInvestmentDisplay.current = Number(display.replaceAll(',', ''));
      // This requires a double casting so that we can remove all the commas and put them in the correct space.
      methods.setValue('slalomInvestmentDisplay', Number(display.replaceAll(',','')).toLocaleString('en-US'));
    }, 500),
    []
  );

  const updateOtherInvestmentFields = useCallback(
    debounce((display: string) => {
      // Unfortunately we need to do a bit of messy casting here so that we can keep the display a comma delimited string and keep the other values numbers.
      methods.setValue('otherInvestment', Number(display.replaceAll(',', '')));
      prevOtherInvestmentDisplay.current = Number(display.replaceAll(',', ''));
      // This requires a double casting so that we can remove all the commas and put them in the correct space.
      methods.setValue('otherInvestmentDisplay', Number(display.replaceAll(',','')).toLocaleString('en-US'));
    }, 500),
    []
  );

  const updateTeamMembersWeeklyHoursStartDate = (
    duration: number,
    contingency: number,
    teamMembers: EstimationFormFieldsType['deliveryTeamMembers']
  ) => {
    const weeks = duration - prevDuration.current;
    if(weeks < 0) {
      shiftTeamMembersHours(Math.abs(weeks), teamMembers);
    } else if(weeks > 0) {
      unshiftTeamMembersHours(weeks, contingency, teamMembers);
    }
  };

  const updateTeamMembersWeeklyHoursEndDate = (
    duration: number,
    contingency: number,
    teamMembers: EstimationFormFieldsType['deliveryTeamMembers']
  ) => {
    const weeks = duration - prevDuration.current;
    if(weeks < 0) {
      popTeamMembersHours(Math.abs(weeks), teamMembers);
    } else if(weeks > 0) {
      pushTeamMembersHours(weeks, contingency, teamMembers);
    }
  };

  const updateMembersWeeklyBreakdown = (duration: number, startDate: Date, endDate: Date, contingency: number, teamMembers: EstimationFormFieldsType['deliveryTeamMembers']) => {
    teamMembers.forEach((teamMember, index) => {
      const weeklyBreakdown = createWeeklyBreakdown(duration, startDate, endDate, contingency, teamMember);
      methods.setValue(`deliveryTeamMembers.${index}.weeklyBreakdown`, weeklyBreakdown);
    });
  };

  const updateSummaryValues = (teamMembers: EstimationFormFieldsType['deliveryTeamMembers']) => {
    const {
      marketValue,
      totalRateDiscounts,
      projectCost
    } = calculateSummaryValues(teamMembers);

    methods.setValue('marketValue', marketValue);
    methods.setValue('totalRateDiscounts', totalRateDiscounts);
    methods.setValue('projectCost', projectCost);
  };

  const unshiftTeamMembersHours = (
    weeks: number,
    contingency: number,
    teamMembers: EstimationFormFieldsType['deliveryTeamMembers']
  ) => {
    teamMembers.forEach((member, index) => {
      if(!member.isValid) return;
      const weeklyBreakdown = member.weeklyBreakdown;
      const hours = member.contingency ? Math.floor(Number(member.defaultHoursPerWeek) * contingency) : Number(member.defaultHoursPerWeek);
      weeklyBreakdown.unshift(...new Array(weeks).fill({ hours }));
      methods.setValue(`deliveryTeamMembers.${index}.weeklyBreakdown`, weeklyBreakdown);
    });
  };

  const shiftTeamMembersHours = (weeks: number, teamMembers: EstimationFormFieldsType['deliveryTeamMembers']) => {
    teamMembers.forEach((member, index) => {
      if(!member.isValid) return;
      const weeklyBreakdown = member.weeklyBreakdown.slice(weeks);
      methods.setValue(`deliveryTeamMembers.${index}.weeklyBreakdown`, weeklyBreakdown);
    });
  };

  const pushTeamMembersHours = (
    weeks: number,
    contingency: number,
    teamMembers: EstimationFormFieldsType['deliveryTeamMembers']
  ) => {
    teamMembers.forEach((member, index) => {
      if(!member.isValid) return;
      const weeklyBreakdown = member.weeklyBreakdown;
      const hours = member.contingency ? Math.floor(Number(member.defaultHoursPerWeek) * contingency) : Number(member.defaultHoursPerWeek);
      weeklyBreakdown.push(...Array.from({length: weeks}, () => ({ hours })));
      methods.setValue(`deliveryTeamMembers.${index}.weeklyBreakdown`, weeklyBreakdown);
    });
  };

  const popTeamMembersHours = (weeks: number, teamMembers: EstimationFormFieldsType['deliveryTeamMembers']) => {
    teamMembers.forEach((member, index) => {
      if(!member.isValid) return;
      const length = member.weeklyBreakdown.length - weeks;
      const weeklyBreakdown = member.weeklyBreakdown.slice(0, length);
      methods.setValue(`deliveryTeamMembers.${index}.weeklyBreakdown`, weeklyBreakdown);
    });
  };

  const mapEstimationFormFieldsTypeToEstimateAPIType = (data: EstimationFormFieldsType):  EstimateAPIType => {
    const client = clients.clientProfiles?.find((profile)=> profile.clientName == data.client);
    const market = estimateToUpdate?.market ? estimateToUpdate?.market : (authState.status === AuthStatus.Authenticated) && authState.user.market ? authState.user.market : 'Atlanta';
    const teamMembers = mapTeamMembersToRoles(data.deliveryTeamMembers, market);
    return {
      estimate_id: estimateId ?? '',
      version: '1.0',
      client_id: client?.clientId ?? '',
      client_name: data.client,
      created_by: estimateToUpdate?.created_by ?? '',
      created_datetime: estimateToUpdate?.created_datetime ?? '',
      duration: data.duration,
      end_date: data.endDate.toDateString(),
      market: market,
      modified_by: estimateToUpdate?.modified_by ?? '',
      modified_datetime: estimateToUpdate?.modified_datetime ?? '',
      partner_funding_type: data.otherInvestmentType,
      partner_funding_value: Number(data.otherInvestmentDisplay.replaceAll(',', '')),
      project_contingency : data.contingency,
      project_name : data.project,
      pursuit_team: data.pursuitTeamMembers.map(user => ({estimate_id:  estimateId ?? '', role: 'Pursuit Team', user_id: user.email, user_name: user.email})),
      roles: teamMembers,
      salesforce_link: data.salesforceLink,
      slalom_investment_type: data.slalomInvestmentType,
      slalom_investment_value: Number(data.slalomInvestmentDisplay.replaceAll(',', '')),
      sow_type: data.sow,
      start_date: data.startDate.toDateString(),
      teams_link: data.teamsLink
    };
  };

  const mapEstimationFormFieldsTypeToMarginRequest = (data: EstimationFormFieldsType): MarginDataItemRequest => {
    const market = estimateToUpdate?.market ? estimateToUpdate?.market : (authState.status === AuthStatus.Authenticated) && authState.user.market ? authState.user.market : 'Atlanta';
    const marginData = mapTeamMembersToMarginData(data.deliveryTeamMembers);
    // partner funding
    let otherPartnerFunding = Number(data.otherInvestmentDisplay.replaceAll(',', ''));
    otherPartnerFunding = convertPercentToCurrency(data.otherInvestmentType, otherPartnerFunding, data.projectCost);
    // slalom funding
    let slalomFunding = Number(data.slalomInvestmentDisplay.replaceAll(',', ''));
    slalomFunding = convertPercentToCurrency(data.slalomInvestmentType, slalomFunding, data.projectCost);

    return {
      teamMembers: marginData,
      partner_other_funding: otherPartnerFunding,
      slalom_funding: slalomFunding,
      market: market
    };
  };

  const mapTeamMembersToMarginData= (teamMembers: DeliveryTeamMemberType[]): MarginData[] => {
    return teamMembers.map((member) => ({
      slalomRole: member.slalomRole,
      slalomRate: member.slalomRate as number,
      clientRate: member.clientRate as number,
      proficiency: member.proficiency,
      weeklyBreakdown: member.weeklyBreakdown
    }));
  };

  const mapTeamMembersToRoles= (teamMembers: DeliveryTeamMemberType[], market:string): RoleAPIType[] => {
    return teamMembers.map((member) => ({
      market: market,
      practice: member.practice,
      salesforce_label: member.salesforceLabel,
      contingency: member.contingency.toString(),
      slalom_role: member.slalomRole,
      weekly_hours: member.weeklyBreakdown.map(hoursType => (hoursType.hours)),
      override_rate: member.clientRate as number,
      client_role: member.clientRole,
      hours_per_week: member.defaultHoursPerWeek as number,
      proficiency: member.proficiency
    }));
  };

  const addTeamMember = () => {
    const teamMember: DeliveryTeamMemberType = {
      ...newDeliveryTeamMember,
    };
    teamMember.id = uuidv4();
    deliveryAppend(teamMember);
  };

  const addPursuitMember = (email: string) => {
    const teamMember: PursuitTeamMemberType = {
      email: email
    };

    pursuitAppend(teamMember);
  };

  const removeTeamMember = (index: number) => {
    deliveryRemove(index);
    if(deliveryFields.length === 1) {
      addTeamMember();
    }
  };

  const removePursuitMember = (index: number) => {
    pursuitRemove(index);
  };

  const duplicateTeamMember = (index: number) => {
    const teamMember = {...watchDeliveryTeamMembers[index]};
    teamMember.id = uuidv4();
    deliveryInsert(index, teamMember);
  };

  const handleCancel = () => {
    setRefreshForm(true);
    setShowModal(false);
  };

  const handleShare = () => {
    const [client, project, pursuitTeamMembers] = methods.getValues(['client', 'project', 'pursuitTeamMembers']);
    let mailTo = '';
    pursuitTeamMembers.forEach((member) => mailTo += member.email + '; ');
    const subject = `${project} Estimate for ${client}`;
    const body = `You have been invited to collaborate on the ${project} estimate for ${client}. Please refer to the link below to view or edit the estimate. ${window.location.href}`;

    window.location.href = `mailto:${mailTo}?subject=${subject}&body=${body}`;
  };

  const onError = () => {
    setErrors({found: true, action: 'Save'});
  };

  const onSubmit = async(data: EstimationFormFieldsType) => {
    if (estimateId) {
      const updateStatus = await estimateService.updateEstimate(mapEstimationFormFieldsTypeToEstimateAPIType(data));
      if(updateStatus === 'collision') {
        setModalTitle('Your Estimate Has Been Updated');
        setModalContent('Changes were recently made to the estimate by someone else. Click CONTINUE to discard your changes and load the latest version. Otherwise, close out this message to review your changes and manually refresh for the most up to date estimate.');
        setShowModal(true);
      }
    } else {
      setSaving(true);
      estimateService.createEstimate(mapEstimationFormFieldsTypeToEstimateAPIType(data), navigate)
        .finally(() => setSaving(false));
    }
    setErrors({found: false});
  };

  const onKeyDown = (keyEvent: React.KeyboardEvent<HTMLFormElement>) => {
    if (keyEvent.code === 'Enter'  || keyEvent.code === 'NumpadEnter') {
      keyEvent.preventDefault();
    }
  };

  const udpateSalesforceLabel = () =>{
    const slalomLabels:{label: string; id: number}[] = [];
    const uniqueLabels = new Set<string>();
    watchDeliveryTeamMembers.length && watchDeliveryTeamMembers?.forEach((item,index) => {
      if(item.salesforceLabel && !uniqueLabels.has(item.salesforceLabel)){
        uniqueLabels.add(item.salesforceLabel);
        slalomLabels.push({id: index, label: item.salesforceLabel});
        slalomLabels.sort((a, b) => a.label.localeCompare(b.label));
      }
    });
    setSalesforceLabelList(Array.from(slalomLabels)?.sort((a,b) => a.label.localeCompare(b.label)).map((item, index) => (<MenuItem key={index} value={item.label}>{item.label}</MenuItem>)));
  };

  const setMargin = async (marginRequest: MarginDataItemRequest) => {
    const marginResponse = await rateService.getMargin(marginRequest);
    methods.setValue('marketRateMargin', marginResponse.marketRateMargin ? marginResponse.marketRateMargin : 0);
    methods.setValue('estimatedProjectMargin', marginResponse.estimatedProjectMargin ? marginResponse.estimatedProjectMargin : 0);
  };

  return (
    <PageLayout title={title} data-testid='Estimation'>
      <FormProvider {...methods} >
        <form
          onSubmit={methods.handleSubmit(onSubmit, onError)}
          onKeyDown={onKeyDown}
          data-testid='EstimationForm'
        >
          <Grid container spacing={0}>
            <Grid xs={12} item>
              <Typography variant='h3' className={classes.subtitle}>{subtitle}</Typography>
            </Grid>
            <Grid item className={[classes.section, classes.bleedBackground].join(' ')}>
              <Stack
                direction='column'
                spacing={1}
              >
                <ProjectDetailsForm disabled={isEstimateDisabled}/>
              </Stack>
            </Grid>
            <Grid item xs={12} className={classes.section}>
              <DeliveryTeamForm fields={deliveryFields} selectedClientProfile={selectedClientProfile} onAdd={addTeamMember} onDuplicate={duplicateTeamMember} onRemove={removeTeamMember} udpateSalesforceLabel={udpateSalesforceLabel} disabled={isEstimateDisabled}/>
            </Grid>
            <Grid item xs={12} className={[classes.section, classes.bleedBackground].join(' ')}>
              <Stack
                direction='column'
                spacing={1}
              >
                <Typography variant='h3' color='text.secondary'>Weekly Hours & Cost Breakdown</Typography>
                <WeeklyHoursTable fields={deliveryFields} salesforceLabelList={salesforceLabelList} disabled={isEstimateDisabled}/>
              </Stack>
            </Grid>
            <Grid item container className={classes.section}>
              <Grid item xs={12} sm={3}>
                <SummaryForm disabled={isEstimateDisabled}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item container xs={12}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant='h3' color='text.secondary'>Pursuit Team</Typography>
                    <Typography variant='body3'>Some members have been added to this list automatically for cross practice visibility and parity with information already in Salesforce. </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <PursuitTeamForm fields={pursuitFields} onAdd={addPursuitMember} onRemove={removePursuitMember} disabled={isEstimateDisabled}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid item container xs={12} direction="row" alignItems="center" rowGap={2}>
                  <Grid item xs={12} sm={9}>
                    <Typography variant='h3' color='text.secondary'>Margin</Typography>
                    <Typography variant='body3'>Margin is estimated at a project level for both market and actual rates. Please save your estimate to see the most up to date calculations.</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography className={classes.marginStyle}>Market Rate Margin</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {watchMarketRateMargin.toLocaleString(undefined, {
                        useGrouping:true,
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1
                      })}%
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography className={classes.marginStyle}>Estimated Project Margin</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {watchEstimatedProjectMargin.toLocaleString(undefined, {
                        useGrouping:true,
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1
                      })}%
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container className={classes.section}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
              >
                <Box>
                  <Typography variant='h6'>Project Cost</Typography>
                  <Typography color='primary' variant='h6' className={[classes.finalCost, classes.final].join(' ')}>{watchProjectCost.toLocaleString('en', {
                    useGrouping:true,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })}</Typography>
                </Box>
                <Box>
                  <Typography variant='h6'>Client Responsibility</Typography>
                  <Typography color='primary' variant='h6' className={[classes.finalCost, classes.final].join(' ')}>{watchClientResponsibility.toLocaleString('en', {
                    useGrouping:true,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='h6'>Total Discounts</Typography>
                  <Typography color='primary' variant='h6' className={classes.final}>{(watchTotalDiscountsPercent * 100).toLocaleString('en', {
                    useGrouping:true,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })}%</Typography>
                </Box>
              </Stack>
            </Grid>
            {errors.found &&
              <Grid xs={12} item>
                <ErrorDescription action={errors.action}/>
              </Grid>
            }
            <Grid item className={classes.buttonGroup}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
              >
                <Button
                  type='submit'
                  size='form'
                  data-testid="SaveButton"
                >
                  Save
                </Button>
                <Button
                  variant='outlined'
                  size='formExtend'
                  disabled={!estimateId}
                  onClick={handleShare}
                >
                  Share Estimate
                </Button>
                <Button
                  variant='outlined'
                  size='formExtend'
                  onClick={() => {
                    setModalTitle('');
                    setModalContent('');
                    setShowModal(true);
                    setErrors({found: false});
                  }}
                  disabled={!methods.formState.isDirty}
                  data-testid="CancelButton"
                >
                  Cancel
                </Button>
                <CancelModal
                  shouldNavBlock={methods.formState.isDirty && !isSaving}
                  manual={{
                    open: showModal,
                    reset: () => setShowModal(false),
                    proceed: handleCancel,
                  }}
                  content={modalContent}
                  titleText={modalTitle}
                />
                <Button
                  variant='outlined'
                  size='formNarrow'
                  startIcon={<CloudDownloadOutlinedIcon />}
                  onClick={() => exportToExcel(methods.getValues())}
                  disabled={!methods.formState.isValid}
                >
                  Download a copy
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </PageLayout>
  );
};