import type { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  chip: {
    textTransform: 'none',
    letterSpacing: 'normal',
    ...theme.typography.caption as CSSObject,
  },
  button: {
    padding: 0,
  }
}));