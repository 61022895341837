import type { RateAPIType, RatesRolesAPIType, RatesRolesType, RateType, UpdateRateRequestType } from '@/api/RatesService/rates.interface';

export const mapToRatesRolesType = (data: RatesRolesAPIType): RatesRolesType => {
  return {
    role: data.role,
    foundationalRate: data.foundational,
    proficientRate: data.proficient,
    advancedRate: data.advanced,
    expertRate: data.expert,
    lastUpdated: new Date(data.last_updated),
  };
};

export const mapToRateType = (data: RateAPIType): RateType => {
  return {
    market: data.market,
    revisedDate: new Date(data.revised_date),
    capabilities: data.capabilities,
    rates: data.rates.map((item: RatesRolesAPIType) => {
      return mapToRatesRolesType(item);
    })
  };
};

export const mapFormToRequest = (form: UpdateRateRequestType): Partial<RateAPIType> => {
  return {
    market: form.market,
    capabilities: form.capabilities,
    rates: form.rates.map((obj) => ({
      role: obj.role,
      foundational: obj.foundationalRate,
      proficient: obj.proficientRate,
      advanced: obj.advancedRate,
      expert: obj.expertRate,
      last_updated: obj.lastUpdated.toString(),
      modified: obj.modified
    }))
  };
};