import { API } from 'aws-amplify';

import type { Employee, EmployeeAPIResponse, EmployeeAPIType, PursuitMemberAPIResponse, PursuitMemberAPIType } from './data.interface';

const DATA_SERVICE_URL = process.env.REACT_APP_DATA_SERVICE_URL;
const EMPLOYEE_BY_ID_PATH = '/slalom-data/employeeID/';
const EMPLOYEE_BY_QUERY_PATH = '/slalom-data/search/';

class DataService {
  public apiServiceName = 'DataService';
  public endpointUrl = DATA_SERVICE_URL;

  mapEmployeeAPITypeToEmployee(employee: EmployeeAPIType): Employee {
    return {
      employeeId: employee.employeeId,
      market: employee.market.name,
      email: employee.email,
      firstName: employee.person.firstName,
      lastName: employee.person.lastName,
      capability: employee.capability.name,
      group: employee.group.name,
      jobTitle: employee.jobTitle,
    };
  }

  async getEmployeeById(id: string): Promise<Employee> {
    const res: EmployeeAPIResponse = await API.get(this.apiServiceName, EMPLOYEE_BY_ID_PATH + id,  {});
    return this.mapEmployeeAPITypeToEmployee(res.result);
  }

  async getEmployeeByQuery(query: string): Promise<PursuitMemberAPIType[]> {
    const res: PursuitMemberAPIResponse = await API.get(this.apiServiceName, EMPLOYEE_BY_QUERY_PATH + query,  {});
    return res.result;
  }

}

export const dataService = new DataService();