import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  searchIcon: {
    fontSize: '1.5rem',
  },
  labelText: {
    fontSize: '1rem',
  },
  subLabelText: {
    fontSize: 11,
  },
  divStyle: {
    paddingTop: 3,
    paddingLeft: 15,
  },
  stackStyle: {
    width: 250
  },
  gridStyle: {
    marginTop: 20,
    width: 350
  },

});