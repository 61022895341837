import type { Market } from './market.interface';

// todo this will eventually be replaced by an actual service call
// this will be in alignment with a move from DynamoDB multi-table structure
// to a RDS implementation where there can be a consistent data table for markets
// for now we will hard code the list for this service for US & Canadian markets
class MarketService {
  public apiServiceName = 'CostService';
  public endpointUrl = '';

  getAllMarkets(): Promise<Market[]> {
    const markets: Market[] = [
      {
        name: 'Atlanta',
        region: 'United States'
      },
      {
        name: 'Austin',
        region: 'United States'
      },
      {
        name: 'Boston',
        region: 'United States'
      },
      {
        name: 'Charlotte',
        region: 'United States'
      },
      {
        name: 'Chicago',
        region: 'United States'
      },
      {
        name: 'Columbus',
        region: 'United States'
      },
      {
        name: 'Dallas Fort Worth',
        region: 'United States'
      },
      {
        name: 'Denver',
        region: 'United States'
      },
      {
        name: 'Detroit',
        region: 'United States'
      },
      {
        name: 'Federal',
        region: 'United States'
      },
      {
        name: 'Florida',
        region: 'United States'
      },
      {
        name: 'Hartford',
        region: 'United States'
      },
      {
        name: 'Houston',
        region: 'United States'
      },
      {
        name: 'Kansas City',
        region: 'United States'
      },
      {
        name: 'Los Angeles',
        region: 'United States'
      },
      {
        name: 'Minneapolis',
        region: 'United States'
      },
      {
        name: 'Nashville',
        region: 'United States'
      },
      {
        name: 'New Jersey',
        region: 'United States'
      },
      {
        name: 'New York',
        region: 'United States'
      },
      {
        name: 'NorCal',
        region: 'United States'
      },
      {
        name: 'Orange County',
        region: 'United States'
      },
      {
        name: 'Philadelphia',
        region: 'United States'
      },
      {
        name: 'Phoenix',
        region: 'United States'
      },
      {
        name: 'Portland',
        region: 'United States'
      },
      {
        name: 'Raleigh',
        region: 'United States'
      },
      {
        name: 'Salt Lake City',
        region: 'United States'
      },
      {
        name: 'San Diego',
        region: 'United States'
      },
      {
        name: 'Seattle',
        region: 'United States'
      },
      {
        name: 'St. Louis',
        region: 'United States'
      },
      {
        name: 'Washington, DC',
        region: 'United States'
      },
      {
        name: 'Montréal',
        region: 'Canada'
      },
      {
        name: 'Toronto',
        region: 'Canada'
      },
      {
        name: 'Western Canada',
        region: 'Canada'
      },
    ];

    return Promise.resolve(markets);
  }

}

export const marketService = new MarketService();