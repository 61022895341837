import type { FC} from 'react';
import React, { useMemo, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import { Grid }from '@mui/material';

import type { UpdateCostRequestType } from '@/api/CostsService/costs.interface';
import { costService } from '@/api/CostsService/costs.service';
import type { UpdateRateRequestType } from '@/api/RatesService/rates.interface';
import { rateService } from '@/api/RatesService/rates.service';
import CancelModal from '@/components/CancelModal';
import { PageLayout } from '@/components/PageLayout/PageLayout';
import { TabGroup } from '@/components/TabGroup/TabGroup';
import { paths } from '@/services/Router/paths';
import { isRachelOrHamilton } from '@/store/auth/auth.func';
import { useLoadingContext } from '@/store/loading/loading';
import { useNotificationContext } from '@/store/notification';

import { Costs } from './costs/Costs';
import { Rates } from './rates/Rates';

export const Admin: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isRachelOrHamiltonVal = isRachelOrHamilton();
  const adminTabs = useMemo<Array<{name: string, value: string, hidden: boolean}>>(() => [
    {name: 'Rates', value: paths.adminRates, hidden: false},
    {name: 'Costs', value: paths.adminCost, hidden: !isRachelOrHamiltonVal},
  ], [isRachelOrHamiltonVal]);
  const currentTab = useMemo(() => {
    const costPathActive = matchPath(location.pathname, paths.adminCost) != null;
    return costPathActive ? adminTabs[1] : adminTabs[0];
  }, [location, adminTabs]);
  const [isCurrentTabDirty, setIsCurrentTabDirty] = useState(false);
  const { dispatch: loader } = useLoadingContext();
  const { dispatch: notification } = useNotificationContext();

  const handleTabChange = (newPath: string) => {
    navigate(newPath);
  };

  const handleFormChange = (isDirty: boolean) => {
    setIsCurrentTabDirty(isDirty);
  };

  const onRatesSubmit = (ratesRequestBody: UpdateRateRequestType) => {
    loader?.setLoading({enable: true});
    const save = rateService.saveMarketRates(ratesRequestBody);
    save.then(() => {
      loader?.setLoading({enable: false});
      notification?.addNotification({
        id: ratesRequestBody.market,
        severity: 'success',
        message: 'Changes have been successfully saved!',
      });
    }).catch(e => {
      loader?.setLoading({enable: false});
      notification?.addNotification({
        id: ratesRequestBody.market,
        severity: 'error',
        message: 'An error occurred while attempting to save market rates: ' + e.message,
      });
    });
    return save;
  };

  const onCostsSubmit = (costsRequestBody: UpdateCostRequestType) => {
    loader?.setLoading({enable: true});
    const save = costService.saveMarketCosts(costsRequestBody);
    save.then(() => {
      loader?.setLoading({enable: false});
      notification?.addNotification({
        id: costsRequestBody.market,
        severity: 'success',
        message: 'Changes have been successfully saved!',
      });
    }).catch(e => {
      loader?.setLoading({enable: false});
      notification?.addNotification({
        id: costsRequestBody.market,
        severity: 'error',
        message: 'An error occurred while attempting to save market costs: ' + e.message,
      });
    });
    return save;
  };

  return (
    <PageLayout title='Admin'>
      <CancelModal shouldNavBlock={isCurrentTabDirty} titleText='' content='' />
      <Grid data-testid='Admin' container spacing={2}>
        <Grid item xs={10}>
          {(currentTab.name === 'Rates') && <Rates dirtyFormCallback={handleFormChange} isOnboarding={false} onSubmitCallback={onRatesSubmit} />}
          {(currentTab.name === 'Costs') && <Costs dirtyFormCallback={handleFormChange} isOnboarding={false} onSubmitCallback={onCostsSubmit}/>}
        </Grid>
        <Grid item xs={2}>
          <TabGroup tabItems={adminTabs} item={currentTab} callback={handleTabChange} />
        </Grid>
      </Grid>
    </PageLayout>
  );
};