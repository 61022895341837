import type { AzureADUser } from './azure.interface';

const MS_GRAPH_URL = 'https://graph.microsoft.com/v1.0/me?$select=employeeId';

export const getAzureADUserProfile = async(accessToken: string): Promise<AzureADUser> =>
{
  const headers = new Headers();
  headers.append('Authorization',  `Bearer ${accessToken}`);

  const requestOptions = {
    method: 'GET',
    headers: headers,
  };

  const res = await fetch(MS_GRAPH_URL, requestOptions);
  const user : AzureADUser = await res.json();
  return user;
};