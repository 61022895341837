import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  addButton: {
    marginTop: 20,
  },
  infoIcon: {
    fontSize: 10,
    marginLeft: 2,
  },
  stretchCell: {
    width: 140,
    maxWidth: 140,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  normalCell: {
    width: 100,
    maxWidth: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  condenseCell: {
    width: 80,
    maxWidth: 70,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textField: {
    maxWidth: 60,
    '& .MuiInputAdornment-root': {
      marginRight: 0,
      marginLeft: 10,
    }
  },
  dropDown: {
    paddingTop: 4,
    width: 130,
    '& .css-64oheu-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select':{
      color:'#737373'
    }
  }
});