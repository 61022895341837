import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';

import { defaultComponents } from './components';
import { defaultTypography } from './typography';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0C62FB',
    },
    success: {
      main: '#62A420',
    },
    error: {
      main: '#E62700',
    },
    background: {
      default: '#FFFFFF',
    },
    text: {
      primary: '#565656',
      secondary: '#000000',
      disabled: '#A3A3A3',
    },
    grey: {
      '500': '#9A9A9A',
      '600': '#737373',
    }
  },
  typography: defaultTypography,
  components: defaultComponents,
});