//TODO: load from file: https://mui.com/material-ui/icons/
import React from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const SlalomCalendarArrowRightIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M1.9911 0.880005L7.28888 6.00001L1.9911 11.12C1.69041 11.4096 1.21186 11.4007 0.922213 11.1C0.63257 10.7993 0.641524 10.3208 0.942213 10.0311L5.11555 6.00001L0.942213 1.96445C0.641524 1.67481 0.63257 1.19625 0.922213 0.89556C1.21186 0.594872 1.69041 0.585918 1.9911 0.87556V0.880005Z" fill="#0C62FB"/>
    </SvgIcon>
  );
};