import type { FC} from 'react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import type { UpdateCostRequestType } from '@/api/CostsService/costs.interface';
import { costService } from '@/api/CostsService/costs.service';
import type { UpdateRateRequestType } from '@/api/RatesService/rates.interface';
import { rateService } from '@/api/RatesService/rates.service';
import { PageLayout } from '@/components/PageLayout';
import Costs from '@/pages/Admin/costs';
import Rates from '@/pages/Admin/rates';
import { paths } from '@/services/Router/paths';
import { useLoadingContext } from '@/store/loading/loading';
import { useNotificationContext } from '@/store/notification/notification';

const onboardingWorkflow: string[] = ['Rates', 'Costs'];

export const Onboarding: FC = () => {
  const navigation = useNavigate();
  const [currentStep, setCurrentStep] = useState(() => {return onboardingWorkflow[0];});
  const [ratesRequestBody, setRatesRequestBody] = useState<UpdateRateRequestType>();
  const [selectedMarket, setSelectedMarket] = React.useState('');
  const { dispatch: loader } = useLoadingContext();
  const { dispatch: notification } = useNotificationContext();

  const onRatesSubmit = (ratesRequestBody: UpdateRateRequestType) => {
    setRatesRequestBody(ratesRequestBody);
    setSelectedMarket(ratesRequestBody.market);
    setCurrentStep(onboardingWorkflow[1]);
    return Promise.resolve();
  };

  function onCostsSubmit(costsRequestBody: UpdateCostRequestType) {
    // should save rates & costs and then navigate away to admin page
    loader?.setLoading({enable: true});
    if (ratesRequestBody) {
      const save = rateService.saveMarketRates(ratesRequestBody)
        .then(() => costService.saveMarketCosts(costsRequestBody));
      save.then(() => {
        loader?.setLoading({enable: false});
        notification?.addNotification({
          id: selectedMarket,
          severity: 'success',
          message: 'Changes have been successfully saved!',
        });
        navigation(paths.admin);
      })
        .catch(e => {
          loader?.setLoading({enable: false});
          notification?.addNotification({
            id: selectedMarket,
            severity: 'error',
            message: 'An error occurred while attempting to save market rates and/or costs: ' + e.message,
          });
        });
      return save;
    }
    else {
      return Promise.reject();
    }
  }

  return (
    <PageLayout title='Onboard New Market' data-testid='Onboarding'>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          {(currentStep === 'Rates') && <Rates isOnboarding={true} onSubmitCallback={onRatesSubmit}/>}
          {(currentStep === 'Costs') && <Costs isOnboarding={true} onSubmitCallback={onCostsSubmit} marketSelection={selectedMarket}/>}
        </Grid>
      </Grid>
    </PageLayout>
  );
};